<template>
  <div class="_segment">
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('type') }">
      <label for="input-type">Type</label>
      <select v-model="segment.type" v-validate="'required'" name="type" id="input-type" class="form-control input-sm">
        <option value="" selected="selected">-- Type</option>
        <option v-for="(v, k) in types" v-bind:key="k" :value="toSlug(v)">{{ v }}</option>
      </select>
    </div>
    <div v-if="segment.type && segment.type !== 'ligne-droite'" class="form-group">
      <label for="input-height">Hauteur</label>
      <input type="number" name="height" v-model="segment.height" min="0" step="any" id="input-height" class="form-control input-sm" @input="calcLength" placeholder="Hauteur escalier *"/>
    </div>
    <div v-if="segment.type && segment.type !== 'ligne-droite'" class="form-group">
      <input type="number" name="depth" v-model="segment.depth" min="0" step="any" id="input-depth" class="form-control input-sm" @input="calcLength" placeholder="Profondeur escalier *"/>
    </div>
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('poteau') }">
      <label for="input-poteau">Type de poteau</label>
      <select v-model="segment.poteau" v-validate="'required'" name="poteau" id="input-poteau" class="form-control input-sm">
        <option value="" selected="selected">-- Type de poteau</option>if (this.specs.remplissage !== '') this.segment.remplissage = this.specs.remplissage
        <option v-for="(v, k) in poteaux" v-bind:key="k" :value="toSlug(v)">{{ v }}</option>
      </select>
    </div>
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('length') }">
      <label for="input-length">Longueur</label>
      <input type="number" name="length" v-model="segment.length" v-validate="'required'" min="0" step="any" id="input-length" class="form-control input-sm" placeholder="Longueur *"/>
    </div>
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('angle_rotation') }">
      <label for="input-angle_rotation">Angle de rotation</label>
      <input type="number" name="angle_rotation" v-model="segment.angle_rotation" v-validate="'required'" min="-180" max="180" step="any" id="input-angle_rotation" class="form-control input-sm" placeholder="Angle de rotation *"/>
    </div>
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('ground') }">
      <label for="input-ground">Type de sol</label>
      <select v-model="segment.ground" name="ground" v-validate="'required'" id="input-ground" class="form-control input-sm">
        <option value="" selected="selected">-- Type de sol</option>
        <option v-for="(v, k) in sols" v-bind:key="k" :value="toSlug(v)">{{ v }}</option>
      </select>
    </div>
    <div class="form-group" :class="{ 'has-danger': errorsSpecs.has('remplissage') }">
      <label for="input-remplissage">Type de remplissage</label>
      <select v-model="segment.remplissage" v-validate="'required'" name="remplissage" id="input-remplissage" class="form-control input-sm">
        <option value="" selected="selected">-- Type de remplissage</option>
        <option v-for="(v, k) in remplissages" v-bind:key="k" :value="toSlug(v)">{{ v }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'Segment',
  props: ['specs', 'segment'],
  data () {
    return {
      types: ['Ligne droite', 'Escalier montant', 'Escalier descendant'],
      poteaux: ['Poteau à plat', 'Poteau latéral'],
      sols: ['Bois', 'Béton'],
      remplissages: ['Câble', 'Tube', 'Tube + plaques transparentes']
    }
  },
  watch: {
    'segment.length': {
      handler (val) {
        if (this.segment.depth !== '' && this.segment.height !== '' && this.segment.type !== 'ligne-droite') {
          this.segment['length'] = parseFloat(val).toFixed(2)
        }
      },
      deep: true
    }
  },
  methods: {

    // Calc length
    calcLength () {
      if (this.segment.depth !== '' && this.segment.height !== '') {
        this.segment.length = Math.sqrt(Math.pow(this.segment.depth, 2) + Math.pow(this.segment.height, 2))
      }
    },

    // Check segment
    checkSegment () {
      this.$validator.validate().then(result => {
        if (result) {
          EventBus.$emit('add-segment', false)
          this.$nextTick(() => {
            this.$validator.reset()
          })
        }
      })
    },

    // To slug
    toSlug (c) {
      return slugify(c, { lower: true })
    }

  },
  mounted () {
    if (this.specs.ground !== '') this.segment.ground = this.specs.ground
    if (this.specs.remplissage !== '') this.segment.remplissage = this.specs.remplissage
    // eslint-disable-next-line
    EventBus.$on('is-valid-segment', data => {
      this.checkSegment()
    })
  },
  beforeDestroy () {
    EventBus.$off('add-segment')
  }
}
</script>
