<template>
  <div id="devis-create">
    <article class="main-container">
      <ul class="breadcrumb">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item"><a href="index.html">Devis</a></li>
        <li class="breadcrumb-item">
          <span>
            Devis terrasse
          </span>
        </li>
      </ul>
      <ul class="actions">
        <li>
          <button class="btn btn-toggle btn-link" @click="toggleAside()">
            <i class="ion-android-menu"></i>
          </button>
        </li>
      </ul>
      <div class="content-i">
        <Form/>
      </div>
    </article>
    <footer>
      <nav class="navbar navbar-light">
        <div class="form-inline">
          <button class="btn btn-outline-primary btn-rounded" @click="saveData">Ajouter</button>
        </div>
      </nav>
    </footer>
  </div>
</template>

<script>
import Form from './_Form.vue'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'devis-create',
  components: { Form },
  methods: {
    // Save data
    saveData () {
      EventBus.$emit('save-data')
    },
    // Toggle Aside
    toggleAside () {
      EventBus.$emit('toggle-aside')
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 0;
  padding: 0;
  height: 35px;
  i {
    font-size: 18px;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 80px;
  right: 0;
  background: #fff;
  padding: 15px 24px;
  border-top: 1px solid rgba(0,0,0,0.05);
  z-index: 3;
  nav {
    background: #fff;
  }
}
</style>
