<template>
    <div id="devis-table">
        <div class="table-responsive">
            <table class="table table-items table-striped table-bordered">
                <thead>
                    <tr>
                        <th width="40"></th>
                        <th width="33"></th>
                        <th width="75">Ref</th>
                        <th width="75">Ref EBP</th>
                        <th>Libellé</th>
                        <th width="50">Quantité</th>
                        <th width="100">Prix TTC</th>
                        <th width="100">Prix HT</th>
                        <th width="80">Remise %</th>
                        <th width="100">Prix net HT</th>
                        <th width="100">Total HT</th>
                        <th width="118">Total TTC</th>
                        <th width="118">Marge</th>
                        <th style="display: none">Poids total</th>
                        <th width="145">Stock</th>
                    </tr>
                </thead>
                <draggable
                    v-for="(val, key) in devis.items"
                    v-bind:key="key"
                    :element="'tbody'"
                    :options="{
                        handle: '.item-move',
                        draggable: '.draggable-item',
                    }"
                >
                    <tr style="background: #fff">
                        <td colspan="17" class="headline">
                            <a
                                style="color: #333333; text-decoration: none"
                                @click="deleteGroup(key)"
                                >{{ key }}
                                <i
                                    class="ion-android-close"
                                    style="
                                        margin-left: 5px;
                                        color: #7b1224;
                                        cursor: pointer;
                                    "
                                ></i
                            ></a>
                        </td>
                    </tr>
                    <tr
                        v-for="(v, k) in val"
                        v-bind:key="k"
                        :ref="key + '-' + k + '_stock-line'"
                        class="draggable-item"
                        :class="{ outstock: isOutstock(v) }"
                    >
                        <td class="item-selector pad-0 text-center">
                            <a
                                href="#"
                                style="line-height: 30px; font-size: 18px"
                                @click="deleteItem(key, k)"
                                class="widget-remove"
                                ><i class="ion-ios-trash-outline text-muted"></i
                            ></a>
                        </td>
                        <td class="item-move">
                            <a
                                href="#"
                                style="line-height: 30px; font-size: 18px"
                                ><i class="ion-arrow-move text-muted"></i
                            ></a>
                        </td>
                        <td class="pad-0">
                            <input
                                type="text"
                                class="form-control input-sm"
                                v-replace-field="{
                                    field1: v.Ref,
                                    field2: v.Id,
                                }"
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="text"
                                class="form-control input-sm"
                                v-model="v.EbpNo"
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="text"
                                class="form-control input-sm"
                                v-model="v.Caption"
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-model="v.Qty"
                                @input="updateQtyByLine(key, k)"
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-format-number="v.SalePriceVatIncluded"
                                :ref="
                                    key + '-' + k + '_sale_price_vat_included'
                                "
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-model="v.SalePriceVatExcluded"
                                :ref="
                                    key + '-' + k + '_sale_price_vat_excluded'
                                "
                                @input="
                                    setSalePriceVatExcluded(
                                        key,
                                        k,
                                        $event.target.value
                                    )
                                "
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-model="v.Discount"
                                :ref="key + '-' + k + '_discount'"
                                @input="updateDiscountByLine(key, k)"
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-format-number="v.PriceNetHt"
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-format-number="v.TotalHt"
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="number"
                                class="form-control input-sm"
                                v-format-number="v.TotalTtc"
                                disabled
                            />
                        </td>
                        <td class="pad-0">
                            <input
                                type="text"
                                class="form-control input-sm"
                                v-format-number="v.TxMarge"
                                disabled
                            />
                        </td>
                        <td class="pad-0" style="display: none">
                            <input
                                type="text"
                                class="form-control input-sm"
                                v-format-number="v.TotalWeightByLine"
                                disabled
                            />
                        </td>
                        <td class="pad-0" width="145">
                            <input
                                type="text"
                                class="form-control input-sm"
                                :ref="key + '-' + k + '_stock'"
                                v-format-number="v.RealInventory"
                                disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0 text-muted" style="text-align: center">
                            <span style="font-size: 18px" class="text-muted">
                                <i class="ion-ios-plus-empty"></i>
                            </span>
                        </td>
                        <td class="pad-0" colspan="17">
                            <autocomplete
                                :name="'id'"
                                :placeholder="'Ajouter un produit'"
                                :inputClass="'form-control input-xs'"
                                :query="{
                                    table: 'products',
                                    as: 'p',
                                    columns: ['Id', 'EbpNo', 'Caption'],
                                    fields: '*',
                                    comparator: 'LIKE',
                                    limit: 50,
                                    group: key,
                                    restrict: {
                                        is_active: 1,
                                    },
                                }"
                                :url="'search/autocomplete'"
                                :callback="'add-item'"
                                :customResult="['Id', 'Caption']"
                                :emptyFilter="true"
                                class="autocomplete-td w100"
                            />
                        </td>
                    </tr>
                </draggable>
                <tfoot>
                    <tr style="background: #fff">
                        <td colspan="14" class="headline">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2" width="236">
                            <div class="title">% remise</div>
                        </td>
                        <td class="pad-0" width="145">
                            <div class="input-grp">
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="1"
                                    class="form-control input-sm text-right"
                                    v-model="devis.total_discount_percent"
                                    @input="setTotalDiscountPercent"
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Remise totale</div>
                        </td>
                        <td class="pad-0" width="145">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.discount_ht"
                                    disabled
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Marge totale</div>
                        </td>
                        <td class="pad-0" width="145">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.total_marge"
                                    disabled
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Tx Marge total</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.total_tx_marge"
                                    disabled
                                />
                                <div class="input-addon">%</div>
                            </div>
                        </td>
                    </tr>
                    <tr style="background: #fff">
                        <td colspan="15" class="headline">&nbsp;</td>
                    </tr>
                    <tr style="display: none">
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Quantité totale</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-model="devis.total_qty"
                                    disabled
                                />
                                <div class="input-addon">pc</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Poid total</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.total_weight"
                                    disabled
                                />
                                <div class="input-addon">kg</div>
                            </div>
                        </td>
                    </tr>
                    <tr style="background: #fff">
                        <td colspan="15" class="headline">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">total NET HT</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.total_ht"
                                    disabled
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">
                                <input
                                    type="checkbox"
                                    name="shipping_cost"
                                    v-model="devis.shipping_cost"
                                    @change="
                                        devis.total_shipping = 0;
                                        updateTotal();
                                    "
                                />
                                Frais de port HT
                            </div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="number"
                                    class="form-control input-sm text-right"
                                    v-model="devis.total_shipping"
                                    @input="updateTotalShipping"
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">TVA</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right"
                                    v-format-number="devis.total_tva"
                                    disabled
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pad-0" colspan="10"></td>
                        <td class="pad-0" colspan="2">
                            <div class="title">Total TTC</div>
                        </td>
                        <td class="pad-0">
                            <div class="input-grp">
                                <input
                                    type="text"
                                    class="form-control input-sm text-right text-bold"
                                    v-model="devis.total_ttc"
                                    disabled
                                />
                                <div class="input-addon">€</div>
                            </div>
                        </td>
                    </tr>
                    <tr style="background: #fff">
                        <td colspan="15" class="headline">&nbsp;</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div
            id="preview-3d"
            v-show="showPreview"
            :style="{ 'background-image': 'url(' + imagePreview + ')' }"
        >
            <button
                type="button"
                @click="showPreview = false"
                class="el-dialog__headerbtn"
                style="top: 10px; right: 20px; font-size: 30px"
            >
                <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
            <div style="padding: 15px 30px">
                <button
                    class="btn btn-sm"
                    :class="[
                        imagePreview === devis.preview_3d
                            ? 'btn-outline-primary'
                            : 'btn-outline-secondary',
                    ]"
                    @click="imagePreview = devis.preview_3d"
                >
                    3D
                </button>
                <button
                    class="btn btn-sm"
                    :class="[
                        imagePreview === devis.preview_2d
                            ? 'btn-outline-primary'
                            : 'btn-outline-secondary',
                    ]"
                    @click="imagePreview = devis.preview_2d"
                >
                    2D
                </button>
                <button
                    class="btn btn-sm"
                    :class="[
                        imagePreview === devis.preview_2ds
                            ? 'btn-outline-primary'
                            : 'btn-outline-secondary',
                    ]"
                    @click="imagePreview = devis.preview_2ds"
                >
                    2D coté
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import axios from "axios";
import { Notification } from "element-ui";
import _forEach from "lodash/forEach";
import _toArray from "lodash/toArray";
import Vue from "vue";
import draggable from "vuedraggable";
import Autocomplete from "../Autocomplete";

Vue.directive("format-number", {
    twoWay: true,
    inserted: function (el, binding) {
        el.value = binding.value
            ? parseFloat(Math.round(binding.value * 100) / 100).toFixed(2)
            : parseInt(0);
    },
    bind: function (el, binding) {
        el.value = binding.value
            ? parseFloat(Math.round(binding.value * 100) / 100).toFixed(2)
            : parseInt(0);
    },
    update: function (el, binding) {
        el.value = binding.value
            ? parseFloat(Math.round(binding.value * 100) / 100).toFixed(2)
            : parseInt(0);
    },
});

Vue.directive("replace-field", function (el, binding) {
    el.value = binding.value.field1
        ? binding.value.field1
        : binding.value.field2;
});

export default {
    name: "devis-table",
    components: { draggable, Autocomplete },
    data() {
        return {
            type: "",
            devis: {
                preview_3d: null,
                preview_2d: null,
                preview_2ds: null,
            },
            item: null,
            product: null,
            showPreview: false,
            imagePreview: null,
        };
    },
    watch: {
        "devis.items": function () {
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        this.setPriceHtByLine(group, item);
                        this.setTotalHtByLine(group, item);
                        this.setTotalTtcByLine(group, item);
                        this.setMargeByLine(group, item);
                        this.setTxMargeByLine(group, item);
                        this.setTotalWeightByLine(group, item);
                    }
                }
            }
            this.updateTotal();
        },
    },
    computed: {
        userCompany() {
            return JSON.parse(localStorage.getItem("currentUser")).company;
        },
        hasItems() {
            return (
                this.devis.items !== undefined &&
                Object.keys(this.devis.items).length > 0
            );
        },
        imageToken() {
            return Math.floor(Math.random() * 100000) + 1;
        },
    },
    methods: {
        // Fetch data
        fetchData() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .get(
                    "/v2/devis/" +
                        (this.$route.params.id !== undefined
                            ? this.$route.params.id + "/edit"
                            : "create/" + this.$route.params.type)
                )
                .then(function (response) {
                    let devis = response.data;
                    _forEach(devis.items, function (v, k) {
                        devis.items[k] = _toArray(v);
                    });
                    vm.devis = devis;
                    EventBus.$emit("devis-specs", response.data.specs);
                    EventBus.$emit("devis-events", response.data.events);
                    EventBus.$emit("devis-notes", response.data.notes);
                    EventBus.$emit(
                        "devis-customer_attachments",
                        response.data.customer_attachments
                    );
                    EventBus.$emit(
                        "devis-user_attachments",
                        response.data.user_attachments
                    );
                    EventBus.$emit("devis-customer", response.data.customer);
                    EventBus.$emit("devis-options", {
                        status_id: response.data.status_id,
                        user_id: response.data.user_id,
                        failed_status_id: response.data.failed_status_id,
                        reminder_at: response.data.reminder_at,
                        name: response.data.name,
                        linked_items: response.data.linked_items,
                    });
                    EventBus.$emit("refresh-gc-preview", {
                        images: {
                            "3d": response.data.preview_3d,
                            "2d": response.data.preview_2d,
                            "2ds": response.data.preview_2ds,
                        },
                    });
                    EventBus.$emit("toggle-loading", false);
                });
        },

        // Fetch palissade families
        /*
        fetchPalissadeFamilies() {
            axios
                .get("/v2/devis/palissade/families/" + this.userCompany)
                .then(function (response) {
                    EventBus.$emit("palissade-families", response.data);
                });
        },
        */

        // Save data
        saveData() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis?type=" + this.$route.params.type, this.devis)
                .then(function () {
                    EventBus.$emit("toggle-loading", false);
                    vm.$router.push({ name: "devis-list" });
                    Notification({
                        title: "Succès",
                        message: "Devis crée",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        EventBus.$emit("errors-customer", error.response.data);
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                    }
                });
        },

        // Update data
        updateData() {
            EventBus.$emit("toggle-loading", true);
            axios
                .put("/v2/devis/" + this.$route.params.id, this.devis)
                .then(function (response) {
                    EventBus.$emit("devis-events", response.data.events);
                    EventBus.$emit("devis-options", {
                        status_id: response.data.status_id,
                        user_id: response.data.user_id,
                        failed_status_id: response.data.failed_status_id,
                        reminder_at: response.data.reminder_at,
                        name: response.data.name,
                        linked_items: response.data.linked_items,
                    });
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Succès",
                        message: "Devis mis à jour",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        if (
                            error.response.data.failed_status_id !==
                                undefined ||
                            error.response.data.reminder_at !== undefined
                        ) {
                            EventBus.$emit(
                                "errors-options",
                                error.response.data
                            );
                        } else {
                            EventBus.$emit(
                                "errors-customer",
                                error.response.data
                            );
                        }
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                    }
                });
        },

        // Find item (async)
        findItem(query) {
            let vm = this;
            this.isLoading = true;
            axios
                .post("/v2/products/search", {
                    query: query,
                    fields: ["Id", "Caption"],
                    limit: 50,
                })
                .then(function (response) {
                    vm.item = response.data;
                    vm.isLoading = false;
                });
        },

        // Add new item
        addItem(group, value) {
            this.product = value;
            this.product.Qty = 1;
            this.product.Discount = this.product.Discount || 0;
            this.devis.items[group].push(this.product);
            this.updateByGroup(group);
        },

        // Delete items group
        deleteGroup(group) {
            Vue.delete(this.devis.items, group);
            this.updateTotal();
        },

        // Delete item
        deleteItem(group, item) {
            this.devis.items[group].splice(item, 1);
            this.updateByGroup(group);
        },

        // On update qty
        updateQtyByLine(group, item) {
            this.setTotalHtByLine(group, item);
            this.setTotalTtcByLine(group, item);
            this.setMargeByLine(group, item);
            this.setTxMargeByLine(group, item);
            this.setTotalWeightByLine(group, item);
            this.updateTotal();
        },

        // On update discount
        updateDiscountByLine(group, item) {
            this.setPriceHtByLine(group, item);
            this.setTotalHtByLine(group, item);
            this.setTotalTtcByLine(group, item);
            this.setMargeByLine(group, item);
            this.setTxMargeByLine(group, item);
            this.setTotalWeightByLine(group, item);
            this.updateTotal();
        },

        // Force total update
        updateTotal() {
            this.updateTotalDiscount();
            this.updateTotalMarge();
            this.updateTotalTxMarge();
            this.updateTotalQty();
            this.updateTotalWeight();
            this.updateTotalHt();
            this.updateTotalShipping();
            this.updateTotalTVA();
            this.updateTotalTTC();
        },

        // Force group update
        updateByGroup(group) {
            if (Object.keys(this.devis.items[group]).length > 0) {
                for (var item in this.devis.items[group]) {
                    this.setPriceHtByLine(group, item);
                    this.setTotalHtByLine(group, item);
                    this.setTotalTtcByLine(group, item);
                    this.setMargeByLine(group, item);
                    this.setTxMargeByLine(group, item);
                    this.setTotalWeightByLine(group, item);
                    this.updateTotal();
                }
            } else {
                this.updateTotal();
            }
        },

        // Set Pro Discount
        setProDiscount(professional) {
            if (this.hasItems) {
                if (professional) {
                    for (var group1 in this.devis.items) {
                        if (this.devis.items[group1].length > 0) {
                            for (var item1 in this.devis.items[group1]) {
                                this.devis.items[group1][item1].Discount =
                                    this.devis.items[group1][item1]
                                        .DiscountPro !== null
                                        ? this.devis.items[group1][item1]
                                              .DiscountPro
                                        : 0;
                                this.setPriceHtByLine(group1, item1);
                                this.setTotalHtByLine(group1, item1);
                                this.setTotalTtcByLine(group1, item1);
                                this.setMargeByLine(group1, item1);
                                this.setTxMargeByLine(group1, item1);
                                this.setTotalWeightByLine(group1, item1);
                            }
                        }
                    }
                } else {
                    for (var group2 in this.devis.items) {
                        if (this.devis.items[group2].length > 0) {
                            for (var item2 in this.devis.items[group2]) {
                                this.devis.items[group2][item2].Discount = 0;
                                this.setPriceHtByLine(group2, item2);
                                this.setTotalHtByLine(group2, item2);
                                this.setTotalTtcByLine(group2, item2);
                                this.setMargeByLine(group2, item2);
                                this.setTxMargeByLine(group2, item2);
                                this.setTotalWeightByLine(group2, item2);
                            }
                        }
                    }
                }
                this.updateTotal();
            }
        },

        // Set VatExluded Price
        setSalePriceVatExcluded(group, item, val) {
            this.devis.items[group][item].SalePriceVatExcluded =
                parseFloat(val);
            this.setPriceHtByLine(group, item);
            this.setTotalHtByLine(group, item);
            this.setTotalTtcByLine(group, item);
            this.setMargeByLine(group, item);
            this.setTxMargeByLine(group, item);
            this.setTotalWeightByLine(group, item);
            this.updateTotal();
        },

        // Prix HT
        setPriceHtByLine(group, item) {
            let FormatedDiscount =
                1 - this.devis.items[group][item].Discount / 100;

            this.devis.items[group][item].PriceNetHt =
                this.devis.items[group][item].SalePriceVatExcluded *
                FormatedDiscount;
        },

        // Total HT
        setTotalHtByLine(group, item) {
            this.devis.items[group][item].TotalHt =
                Math.ceil(this.devis.items[group][item].Qty) *
                this.devis.items[group][item].PriceNetHt;
        },

        // Total TTC
        setTotalTtcByLine(group, item) {
            this.devis.items[group][item].TotalTtc =
                this.devis.items[group][item].TotalHt * 1.2;
        },

        // Marge
        setMargeByLine(group, item) {
            this.devis.items[group][item].Marge =
                this.devis.items[group][item].TotalHt -
                this.devis.items[group][item].Qty *
                    this.devis.items[group][item].CostPrice;
        },

        // Tx Marge
        setTxMargeByLine(group, item) {
            this.devis.items[group][item].TxMarge =
                (this.devis.items[group][item].Marge /
                    this.devis.items[group][item].TotalHt) *
                100;
        },

        // Poids par ligne
        setTotalWeightByLine(group, item) {
            this.devis.items[group][item].TotalWeightByLine =
                this.devis.items[group][item].Qty *
                this.devis.items[group][item].NetWeight;
        },

        // Total discount %
        setTotalDiscountPercent() {
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        this.devis.items[group][item].Discount =
                            this.devis.total_discount_percent;
                        this.setPriceHtByLine(group, item);
                        this.setTotalHtByLine(group, item);
                        this.setTotalTtcByLine(group, item);
                        this.setMargeByLine(group, item);
                        this.setTxMargeByLine(group, item);
                        this.setTotalWeightByLine(group, item);
                        this.updateTotal();
                    }
                }
            }
        },

        // Total discount HT
        updateTotalDiscount() {
            var totalDiscount = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        totalDiscount =
                            totalDiscount +
                            (this.devis.items[group][item]
                                .SalePriceVatExcluded *
                                this.devis.items[group][item].Qty *
                                this.devis.items[group][item].Discount) /
                                100;
                    }
                }
            }
            this.devis.discount_ht = totalDiscount;
        },

        // Total marge
        updateTotalMarge() {
            var totalMarge = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        if (this.devis.items[group][item] !== null) {
                            totalMarge =
                                totalMarge +
                                (this.devis.items[group][item].Marge !==
                                undefined
                                    ? this.devis.items[group][item].Marge
                                    : 0);
                        }
                    }
                }
            }
            this.devis.total_marge = totalMarge;
        },

        // Total Tx marge
        updateTotalTxMarge() {
            var totalMarge = 0;
            var totalHt = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        if (this.devis.items[group][item] !== null) {
                            totalHt =
                                totalHt +
                                parseFloat(
                                    this.devis.items[group][item].TotalHt
                                );
                            totalMarge =
                                totalMarge +
                                parseFloat(this.devis.items[group][item].Marge);
                        }
                    }
                }
            }
            this.devis.total_tx_marge = (totalMarge / totalHt) * 100;
        },

        // Total Qté
        updateTotalQty() {
            var totalQty = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        if (this.devis.items[group][item] !== null) {
                            totalQty = parseInt(
                                totalQty +
                                    (this.devis.items[group][item].Qty !==
                                    undefined
                                        ? this.devis.items[group][item].Qty
                                        : 0)
                            );
                        }
                    }
                }
            }
            this.devis.total_qty = totalQty;
        },

        // Total poids
        updateTotalWeight() {
            var totalWeight = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        if (this.devis.items[group][item] !== null) {
                            totalWeight = parseFloat(
                                totalWeight +
                                    (this.devis.items[group][item]
                                        .TotalWeightByLine !== undefined
                                        ? this.devis.items[group][item]
                                              .TotalWeightByLine
                                        : 0)
                            );
                        }
                    }
                }
            }
            this.devis.total_weight = totalWeight;
        },

        // Total HT
        updateTotalHt() {
            var totalHT = 0;
            for (var group in this.devis.items) {
                if (Object.keys(this.devis.items[group]).length > 0) {
                    for (var item in this.devis.items[group]) {
                        if (this.devis.items[group][item] !== null) {
                            totalHT =
                                totalHT +
                                (this.devis.items[group][item].TotalHt !==
                                undefined
                                    ? this.devis.items[group][item].TotalHt
                                    : 0);
                        }
                    }
                }
            }
            this.devis.total_ht = totalHT;
        },

        // Frais de port
        updateTotalShipping() {
            if (
                ["terrasse", "terrasse_pilotis", "palissade"].indexOf(
                    this.$route.params.type
                ) >= 0
            ) {
                this.devis.total_shipping = parseFloat(
                    this.devis.total_shipping &&
                        (this.devis.total_shipping !== 125 ||
                            this.devis.total_shipping !== 0)
                        ? this.devis.total_shipping
                        : this.devis.total_ht > 0
                        ? this.devis.total_ht > 2500
                            ? 0
                            : 125
                        : 0
                );
            } else if (
                [
                    "garde-corps",
                    "garde-corps_jersey",
                    "garde-corps_louisiane",
                ].indexOf(this.$route.params.type) >= 0
            ) {
                if (this.devis.shipping_cost) {
                    if (this.devis.total_ht > 2500) {
                        this.devis.total_shipping = 0;
                    } else {
                        if (this.devis.total_weight <= 30) {
                            this.devis.total_shipping = 33.25;
                        } else if (
                            this.devis.total_weight > 30 &&
                            this.devis.total_weight <= 750
                        ) {
                            this.devis.total_shipping = 58.25;
                        } else {
                            this.devis.total_shipping = 125;
                        }
                    }
                } else {
                    this.devis.total_shipping = parseFloat(
                        this.devis.total_shipping
                    );
                }
            } else {
                this.devis.total_shipping = parseFloat(
                    this.devis.total_shipping > 0
                        ? this.devis.total_shipping
                        : 0
                );
            }
            this.updateTotalTVA();
            this.updateTotalTTC();
        },

        // Total TVA
        updateTotalTVA() {
            this.devis.total_tva =
                ((this.devis.total_ht + this.devis.total_shipping) * 20) / 100;
        },

        // Total TTC
        updateTotalTTC() {
            this.devis.total_ttc =
                Math.floor(
                    (this.devis.total_ht +
                        this.devis.total_shipping +
                        this.devis.total_tva) *
                        100
                ) / 100;
        },

        // Round nearest
        roundToNearest(numToRound, numToRoundTo) {
            return Math.round(numToRound / numToRoundTo) * numToRoundTo;
        },

        // Is out stock
        isOutstock(v) {
            return v.RealInventory - v.Qty < 0;
        },

        // Update Stock
        updateProducts() {
            let vm = this;
            setTimeout(function () {
                for (var group in vm.devis.items) {
                    if (Object.keys(vm.devis.items[group]).length > 0) {
                        for (var i = 0; i < vm.devis.items[group].length; i++) {
                            vm.updateProduct(
                                vm.devis.items[group][i],
                                group,
                                i
                            );
                        }
                    }
                }
            }, 2000);
        },

        // Check stock
        updateProduct(product, group, item) {
            let vm = this;
            axios
                .get(`/v2/devis/product/${product.Ref}`)
                .then(function (response) {
                    var stock = parseFloat(response.data.RealInventory);

                    vm.$refs[group + "-" + item + "_stock"][0].value = stock;

                    var test_stock = -1;

                    if (stock - product.Qty < 0) {
                        vm.$refs[
                            group + "-" + item + "_stock-line"
                        ][0].classList.add("outstock");
                    } else {
                        vm.$refs[
                            group + "-" + item + "_stock-line"
                        ][0].classList.remove("outstock");
                    }
                });
        },
    },
    mounted() {
        this.fetchData();
        this.updateProducts();
        this.type = this.$route.params.type;
        // Events
        EventBus.$on("add-item", (data) => {
            this.addItem(data.group, data.value);
        });
        EventBus.$on("refresh-items", (data) => {
            this.devis.items = data;
        });
        EventBus.$on("refresh-specs", (data) => {
            this.devis.specs = data;
        });
        EventBus.$on("refresh-customer", (data) => {
            this.devis.customer = data;
        });
        EventBus.$on("refresh-options", (data) => {
            for (var key in data) {
                this.devis[key] = data[key];
            }
        });
        // eslint-disable-next-line
        EventBus.$on("save-data", (data) => {
            this.saveData();
        });
        EventBus.$on("update-data", (data) => {
            this.updateData(data);
        });
        EventBus.$on("pro-discount", (data) => {
            this.setProDiscount(data);
        });
        if (this.$route.params.type === "garde-corps") {
            EventBus.$on("refresh-gc-preview", (data) => {
                this.devis.preview_3d =
                    data["3d"] !== undefined
                        ? data["3d"] +
                          "?version=" +
                          Math.floor(Math.random() * 100000) +
                          1
                        : this.devis.preview_3d;
                this.devis.preview_2d =
                    data["2d"] !== undefined
                        ? data["2d"] +
                          "?version=" +
                          Math.floor(Math.random() * 100000) +
                          1
                        : this.devis.preview_2d;
                this.devis.preview_2ds =
                    data["2ds"] !== undefined
                        ? data["2ds"] +
                          "?version=" +
                          Math.floor(Math.random() * 100000) +
                          1
                        : this.devis.preview_2ds;
            });
        }
        // eslint-disable-next-line
        EventBus.$on("show-gc-preview", (data) => {
            this.showPreview = true;
            this.imagePreview = this.devis.preview_3d;
        });
        // eslint-disable-next-line
        EventBus.$on("hide-gc-preview", (data) => {
            this.showPreview = false;
            this.imagePreview = null;
        });
        EventBus.$on("add-linked-item", (data) => {
            this.devis.linked_items.push(data.value);
        });
    },
    beforeDestroy() {
        EventBus.$off("save-data");
        EventBus.$off("update-data");
        EventBus.$off("show-gc-preview");
        EventBus.$off("add-linked-item");
    },
};
</script>

<style lang="scss" scoped>
.table-items {
    margin-bottom: 0;

    thead {
        tr {
            &:first-child {
                th {
                    border-top: 0;
                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }

    tr {
        &.outstock {
            td,
            input {
                background-color: #f4dbdb;
            }
        }

        th {
            border: 1px solid rgba(192, 192, 192, 0.33);
            padding: 0.5rem;
        }

        td {
            vertical-align: middle;
            padding: 8px;
            border: 1px solid rgba(192, 192, 192, 0.33);
            font-size: 12px;

            &:first-child {
                border-left: 0;
            }
            &:last-child {
                border-right: 0;
            }

            .form-control {
                font-size: 12px;
                height: 30px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:focus {
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                        0 0 8px rgba(102, 175, 233, 0.6);
                }
            }

            &.headline {
                background: rgba(7, 122, 248, 0.1);
                text-transform: uppercase;
                text-align: left;
                font-weight: 500;
                font-size: 10px;
            }

            &.item-move {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                font-size: 14px;
                color: #bbbbbb;
            }

            .input-grp {
                position: relative;

                .form-control {
                    padding-right: 40px !important;
                }

                .input-addon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #e6e6e6;
                    height: 30px;
                    width: 30px;
                    text-align: center;
                    line-height: 30px;
                }
            }

            .title {
                padding: 6px 15px;
                font-weight: 500;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
    .pad-0 {
        padding: 0;
    }
}
</style>
<style>
.table-items .form-control {
    border: none;
    border-radius: 0;
}
#preview-3d {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 300px;
}
.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
    overflow: unset;
}
</style>
