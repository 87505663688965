<template>
  <div id="logout"></div>
</template>

<script>
import store from '../store'

export default {
  mounted () {
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')
    localStorage.removeItem('devis-app__filters')
    localStorage.removeItem('devis-app__dashboard')
    store.commit('logoutUser')
    this.$router.push({ name: 'login' })
    // window.location = '/login'
  }
}
</script>
