<template>
    <div class="dashboard main-container">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link :to="{ name: 'devis-list' }"> Home </router-link>
            </li>
            <li class="breadcrumb-item">
                <span>
                    Devis
                    <strong v-show="items.length > 0" class="text-primary"
                        >({{ totalItems }})</strong
                    >
                    <a
                        href="#"
                        @click="resetFilters()"
                        v-show="isFiltered()"
                        id="reset-filters"
                        class="text-danger"
                        ><i class="ion-android-close"></i> Réinitialiser
                        {{ isFiltered() }} filtre(s)</a
                    >
                </span>
            </li>
            <li class="d-inline-flex">
                <div
                    class="custom-control custom-checkbox"
                    id="archives-checkbox"
                >
                    <input
                        type="checkbox"
                        id="quote_archived"
                        name="quote_archived"
                        v-model="filters.deleted"
                        class="custom-control-input"
                    />
                    <label for="quote_archived" class="custom-control-label">
                        Inclure les devis archivées
                    </label>
                </div>
            </li>
        </ul>
        <ul class="actions">
            <li class="dropdown">
                <button
                    class="btn btn-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="ion-plus" style="margin-left: 1.5px"></i>
                </button>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                >
                    <router-link
                        v-for="(v, k) in userProducts"
                        v-bind:key="k"
                        :to="{ name: 'devis-create', params: { type: v } }"
                        class="dropdown-item"
                        style="text-transform: capitalize"
                        >{{ v.replace("_", " ") }}</router-link
                    >
                </div>
            </li>
        </ul>
        <div class="content-i">
            <div class="content-box">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <table
                                class="table table-striped table-hover table-bordered"
                            >
                                <thead>
                                    <tr>
                                        <!-- Id -->
                                        <th
                                            class="text-center pad-0"
                                            width="100"
                                        >
                                            <autocomplete
                                                :name="'id'"
                                                :placeholder="'Id'"
                                                :inputClass="'form-control input-xs'"
                                                :query="{
                                                    table: 'devis',
                                                    as: 'd',
                                                    column: 'id',
                                                    fields: ['id'],
                                                    comparator: 'LIKE',
                                                    relation: false,
                                                }"
                                                :class="
                                                    isFiltered('id')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                                :url="'devis/search'"
                                                :callback="'refresh-filters'"
                                                class="autocomplete-th"
                                            />
                                        </th>
                                        <!-- Index -->
                                        <th class="text-center" width="80">
                                            Index
                                        </th>
                                        <!-- Type -->
                                        <th
                                            class="text-center pad-0"
                                            width="120"
                                        >
                                            <div
                                                class="dropdown select-dropdown multi"
                                                :class="
                                                    isFiltered('type')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                                <button
                                                    class="btn btn-link dropdown-toggle"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Type
                                                    <strong
                                                        v-if="
                                                            filters.type
                                                                .length > 0
                                                        "
                                                        class="text-primary"
                                                        >({{
                                                            filters.type.length
                                                        }})</strong
                                                    >
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                    style="
                                                        min-width: 120px;
                                                        margin-left: -60px;
                                                    "
                                                >
                                                    <a
                                                        class="dropdown-item"
                                                        href="#"
                                                        v-for="item in types"
                                                        v-bind:key="item"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input form-check-input"
                                                                v-model="
                                                                    filters.type
                                                                        .value
                                                                "
                                                                :value="item"
                                                                :id="
                                                                    'type-' +
                                                                    item
                                                                "
                                                                name="filters_type"
                                                            />
                                                            <label
                                                                class="custom-control-label form-check-label"
                                                                :for="
                                                                    'type-' +
                                                                    item
                                                                "
                                                            >
                                                                {{
                                                                    replaceTypeName(
                                                                        item
                                                                    )
                                                                }}
                                                            </label>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </th>
                                        <!-- Produit -->
                                        <th
                                            class="text-center pad-0"
                                            width="100"
                                        >
                                            <autocomplete
                                                id="autocomplete-product"
                                                :name="'product'"
                                                :placeholder="'Produit'"
                                                :inputClass="'form-control input-xs'"
                                                :query="{
                                                    table: 'products',
                                                    as: 'p',
                                                    columns: ['Id', 'Caption'],
                                                    column: 'product',
                                                    fields: [
                                                        'Id',
                                                        'Caption',
                                                        'Image',
                                                    ],
                                                    comparator: 'LIKE',
                                                    relation: 'items',
                                                    limit: 10,
                                                }"
                                                :url="
                                                    'search/autocomplete?site=' +
                                                    this.currentUser.company
                                                "
                                                :class="
                                                    isFiltered('product')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                                :callback="'refresh-filters'"
                                                :customResult="[
                                                    'Id',
                                                    'Caption',
                                                ]"
                                                class="autocomplete-th"
                                            />
                                        </th>
                                        <!-- Nom -->
                                        <th
                                            class="text-center pad-0"
                                            width="250"
                                        >
                                            <autocomplete
                                                :name="'last_name'"
                                                :placeholder="'Nom'"
                                                :inputClass="'form-control input-xs'"
                                                :query="{
                                                    table: 'customers',
                                                    as: 'c',
                                                    column: 'last_name',
                                                    fields: ['last_name'],
                                                    comparator: 'LIKE',
                                                    groupBy: 'c.last_name',
                                                    relation: 'customer',
                                                }"
                                                :class="
                                                    isFiltered('last_name')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                                :url="'devis/search'"
                                                :callback="'refresh-filters'"
                                                class="autocomplete-th"
                                            />
                                        </th>
                                        <!-- Email -->
                                        <th
                                            class="text-center pad-0"
                                            width="250"
                                        >
                                            <autocomplete
                                                :name="'email'"
                                                :placeholder="'Email'"
                                                :inputClass="'form-control input-xs'"
                                                :query="{
                                                    table: 'customers',
                                                    as: 'c',
                                                    column: 'email',
                                                    fields: ['c.email'],
                                                    comparator: 'LIKE',
                                                    relation: 'customer',
                                                }"
                                                :class="
                                                    isFiltered('email')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                                :url="'devis/search'"
                                                :callback="'refresh-filters'"
                                                class="autocomplete-th"
                                            />
                                        </th>
                                        <th class="text-center" width="200">
                                            Tel
                                        </th>
                                        <th class="text-center" width="75">
                                            Pays
                                        </th>
                                        <!-- Pro -->
                                        <th
                                            class="text-center pad-0"
                                            width="100"
                                        >
                                            <div
                                                class="dropdown select-dropdown multi"
                                                :class="
                                                    isFiltered('professional')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                                <button
                                                    class="btn btn-link dropdown-toggle"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Pro
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                    style="
                                                        min-width: 160px;
                                                        margin-left: -80px;
                                                    "
                                                >
                                                    <a
                                                        class="dropdown-item"
                                                        href="#"
                                                        v-for="(
                                                            v, k
                                                        ) in professionals"
                                                        v-bind:key="k"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input form-check-input"
                                                                v-model="
                                                                    filters
                                                                        .professional
                                                                        .value
                                                                "
                                                                :value="v.value"
                                                                :id="
                                                                    'professional-' +
                                                                    k
                                                                "
                                                                name="filters_professional"
                                                            />
                                                            <label
                                                                class="custom-control-label form-check-label"
                                                                :for="
                                                                    'professional-' +
                                                                    k
                                                                "
                                                            >
                                                                {{ v.name }}
                                                            </label>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="text-center" width="110">
                                            Prix TTC
                                        </th>
                                        <!-- Origine -->
                                        <th
                                            class="text-center pad-0"
                                            width="150"
                                        >
                                            <div
                                                class="dropdown select-dropdown multi"
                                                :class="
                                                    isFiltered('origine')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                                <button
                                                    class="btn btn-link dropdown-toggle"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Origine
                                                    <strong
                                                        v-if="
                                                            filters.origine
                                                                .length > 0
                                                        "
                                                        class="text-primary"
                                                        >({{
                                                            filters.origine
                                                                .length
                                                        }})</strong
                                                    >
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                    style="
                                                        min-width: 210px;
                                                        margin-left: -105px;
                                                    "
                                                >
                                                    <a
                                                        class="dropdown-item"
                                                        href="#"
                                                        v-for="item in origines"
                                                        v-bind:key="item"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input form-check-input"
                                                                v-model="
                                                                    filters
                                                                        .origine
                                                                        .value
                                                                "
                                                                :value="item"
                                                                :id="
                                                                    'origine-' +
                                                                    item
                                                                "
                                                                name="filters_origine"
                                                            />
                                                            <label
                                                                class="custom-control-label form-check-label"
                                                                :for="
                                                                    'origine-' +
                                                                    item
                                                                "
                                                                style="
                                                                    text-transform: initial;
                                                                "
                                                            >
                                                                {{ item }}
                                                            </label>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="text-center" width="90">
                                            Deadline
                                        </th>
                                        <!-- Status -->
                                        <th
                                            class="text-center pad-0"
                                            width="200"
                                        >
                                            <div
                                                class="dropdown select-dropdown multi"
                                                :class="
                                                    isFiltered('status')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                                <button
                                                    class="btn btn-link dropdown-toggle"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Statut
                                                    <strong
                                                        v-if="
                                                            filters.status
                                                                .length > 0
                                                        "
                                                        class="text-primary"
                                                        >({{
                                                            filters.status
                                                                .length
                                                        }})</strong
                                                    >
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                    style="
                                                        min-width: 160px;
                                                        margin-left: -80px;
                                                    "
                                                >
                                                    <a
                                                        class="dropdown-item"
                                                        href="#"
                                                        v-for="item in status"
                                                        v-bind:key="item.id"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input form-check-input"
                                                                v-model="
                                                                    filters
                                                                        .status
                                                                        .value
                                                                "
                                                                :value="item.id"
                                                                :id="
                                                                    'status-' +
                                                                    item.id
                                                                "
                                                                name="filters_status_id"
                                                            />
                                                            <label
                                                                class="custom-control-label form-check-label"
                                                                :for="
                                                                    'status-' +
                                                                    item.id
                                                                "
                                                            >
                                                                {{ item.title }}
                                                            </label>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="text-center" width="120">
                                            Cycle
                                        </th>
                                        <!-- User -->
                                        <th
                                            class="text-center pad-0"
                                            width="100"
                                        >
                                            <div
                                                class="dropdown select-dropdown multi"
                                                :class="
                                                    isFiltered('user')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                                <button
                                                    class="btn btn-link dropdown-toggle"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    User
                                                    <strong
                                                        v-if="
                                                            filters.user
                                                                .length > 0
                                                        "
                                                        class="text-primary"
                                                        >({{
                                                            filters.user.length
                                                        }})</strong
                                                    >
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                    style="
                                                        min-width: 160px;
                                                        margin-left: -80px;
                                                    "
                                                >
                                                    <div
                                                        v-for="(v, k) in users"
                                                        :key="k"
                                                    >
                                                        <div
                                                            style="
                                                                font-size: 11px;
                                                                padding: 6px;
                                                            "
                                                            class="text-primary"
                                                        >
                                                            {{ k }}
                                                        </div>
                                                        <a
                                                            class="dropdown-item"
                                                            href="#"
                                                            v-for="item in v"
                                                            v-bind:key="item.id"
                                                        >
                                                            <div
                                                                class="custom-control custom-checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input form-check-input"
                                                                    v-model="
                                                                        filters
                                                                            .user
                                                                            .value
                                                                    "
                                                                    :value="
                                                                        item.id
                                                                    "
                                                                    :id="
                                                                        'user-' +
                                                                        item.id
                                                                    "
                                                                    name="filters_user_id"
                                                                />
                                                                <label
                                                                    class="custom-control-label form-check-label"
                                                                    :for="
                                                                        'user-' +
                                                                        item.id
                                                                    "
                                                                >
                                                                    {{
                                                                        item.name
                                                                    }}
                                                                </label>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="text-center" width="100">
                                            Note
                                        </th>
                                        <th class="text-center" width="100">
                                            PJ
                                        </th>
                                        <th
                                            class="text-center pad-0"
                                            width="200"
                                        >
                                            <el-date-picker
                                                v-model="filters.date.value"
                                                lang="fr"
                                                format="yyyy-MM-dd"
                                                type="daterange"
                                                :picker-options="{
                                                    shortcuts: shortcuts,
                                                }"
                                                align="right"
                                                placeholder="Création"
                                                :class="
                                                    isFiltered('date')
                                                        ? 'is-filtered'
                                                        : ''
                                                "
                                            >
                                            </el-date-picker>
                                        </th>
                                        <th class="text-center" width="150">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="items.length > 0">
                                    <tr
                                        v-for="(item, index) in items"
                                        v-bind:key="index"
                                        @click="goToDevis(item.type, item.id)"
                                    >
                                        <td class="text-center">
                                            <small>{{ item.id }}</small>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="item.heat_index"
                                                class="fa-layers fa-fw"
                                            >
                                                <i
                                                    class="fab fa-hotjar"
                                                    v-bind:style="{
                                                        color: heatIndexColor(
                                                            item.heat_index
                                                        ),
                                                    }"
                                                ></i>
                                                <span
                                                    class="fa-layers-text fa-inverse"
                                                    data-fa-transform="shrink-11.5 rotate--0"
                                                >
                                                    {{ item.heat_index }}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                class="badge badge-pill badge-type"
                                                :class="'badge-' + item.type"
                                                >{{
                                                    item.type === "garde-corps"
                                                        ? "garde-corps inox"
                                                        : item.type.replace(
                                                              "_",
                                                              " "
                                                          )
                                                }}</span
                                            >
                                        </td>
                                        <td class="text-center pad-0">
                                            <span
                                                v-if="
                                                    (item.type === 'terrasse' ||
                                                        item.type === 'devis' ||
                                                        item.type ===
                                                            'garde-corps_jersey' ||
                                                        item.type ===
                                                            'terrasse_pilotis') &&
                                                    item.product
                                                "
                                            >
                                                <span
                                                    v-if="item.product.lame"
                                                    class="relative"
                                                    v-tooltip="{
                                                        html: true,
                                                        content:
                                                            '<span style=\'color:gold;\'>' +
                                                            item.product.lame
                                                                .Id +
                                                            '</span><br/>' +
                                                            item.product.lame
                                                                .Caption,
                                                    }"
                                                >
                                                    <img
                                                        :src="
                                                            generateProductImage(
                                                                item.product
                                                                    .lame
                                                            )
                                                        "
                                                        style="
                                                            width: 40px;
                                                            height: 40px;
                                                            border-radius: 50%;
                                                        "
                                                    />
                                                </span>
                                                <small
                                                    v-else
                                                    class="text-uppercase"
                                                    ><strong>{{
                                                        item.product.product_id
                                                    }}</strong></small
                                                >
                                            </span>
                                            <span
                                                v-if="
                                                    item.type === 'palissade' &&
                                                    item.product
                                                "
                                            >
                                                <small class="text-uppercase">
                                                    <strong>{{
                                                        item.product.family
                                                    }}</strong>
                                                </small>
                                            </span>
                                            <span
                                                v-if="
                                                    item.type ===
                                                        'pergola_alu' &&
                                                    item.product
                                                "
                                            >
                                                <small class="text-uppercase"
                                                    ><strong>{{
                                                        item.product.type
                                                    }}</strong></small
                                                >
                                            </span>
                                            <span
                                                v-if="
                                                    item.type === 'garde-corps'
                                                "
                                            >
                                                <small class="text-uppercase"
                                                    ><strong>-</strong></small
                                                >
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span v-if="item.customer"
                                                ><strong
                                                    class="text-uppercase"
                                                    style="margin-right: 5px"
                                                    >{{
                                                        item.customer.last_name
                                                    }}</strong
                                                >
                                                <small>{{
                                                    item.customer.first_name
                                                }}</small></span
                                            >
                                        </td>
                                        <td class="text-center">
                                            <a
                                                v-if="item.customer"
                                                v-on:click.stop.prevent="
                                                    openEmail(
                                                        item.customer.email,
                                                        item.type,
                                                        item.id
                                                    )
                                                "
                                                href="#"
                                                >{{ item.customer.email }}</a
                                            >
                                            <span v-else>-</span>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="
                                                    item.customer &&
                                                    item.customer.phone
                                                "
                                                v-on:click.stop.prevent="
                                                    openPhone(
                                                        formatPhone(
                                                            item.customer
                                                                .iso_code,
                                                            item.customer.phone
                                                        )
                                                    )
                                                "
                                                v-html="
                                                    formatPhone(
                                                        item.customer.iso_code,
                                                        item.customer.phone
                                                    )
                                                "
                                            ></span>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="
                                                    item.customer &&
                                                    item.customer.iso_code
                                                "
                                                class="flag-icon flag-icon-squared"
                                                :class="
                                                    'flag-icon-' +
                                                    item.customer.iso_code.toLowerCase()
                                                "
                                            ></span>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="
                                                    item.customer &&
                                                    item.customer.professional
                                                "
                                                class="badge badge-pro"
                                            >
                                                Pro
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <strong
                                                v-if="item.total_ttc"
                                                class="price"
                                                >{{
                                                    formatCurrency(
                                                        item.total_ttc
                                                    )
                                                }}</strong
                                            >
                                            <span v-else>-</span>
                                        </td>
                                        <td
                                            class="text-center"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    cdnUrl +
                                                    '/assets/img/origines/' +
                                                    formatUtmSource(
                                                        item.utm_source
                                                    ) +
                                                    '-logo.png)',
                                            }"
                                            style="
                                                background-repeat: no-repeat;
                                                background-size: 70%;
                                                background-position: center;
                                            "
                                        >
                                            <span
                                                v-if="
                                                    !formatUtmSource(
                                                        item.utm_source
                                                    )
                                                "
                                                >-</span
                                            >
                                        </td>
                                        <td class="text-center">
                                            <small class="deadline">{{
                                                item.deadline
                                            }}</small>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="item.status"
                                                class="badge badge-status"
                                                :class="item.status.name"
                                                v-tooltip="
                                                    item.failed_status
                                                        ? item.failed_status
                                                              .title
                                                        : ''
                                                "
                                            >
                                                {{ item.status.title }}
                                            </span>
                                        </td>
                                        <td class="text-center devisProcess">
                                            <ul style="margin-top: 6px">
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="btn btn-link text-muted"
                                                        style="
                                                            padding: 0;
                                                            border: 0;
                                                        "
                                                    >
                                                        <i
                                                            :class="
                                                                item.is_generated
                                                                    ? 'ion-ios-gear text-success'
                                                                    : 'ion-ios-gear-outline'
                                                            "
                                                            v-tooltip="
                                                                item.is_generated
                                                                    ? 'Devis généré'
                                                                    : ''
                                                            "
                                                        ></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="btn btn-link text-muted"
                                                        style="
                                                            padding: 0;
                                                            border: 0;
                                                        "
                                                    >
                                                        <i
                                                            :class="
                                                                item.mail_delivery_status !==
                                                                '-1'
                                                                    ? item.mail_delivery_status <
                                                                      '2'
                                                                        ? item.mail_delivery_status ===
                                                                          '0'
                                                                            ? 'ion-ios-email text-danger'
                                                                            : 'ion-ios-email text-success'
                                                                        : 'ion-ios-email text-success'
                                                                    : 'ion-ios-email-outline'
                                                            "
                                                            v-tooltip="
                                                                item.mail_delivery_status !==
                                                                '-1'
                                                                    ? item.mail_delivery_status <
                                                                      '2'
                                                                        ? item.mail_delivery_status ===
                                                                          '0'
                                                                            ? 'Devis non reçu'
                                                                            : 'Devis envoyé'
                                                                        : 'ion-ios-email text-success'
                                                                    : ''
                                                            "
                                                        ></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="btn btn-link text-muted"
                                                        style="
                                                            padding: 0;
                                                            border: 0;
                                                        "
                                                    >
                                                        <i
                                                            :class="
                                                                item.mail_delivery_status >=
                                                                '1'
                                                                    ? item.mail_delivery_status ===
                                                                      '2'
                                                                        ? 'ion-eye text-success'
                                                                        : 'ion-eye-disabled text-danger'
                                                                    : 'ion-ios-eye-outline'
                                                            "
                                                            v-tooltip="
                                                                item.mail_delivery_status >=
                                                                '1'
                                                                    ? item.mail_delivery_status ===
                                                                      '2'
                                                                        ? 'Devis ouvert'
                                                                        : 'Devis non ouvert'
                                                                    : ''
                                                            "
                                                        ></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="relative">
                                            <div class="absolute-badge">
                                                <div
                                                    v-if="item.user"
                                                    class="badge-user"
                                                    :class="
                                                        'badge-' +
                                                        formatUserName(
                                                            item.user.email
                                                        )
                                                    "
                                                >
                                                    <span
                                                        v-tooltip="
                                                            item.user.name
                                                        "
                                                        >{{
                                                            formatUserName(
                                                                item.user.email
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center pad-0">
                                            <span
                                                v-if="item.notes_count"
                                                class="relative"
                                                v-tooltip="{
                                                    content: asyncTooltip(
                                                        item.notes
                                                    ),
                                                    loadingContent:
                                                        'Please wait...',
                                                    loadingClass:
                                                        'content-is-loading',
                                                    defaultHtml: true,
                                                }"
                                            >
                                                <i
                                                    class="ion-ios-chatboxes"
                                                ></i>
                                                <span
                                                    class="attachmentsCount"
                                                    >{{
                                                        item.notes_count
                                                    }}</span
                                                >
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="text-center pad-0">
                                            <span
                                                v-if="
                                                    item.customer_attachments_count >
                                                    0
                                                "
                                                class="relative"
                                            >
                                                <i
                                                    class="ion-android-attach"
                                                ></i>
                                                <span
                                                    class="attachmentsCount"
                                                    >{{
                                                        item.customer_attachments_count
                                                    }}</span
                                                >
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                v-if="item.created_at"
                                                class="time"
                                                v-tooltip="
                                                    formatDate(
                                                        item.created_at,
                                                        'time'
                                                    )
                                                "
                                            >
                                                {{
                                                    formatDate(
                                                        item.created_at,
                                                        "date"
                                                    )
                                                }}
                                            </span>
                                        </td>
                                        <td class="row-actions">
                                            <button
                                                class="btn btn-link btn-secondary"
                                                v-on:click.stop.prevent="
                                                    duplicateDevis(item.id)
                                                "
                                                style="
                                                    padding: 0;
                                                    border: 0;
                                                    color: #292b2c;
                                                "
                                            >
                                                <i
                                                    class="os-icon os-icon-grid-10"
                                                    v-tooltip="'Dupliquer'"
                                                ></i>
                                            </button>
                                            <button
                                                class="btn btn-link btn-danger"
                                                v-on:click.stop.prevent="
                                                    deleteDevis(item.id, index)
                                                "
                                                style="
                                                    padding: 0;
                                                    border: 0;
                                                    margin-left: 5px;
                                                "
                                                v-tooltip="'Supprimer'"
                                            >
                                                <i
                                                    class="os-icon os-icon-ui-15"
                                                ></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <infinite-loading
                                :identifier="infiniteId"
                                @infinite="fetchData"
                                ref="infiniteLoading"
                            ></infinite-loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VTooltip from "v-tooltip";
import store from "../../store";
import VueLocalStorage from "vue-ls";
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";
import * as moment from "moment";
import PhoneNumber from "awesome-phonenumber";
import Autocomplete from "../Autocomplete";
import { EventBus } from "@/event-bus.js";
import { Notification } from "element-ui";
import _forEach from "lodash/forEach";

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);
Vue.use(VTooltip);

moment.locale("fr");

export default {
    name: "Dashboard",
    components: { InfiniteLoading, Autocomplete },
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem("currentUser")),
            items: [],
            types: [],
            status: [],
            users: [],
            origines: [],
            currentPage: 1,
            infiniteId: +new Date(),
            cdnUrl: "https://s3-eu-west-1.amazonaws.com/api.forestia.fr",
            utmSources: [
                "www.terrassebois.com",
                "www.terrassebois.com/",
                "adwords",
                "www.deck-linea.com",
                "www.direct-abris.com",
                "www.oxynov.fr",
            ],
            userProducts: JSON.parse(store.state.currentUser).products.split(
                "|"
            ),
            totalItems: null,
            shortcuts: [
                {
                    text: "Aujourd'hui",
                    onClick(picker) {
                        const end = moment().format("YYYY-MM-DD");
                        const start = moment().format("YYYY-MM-DD");
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "7 jours",
                    onClick(picker) {
                        const end = moment().format("YYYY-MM-DD");
                        const start = moment()
                            .subtract(7, "d")
                            .format("YYYY-MM-DD");
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "1 mois",
                    onClick(picker) {
                        const end = moment().format("YYYY-MM-DD");
                        const start = moment()
                            .subtract(30, "d")
                            .format("YYYY-MM-DD");
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "6 mois",
                    onClick(picker) {
                        const end = moment().format("YYYY-MM-DD");
                        const start = moment()
                            .subtract(182, "d")
                            .format("YYYY-MM-DD");
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "1 an",
                    onClick(picker) {
                        const end = moment().format("YYYY-MM-DD");
                        const start = moment()
                            .subtract(365, "d")
                            .format("YYYY-MM-DD");
                        picker.$emit("pick", [start, end]);
                    },
                },
            ],
            professionals: {
                oui: {
                    name: "Oui",
                    value: true,
                },
                non: {
                    name: "Non",
                    value: false,
                },
            },
            filters: {
                type: {
                    column: "type",
                    as: "d",
                    comparator: "IN",
                    value: [],
                    relation: false,
                },
                product: {
                    column: "Id",
                    table: "foo",
                    as: "p",
                    comparator: "IN",
                    value: [],
                    relation: true,
                },
                status: {
                    column: "status_id",
                    as: "d",
                    comparator: "IN",
                    value: [],
                    relation: false,
                },
                user: {
                    column: "user_id",
                    as: "d",
                    comparator: "IN",
                    value: [],
                    relation: false,
                },
                origine: {
                    column: "utm_source",
                    as: "d",
                    comparator: "IN",
                    value: [],
                    relation: false,
                },
                deleted: false,
                date: {
                    column: "created_at",
                    as: "d",
                    comparator: "BETWEEN",
                    value: "",
                    relation: false,
                },
                professional: {
                    column: "professional",
                    table: "customers",
                    as: "c",
                    comparator: "IN",
                    value: [],
                    relation: "customer",
                },
            },
        };
    },
    computed: {
        hasFilters() {
            return Vue.ls.get("filters")
                ? Object.keys(Vue.ls.get("filters")).length > 0
                : false;
        },
    },
    watch: {
        filters: {
            handler: function () {
                this.currentPage = 1;
                this.items = [];
                this.infiniteId += 1;
            },
            deep: true,
        },
    },
    methods: {
        // Fetch data
        fetchData($state) {
            let vm = this;
            let startTimer = Date.now();
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis/filter?page=" + this.currentPage, {
                    filters: this.filters,
                })
                .then(function (response) {
                    vm.items = vm.items.concat(response.data.data);
                    vm.totalItems = response.data.total;
                    EventBus.$emit("toggle-loading", false);
                    if (response.data.next_page_url) {
                        vm.currentPage += 1;
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                    vm.saveFilters();
                    let queryTiming = Date.now() - startTimer;
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    $state.complete();
                });
        },

        // Search data
        searchData() {
            let vm = this;
            axios
                .post("/v2/devis/search", vm.query)
                .then(function (response) {
                    vm.query.results = response.data;
                })
                .catch(function () {});
        },

        // Fetch devis types
        fetchTypes() {
            let vm = this;
            axios.get("/v2/devis/types").then(function (response) {
                vm.types = response.data;
            });
        },

        // Fetch devis status
        fetchStatus() {
            let vm = this;
            axios.get("/v2/devis/status").then(function (response) {
                vm.status = response.data;
            });
        },

        // Fetch devis users
        fetchUsers() {
            let vm = this;
            axios.get("/v2/devis/users").then(function (response) {
                vm.users = response.data;
            });
        },

        // Fetch orgines
        fetchOrigines() {
            let vm = this;
            axios.get("/v2/devis/origines").then(function (response) {
                vm.origines = response.data;
            });
        },

        // Format phone
        formatPhone(i, n) {
            var pn = new PhoneNumber(n, i);
            if (pn.isValid()) {
                return (
                    '<a href="#" class="badge badge-pill badge-phone ' +
                    (pn.isValid() ? "phone-valid" : "phone-invalid") +
                    '"><i class="' +
                    (pn.isMobile()
                        ? "ion-android-phone-portrait"
                        : "ion-ios-telephone") +
                    '"></i>' +
                    pn.getNumber("national") +
                    "</a>"
                );
            } else {
                return (
                    '<span class="badge badge-pill badge-phone phone-invalid"><i class="ion-alert-circled"></i> ' +
                    n +
                    "</span>"
                );
            }
        },

        // Is numeric
        isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },

        // Format UTM
        formatUtmSource(u) {
            if (this.utmSources.indexOf(u) > 0) {
                return u.replace("/", "");
            } else {
                return null;
            }
        },

        // Format Username
        formatUserName(e) {
            return e.substr(0, 2);
        },

        // Format Created at
        formatDate(d, type) {
            if (type === "date") {
                if (
                    moment().format("YYYY-MM-DD") ===
                    moment(d).format("YYYY-MM-DD")
                ) {
                    return moment(d).from(moment());
                }
                return moment(d).format("DD/MM/YYYY");
            } else {
                return moment(d).format("hh:mm");
            }
        },

        // Format currency
        formatCurrency(v) {
            return new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
            }).format(v);
        },

        // Show devis
        goToDevis(type, id) {
            this.$router.push({
                name: "devis-edit",
                params: { type: type, id: id },
            });
        },

        // Save filters
        saveFilters() {
            Vue.ls.set("filters", this.filters);
        },

        // Reset filters
        resetFilters() {
            Vue.ls.remove("filters");
            window.location.reload();
        },

        // Has filters (@params field)
        isFiltered(field) {
            var filtersCount = 0;
            if (field) {
                return (
                    field in this.filters &&
                    (Array.isArray(this.filters[field].value)
                        ? this.filters[field].value.length > 0
                        : this.filters[field].value !== "")
                );
            } else {
                for (var key in this.filters) {
                    if (
                        this.filters[key].value &&
                        (this.filters[key].value.length > 0 ||
                            this.filters[key].value === true)
                    ) {
                        filtersCount++;
                    }
                }
            }
            return filtersCount;
        },

        // Delete devis
        deleteDevis(id, index) {
            if (!confirm("Etes vous sur de vouloir supprimer ce devis ?")) {
                return false;
            }
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .delete("/v2/devis/" + id)
                .then(function () {
                    vm.items.splice(index, 1);
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Succès",
                        message: "Devis supprimé",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.message,
                        type: "error",
                    });
                });
        },

        // Duplicate devis
        duplicateDevis(id) {
            if (!confirm("Etes vous sur de vouloir dupliquer ce devis ?")) {
                return false;
            }
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis/" + id + "/duplicate")
                .then(function () {
                    EventBus.$emit("toggle-loading", false);
                    window.location.reload();
                    Notification({
                        title: "Succès",
                        message: "Devis dupliqué",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.message,
                        type: "error",
                    });
                });
        },

        // Open email
        openEmail(email, type, id) {
            window.location.href =
                "mailto:" + email + "?subject=Votre devis " + type + " N°" + id;
        },

        // Open email
        openPhone(phone) {
            window.location.href = "tel:" + phone;
        },

        // Notes tooltips
        asyncTooltip(content) {
            let tooltip =
                '<ul style="list-style: none; padding: 0; margin: 0">';
            _forEach(content, function (v, k) {
                tooltip +=
                    '<li style="margin-bottom: 10px; padding-top: 10px;' +
                    (k !== 0 ? "border-top: dashed 1px #fff;" : "") +
                    '"><p style="margin: 0;">' +
                    v.content +
                    '</p><small style="color: gold;">' +
                    (v.user ? v.user.name : "") +
                    "</small></li>";
            });
            tooltip += "</ul>";
            return tooltip;
        },

        // Replace type name
        replaceTypeName(item) {
            if (item === "devis") {
                return "devis DA";
            } else if (item === "garde-corps") {
                return "GC Inox";
            } else if (item === "garde-corps_jersey") {
                return "GC Jersey";
            } else if (item === "garde-corps_louisiane") {
                return "GC Louisiane";
            } else {
                return item.replace("_", " ");
            }
        },

        generateProductImage(item) {
            if (item.Image) {
                if (item.Image.startsWith("http")) {
                    return item.Image;
                } else {
                    return this.formatImageUrl(item.Image);
                }
            } else {
                return `https://via.placeholder.com/40?text=${item.Id}`;
            }
        },

        formatImageUrl(image) {
            return `data:"${this.detectMimeType(
                image
            )};charset=utf-8;base64,${image}`;
        },

        detectMimeType(b64) {
            var signatures = {
                JVBERi0: "application/pdf",
                R0lGODdh: "image/gif",
                R0lGODlh: "image/gif",
                iVBORw0KGgo: "image/png",
                "/9j/": "image/jpg",
            };
            for (var s in signatures) {
                if (b64.indexOf(s) === 0) {
                    return signatures[s];
                }
            }
        },

        // heatIndexColor
        heatIndexColor(headIndex) {
            let color = "#3E4B5B";

            if (headIndex == 100) {
                color = "#f43535";
            } else if (headIndex >= 50 && headIndex < 100) {
                color = "#f46d35";
            } else if (headIndex > 0 && headIndex < 50) {
                color = "#f4d734";
            }

            return color;
        },
    },
    mounted() {
        EventBus.$on("refresh-filters", (data) => {
            this.filters[data.column] = data;
            this.currentPage = 1;
            this.items = [];
            this.infiniteId += 1;
        });
        if (this.hasFilters) {
            this.filters = Vue.ls.get("filters");
        }
        this.fetchTypes();
        this.fetchStatus();
        this.fetchUsers();
        this.fetchOrigines();
    },
};
</script>

<style>
.el-range-editor {
    border: 0;
    width: auto !important;
    background: transparent;
}
.el-range-editor .el-range-input {
    background: transparent;
}
.mx-calendar-header {
    text-transform: initial;
}
.mx-input {
    font-size: 0.63rem !important;
    text-transform: uppercase !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}
.mx-input::-webkit-input-placeholder {
    /* Chrome */
    color: #3e4b5b;
}
.mx-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #3e4b5b;
}
.mx-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #3e4b5b;
    opacity: 1;
}
.mx-input:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #3e4b5b;
    opacity: 1;
}
.mx-datepicker-top {
    text-transform: initial;
    color: inherit;
}
.mx-datepicker-top span:last-child:after {
    content: "";
}
.mx-calendar-table th,
.mx-calendar-table td {
    border: 0 !important;
    padding: 0 !important;
}
.mx-calendar-table tr:nth-of-type(odd),
.mx-calendar-table tr:hover {
    background-color: #fff !important;
}
.mx-input-icon__calendar {
    background-image: none !important;
}
.mx-input-icon__calendar:after {
    color: #777;
    width: auto !important;
    height: auto !important;
    content: "\F117" !important;
    display: inline-block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.mx-input-icon__close:before {
    content: "\f12a" !important;
    color: #777;
    width: auto !important;
    height: auto !important;
    display: inline-block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.mx-calendar-content {
    height: auto !important;
    overflow: initial !important;
}
.pad-0 {
    padding: 0 !important;
}
.text-success {
    color: #00bf83 !important;
}
.badge-phone {
    color: #292b2c;
    background-color: #e1e1e1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-decoration: none !important;
}
.badge-phone i {
    margin-right: 4px;
    font-size: 12px;
}
.badge-phone.phone-valid {
    color: #292b2c;
    background-color: #ffffff;
    border: solid 1px #3da53d;
}
.badge-phone.phone-invalid {
    color: #292b2c;
    background-color: #ffffff;
    border: solid 1px crimson;
}
.badge-phone.phone-valid i {
    color: #afafaf;
}
</style>
<style lang="scss">
.badge-pro {
    font-size: 70%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    font-weight: 400;
    background: #565656;
    color: #eabba6;
}
.badge-status {
    font-size: 70%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    font-weight: 400;
    &.draft {
        background-color: #bbbbbb;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    &.new {
        background-color: #2196f3;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    &.pre-quoted {
        background: #c5fbc7;
        color: #333;
    }
    &.auto-quoted {
        background-color: #8dff91;
        color: #333;
    }
    &.quoted {
        background-color: #2bda31;
        color: #333;
    }
    &.pending {
        background-color: #ff9800;
    }
    &.raise-1,
    &.raise-1-auto {
        background-color: #e3ff8d;
        color: #333;
    }
    &.raise-2 {
        background-color: #ffbc7b;
        color: #333;
    }
    &.error,
    &.quoted-fail,
    &.fail {
        background-color: #7b1224;
    }
    &.duplicated {
        background-color: #bbb;
        color: #333;
    }
    &.contacted {
        background-color: #9c27b0;
    }
    &.in-queue {
        background: #a94442;
        color: #fff;
    }
    &.succeeded {
        background: #199319;
        color: #fff;
    }
    &.deferred {
        background-color: #acaae6;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    &.badge-status.added-to-cart {
        background-color: #67bc6a;
    }
    &.imported-into-bc {
        background: #1ce0ff;
        color: #333;
    }
}
.table-bordered {
    thead {
        tr {
            &:first-child {
                th {
                    border-top: 0;
                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
        th {
            border: 1px solid rgba(192, 192, 192, 0.33);
        }
    }

    td {
        border: 1px solid rgba(192, 192, 192, 0.33);

        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: 0;
        }
    }
}
.table th .form-control {
    font-size: 0.63rem;
    text-transform: uppercase;
    border-top: none;
    background: transparent;
    text-align: center;
    font-weight: 400;
}
.table th .form-control::-webkit-input-placeholder {
    /* Chrome */
    color: #3e4b5b;
}
.table th .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #3e4b5b;
}
.table th .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #3e4b5b;
    opacity: 1;
}
.table th .form-control:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #3e4b5b;
    opacity: 1;
}
.table-hover tbody tr:hover {
    background-color: rgba(18, 95, 142, 0.075) !important;
}
#loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1000;
    > div {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -20px -50px;
    }
    .vue-simple-spinner-text {
        text-transform: uppercase;
        font-weight: 100;
    }
}
.select-dropdown {
    height: 39px;
    line-height: 39px;

    &.is-filtered {
        .dropdown-toggle {
            color: #047bf8;
        }
    }

    .dropdown-toggle {
        font-size: 0.63rem;
        color: #3e4b5b;
        text-transform: uppercase;
        text-decoration: none;
        &:focus {
            color: #047bf8;
        }
    }
    .dropdown-menu {
        transform: none !important;
        line-height: 1.5;
        padding: 0;
        position: absolute;
        left: 50% !important;
        width: 80%;
        top: 100% !important;
        z-index: 1000;
        float: left;
        min-width: 10rem;
        margin: 0.125rem 0 0;
        font-size: 0.9rem;
        color: #3e4b5b;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border-radius: 4px;
        border: 2px solid #dde2ec;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0px;
        background: #f8f9fb;
        padding: 0;

        &:before {
            position: absolute;
            top: -7px;
            left: 50%;
            margin-left: -7px;
            display: inline-block;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-left: 7px solid transparent;
            border-bottom-color: #dde2ec;
            content: "";
        }

        .dropdown-item {
            line-height: initial;
            padding: 6px !important;
            cursor: pointer;

            input[type="checkbox"] {
                position: relative;
                top: -1px;
            }

            &:hover {
                color: #fff;
                background-color: #5897fb !important;
            }

            &:nth-of-type(odd) {
                background-color: #fff;
            }
        }
    }
    .custom-control-label {
        text-transform: capitalize;
        width: 100%;
        cursor: pointer;
        font-size: 13px;
        line-height: 24px;
    }
}

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
.badge-type {
    font-size: 70%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    color: #fff;
    &.badge-pergola {
        background-color: #ff5500;
    }
    &.badge-devis {
        background-color: #f50;
    }
    &.badge-carport {
        background-color: #aa8e5c;
    }
    &.badge-pergola_bois {
        background: #814848;
    }
    &.badge-pergola_alu {
        background: #8d8888;
    }
    &.badge-garde-corps {
        background-color: #4d8cd1;
    }
    &.badge-palissade {
        background-color: #2e3a3c;
    }
    &.badge-terrasse {
        background-color: #d3823d;
    }
    &.badge-garde-corps_jersey {
        background: #d9c362;
        color: inherit;
    }
    &.badge-terrasse_pilotis {
        background: #968484;
    }
    &.badge-garde-corps_louisiane {
        background: #af8552;
    }
    &.badge-chalet_bois {
        background: #c29e52;
    }
}
</style>
<style scoped lang="scss">
#archives-checkbox {
    margin-left: 15px !important;
    min-height: auto !important;
    padding-left: 20px !important;
}
#archives-checkbox label:after,
#archives-checkbox label:before {
    top: 0 !important;
    height: 14px !important;
    width: 14px !important;
}
.relative {
    position: relative;
}
.ion-android-attach,
.ion-ios-chatboxes {
    font-size: 20px;
}
.main-container {
    position: relative;
}
.actions {
    list-style: none;
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    padding: 0;
    height: 35px;
    li {
        float: right;
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        .dropdown-toggle {
            height: 35px;
            width: 42px;
            color: inherit;
            &:hover,
            &:focus {
                color: #0073ff;
            }
            &:after {
                border: none;
            }
        }
        .dropdown-menu {
            line-height: 1.5;
            position: absolute;
            z-index: 1000;
            float: right;
            font-size: 0.9rem;
            color: rgb(62, 75, 91);
            text-align: left;
            list-style: none;
            border-radius: 0px 0px 4px 4px;
            border: 2px solid rgb(221, 226, 236);
            background: rgb(248, 249, 251);
            padding: 0px;
            transform: translate3d(-118px, 35px, 0px);
            right: 0px;
            top: 0px;
            margin-top: -1px;

            &:before {
                position: absolute;
                top: -7px;
                right: 0;
                margin-right: 12px;
                display: inline-block;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #ccc;
                border-left: 7px solid transparent;
                border-bottom-color: #dde2ec;
                content: "";
            }

            .dropdown-item {
                line-height: initial;
                padding: 6px !important;
                display: block;
                color: #3e4b5b;
                text-decoration: none;
                font-size: 14px;
                text-transform: initial;

                &:nth-of-type(odd) {
                    background-color: #fff;
                }

                &:hover {
                    color: #fff;
                    background-color: #5897fb !important;
                }
            }
        }
    }
}
.mx-datepicker {
    width: 100px !important;
    min-width: 100px !important;
    line-height: 39px;
}
#reset-filters {
    text-transform: initial;
}
.content-box {
    padding: 0;
}

.table-striped tbody > tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.5);
}
.price {
    font-weight: 800;
    font-size: 85%;
}
.badge-user {
    position: relative;
    width: 25px;
    height: 14.43px;
    margin: 7.22px 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background-color: #3f5369;
    &:before {
        bottom: 100%;
        border-bottom: 7.22px solid #3f5369;
    }
    &:after {
        top: 100%;
        width: 0;
        border-top: 7.22px solid #3f5369;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 12.5px solid transparent;
        border-right: 12.5px solid transparent;
    }
    &.badge-cf {
        background-color: #f7749b;
        &:before {
            border-bottom-color: #f7749b;
        }
        &:after {
            border-top-color: #f7749b;
        }
    }
    &.badge-gd {
        background-color: #26af3a;
        &:before {
            border-bottom-color: #26af3a;
        }
        &:after {
            border-top-color: #26af3a;
        }
    }
    &.badge-vb {
        background-color: #99ca26;
        &:before {
            border-bottom-color: #99ca26;
        }
        &:after {
            border-top-color: #99ca26;
        }
    }
    &.badge-tb {
        background-color: #c4add2;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        span {
            color: gold;
        }
        &:before {
            border-bottom-color: #c4add2;
        }
        &:after {
            border-top-color: #c4add2;
        }
    }
    span {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 25px;
        height: 14.4338px;
        z-index: 2;
        background: inherit;
        font-weight: 500;
        font-size: 80%;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        line-height: 15px;
    }
}

.absolute-badge {
    left: 50%;
    position: absolute;
    margin: -14px -12.5px;
}

.attachmentsCount {
    background-color: #0273ff;
    color: #fff;
    border-radius: 9px;
    font-weight: 500;
    position: absolute;
    top: -5px;
    right: -14px;
    padding: 2px 4px;
    vertical-align: middle;
    font-size: 58%;
    line-height: 1;
}

.deadline {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 80%;
}

.time {
    font-weight: 800;
    font-size: 80%;
}

.devisProcess {
    padding: 0;
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: inline-block;
    }
    li {
        float: left;
        &:after {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            content: ">";
            color: #c2c8d8;
        }
        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}

.fa-layers {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background-color: #ddd9d9;
    padding: 0 2px;

    .fab {
        margin-right: 3px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .fa-layers-text {
        font-weight: 500;
        color: #3e4b5b;
        font-size: 90%;
    }
}
</style>
