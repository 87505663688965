<template>
  <div class="menu-w selected-menu-color-light menu-activated-on-hover color-scheme-light color-style-default sub-menu-color-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over">
    <div class="logo-w">
      <router-link :to="{ name: 'devis-list' }" class="logo" :class="'logo-' + currentUser.company.toLowerCase()">
        <div class="logo-element"></div>
      </router-link>
    </div>
    <div class="menu-and-user">
      <ul class="main-menu">
        <li>
          <router-link :to="{ name: 'dashboard' }">
            <div class="icon-w">
              <i class="os-icon os-icon-window-content"></i>
              <small>Dashboard</small>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'devis-list' }">
            <div class="icon-w">
              <i class="os-icon os-icon-layers"></i>
              <small>Devis</small>
            </div>
          </router-link>
        </li>
        <!--
        <li>
          <router-link :to="{ name: 'devis-stats' }">
            <div class="icon-w">
              <i class="os-icon os-icon-pie-chart-3"></i>
              <small>Stats</small>
            </div>
          </router-link>
        </li>
        -->
      </ul>
    </div>
    <div id="app-version" class="text-center text-muted">
      <small>
        {{ appVersion }}
      </small>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'Menu',
  props: ['appVersion'],
  data () {
    return {
      currentUser: JSON.parse(store.state.currentUser)
    }
  }
}
</script>

<style scoped lang="scss">
#app-version {
  border-top: solid 1px #f2f2f2;
  width: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  small {
    font-size: 70%;
    color: #787f83;
    font-weight: 100;
    border-bottom: dashed 1px #0273ff;
  }
}
.main-menu {
  padding: 0!important;
}
.menu-w {
  position: relative;
  z-index: 1;
}
.menu-w.menu-layout-mini .logo-w {
  padding: 16px 15px;
}
.logo-w .logo-forestia .logo-element {
  background-color: #151b1f;
  &:after {
    background-color: #851326;
  }
}

.logo-w .logo-deck-linea .logo-element {
  background-color: #ff9a01;
  &:after {
    background-color: #000000;
  }
}

.main-menu li .icon-w small {
  font-weight: 100;
  font-size: 10px;
  display: block;
  text-transform: uppercase;
  color: #3E4B5B;
}
</style>
