<template>
    <div id="devis-create">
        <article class="main-container">
            <ul class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item"><a href="index.html">Devis</a></li>
                <li class="breadcrumb-item">
                    <span> Devis terrasse </span>
                </li>
            </ul>
            <ul class="actions">
                <li>
                    <button
                        class="btn btn-toggle btn-link"
                        @click="toggleAside()"
                    >
                        <i class="ion-android-menu"></i>
                    </button>
                </li>
            </ul>
            <div class="content-i">
                <Form />
            </div>
        </article>
        <footer>
            <nav class="navbar navbar-light">
                <div class="form-inline">
                    <button
                        class="btn btn-outline-primary btn-rounded"
                        @click="updateData"
                    >
                        Modifier
                    </button>
                    <button
                        class="btn btn-outline-secondary btn-rounded"
                        @click="previewData"
                    >
                        Prévisualiser
                    </button>
                    <button
                        class="btn btn-outline-secondary btn-rounded"
                        @click="fetchEmailBody"
                    >
                        Email
                    </button>
                    <button
                        class="btn btn-outline-danger btn-rounded"
                        v-on:click.stop.prevent="importIntoBC"
                    >
                        Importer dans BC
                    </button>
                </div>
            </nav>
        </footer>
        <!-- Dialog email -->
        <el-dialog
            title="Envoyer email"
            :visible.sync="dialogEmailVisible"
            :append-to-body="true"
            :close-on-click-modal="false"
            id="modal-email"
            width="30%"
        >
            <form>
                <trumbowyg v-model="email.body"></trumbowyg>
            </form>
            <span slot="footer" class="dialog-footer">
                <div
                    class="custom-control custom-checkbox custom-control-inline float-left"
                >
                    <input
                        type="checkbox"
                        id="pj"
                        v-model="email.pj"
                        name="pj"
                        class="custom-control-input"
                    />
                    <label class="custom-control-label" for="pj"
                        >Inclure des pièces-jointes
                        <span
                            class="text-muted"
                            style="font-style: italic; font-size: 11px"
                            >(A déposer dans la seection Pièces jointes dans le
                            menu de droite)
                        </span>
                    </label>
                </div>
                <button
                    type="reset"
                    @click="dialogEmailVisible = false"
                    class="btn btn-sm btn-outline-secondary"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    @click="sendEmail"
                    class="btn btn-sm btn-outline-primary ml-2"
                >
                    Envoyer
                </button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import Form from "./_Form.vue";
import { EventBus } from "@/event-bus.js";
import { Notification } from "element-ui";
import Trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.css";

export default {
    name: "devis-edit",
    components: { Form, Trumbowyg },
    data() {
        return {
            id: this.$route.params.id,
            type: this.$route.params.type,
            dialogEmailVisible: false,
            email: {
                bcc: true,
                pj: false,
            },
        };
    },
    methods: {
        // Preview data
        previewData() {
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis/" + this.id + "/preview")
                .then(function (response) {
                    EventBus.$emit("toggle-loading", false);
                    if (response.status === 200) {
                        if (response.data.error === undefined) {
                            window.open(response.data.s3_url, "_blank");
                        } else {
                            Notification({
                                title: "Erreur",
                                message: response.data.msg,
                                type: "error",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        if (error.response.data.collapse) {
                            EventBus.$emit("open-collapse", {
                                collapse: error.response.data.collapse,
                            });
                        }
                        Notification({
                            title: "Attention",
                            message: error.response.data.message,
                            type: "warning",
                        });
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                    }
                });
        },

        // Email body
        fetchEmailBody() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .get("/v2/devis/" + this.id + "/email")
                .then(function (response) {
                    EventBus.$emit("toggle-loading", false);
                    vm.email.body = response.data;
                    vm.email.pj = false;
                    vm.dialogEmailVisible = true;
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        if (error.response.data.collapse) {
                            EventBus.$emit("open-collapse", {
                                collapse: error.response.data.collapse,
                                errorBag: error.response.data.errorBag,
                                errors: error.response.data.errors,
                            });
                        }
                        Notification({
                            title: "Attention",
                            message: error.response.data.message,
                            type: "warning",
                        });
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                    }
                });
        },

        // Send email
        sendEmail() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis/" + this.id + "/send", this.email)
                .then(function (response) {
                    EventBus.$emit("toggle-loading", false);
                    EventBus.$emit("devis-events", response.data.events);
                    EventBus.$emit("devis-options", {
                        status_id: response.data.status_id,
                        user_id: response.data.user_id,
                        failed_status_id: response.data.failed_status_id,
                        reminder_at: response.data.reminder_at,
                        name: response.data.name,
                    });
                    vm.dialogEmailVisible = false;
                    vm.email.pj = false;
                    Notification({
                        title: "Succès",
                        message: "Devis envoyé",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        let errorClean = "";
                        let errorRaw = error.response.data.message;
                        try {
                            errorClean = errorRaw
                                .split("response:")[1]
                                .split('"details":')[2]
                                .split('"description":')[1]
                                .split("}]}}")[0]
                                .split('"')[1];
                        } catch (err) {
                            errorClean = errorRaw;
                        }

                        Notification({
                            title: "Erreur",
                            message: errorClean,
                            type: "error",
                        });
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                        vm.email.pj = false;
                    }
                });
        },

        // Import data
        importIntoBC() {
            if (
                !confirm(
                    "Etes vous sur de vouloir importer ce devis dans Business Central ?"
                )
            ) {
                return false;
            }
            EventBus.$emit("toggle-loading", true);
            axios
                .put("/v2/devis/" + this.id + "/import_into_bc")
                .then(function (response) {
                    EventBus.$emit("toggle-loading", false);
                    EventBus.$emit("devis-events", response.data.events);
                    EventBus.$emit("devis-status", response.data.status);
                    Notification({
                        title: "Succès",
                        message: response.data.message,
                        dangerouslyUseHTMLString: true,
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.response.data.message,
                        type: "error",
                    });
                });
        },

        // Update Data
        updateData(back) {
            EventBus.$emit("update-data", back);
        },

        // Toggle Aside
        toggleAside() {
            EventBus.$emit("toggle-aside");
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
    list-style: none;
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    padding: 0;
    height: 35px;
    i {
        font-size: 18px;
    }
}

footer {
    position: fixed;
    bottom: 0;
    left: 80px;
    right: 0;
    background: #fff;
    padding: 15px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 3;
    nav {
        background: #fff;
        button {
            float: left;
            margin-right: 15px;
        }
    }
}
</style>

<style>
#modal-email .el-dialog {
    width: 40% !important;
}
#modal-email .el-dialog__body {
    padding: 0 0 15px 0;
}
#modal-email .el-dialog__body .fr-wrapper {
    border-radius: 0 !important;
}
#modal-email .fr-box.fr-basic .fr-element p {
    margin-bottom: 0 !important;
}
#loading-container {
    position: absolute;
    top: -34px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 3000;
}
#loading-container > div {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -20px -50px;
}
#loading-container .vue-simple-spinner-text {
    text-transform: uppercase;
    font-weight: 100;
}
a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
    position: absolute;
    top: -99999999px;
}
.trumbowyg-box {
    margin: 0;
    border-left: 0;
    border-right: 0;
}
#modal-email .el-dialog__header {
    border-bottom: 0;
}
</style>
