<template>
    <aside id="devis-specs" :class="{ 'is-opened': isOpen }">
        <!-- Toggle aside -->
        <button
            class="btn btn-link btn-toggle btn-block"
            @click="toggleAside()"
        >
            <i class="ion-android-close text-primary"></i>
        </button>
        <el-collapse v-model="activeCollapse" accordion>
            <!-- Specs collapse -->
            <el-collapse-item
                title="Caractéristiques"
                name="specs"
                :disabled="type.indexOf(['devis']) >= 0"
            >
                <form>
                    <fieldset v-if="type === 'terrasse'">
                        <div class="form-group">
                            <input
                                type="number"
                                name="length"
                                v-model="specs.length"
                                min="0"
                                step="any"
                                id="length"
                                class="form-control input-sm"
                                placeholder="Longueur"
                                @input="updateArea"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="width"
                                class="form-control input-sm"
                                placeholder="Largeur"
                                @input="updateArea"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.area }"
                        >
                            <input
                                type="number"
                                name="area"
                                v-model="specs.area"
                                min="0"
                                step="any"
                                id="area"
                                class="form-control input-sm"
                                placeholder="Surface *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                        >
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.p_length }"
                        >
                            <input
                                type="number"
                                name="p_length"
                                v-model="specs.p_length"
                                min="0"
                                step="any"
                                id="p_length"
                                class="form-control input-sm"
                                placeholder="Longueur plinthe *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.p_height }"
                        >
                            <input
                                type="number"
                                name="p_height"
                                v-model="specs.p_height"
                                min="0"
                                step="any"
                                id="p_height"
                                class="form-control input-sm"
                                placeholder="Hauteur plinthe"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.ground }"
                        >
                            <label style="display: block"
                                ><strong>Sol</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="ground"
                                    v-model="specs.ground"
                                    id="ground-1"
                                    value="beton"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="ground-1"
                                    >Béton</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="ground"
                                    v-model="specs.ground"
                                    id="ground-2"
                                    value="terre"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="ground-2"
                                    >Terre</label
                                >
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.structure }"
                        >
                            <label style="display: block"
                                ><strong>Lambourdage</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="structure"
                                    v-model="specs.structure"
                                    id="structure-1"
                                    value="bois"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="structure-1"
                                    >Pin</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="structure"
                                    v-model="specs.structure"
                                    id="structure-2"
                                    value="exo"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="structure-2"
                                    >Exo</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="structure"
                                    v-model="specs.structure"
                                    id="structure-3"
                                    value="alu"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="structure-3"
                                    >Alu</label
                                >
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.product_id }"
                        >
                            <autocomplete
                                :name="'product'"
                                :placeholder="'Choix de la lame'"
                                :inputClass="'form-control input-xs'"
                                :query="{
                                    table: 'products',
                                    as: 'p',
                                    columns: ['Id', 'Caption'],
                                    fields: '*',
                                    comparator: 'LIKE',
                                    limit: 50,
                                    restrict: {
                                        Family: [
                                            'LAMES ET PL. THERMO',
                                            'LAMES ET PL. RESIN',
                                            'LAMES ET PL. EXO',
                                            'LAMES ET PL. COMPO',
                                        ],
                                        is_active: 1,
                                    },
                                }"
                                :url="'search/autocomplete'"
                                :callback="'update-lame'"
                                :customResult="['Id', 'Caption']"
                                :emptyFilter="false"
                                :initValue="
                                    specs.product
                                        ? specs.product.Id +
                                          ' - ' +
                                          specs.product.Caption
                                        : ''
                                "
                                class="autocomplete-td autocomplete-specs autocomplete-float"
                            />
                        </div>
                        <div class="form-group">
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    type="checkbox"
                                    id="eclairage"
                                    v-model="specs.eclairage"
                                    name="eclairage"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="eclairage"
                                    >Kit eclairage
                                    <span class="text-muted"
                                        >(6 spot Aries + transfo)</span
                                    ></label
                                >
                            </div>
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    type="checkbox"
                                    id="complementary_products"
                                    v-model="specs.complementary_products"
                                    name="complementary_products"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="complementary_products"
                                    >Produits complémentaires</label
                                >
                            </div>
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'terrasse_pilotis'">
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.width }"
                            v-tooltip="errors.specs.width"
                        >
                            <label for="width">Largeur</label>
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="length"
                                class="form-control input-sm"
                                placeholder="Largeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depth }"
                            v-tooltip="errors.specs.depth"
                        >
                            <label for="depth">Profondeur</label>
                            <input
                                type="number"
                                name="depth"
                                v-model="specs.depth"
                                min="0"
                                step="any"
                                id="depth"
                                class="form-control input-sm"
                                placeholder="Profondeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-tooltip="errors.specs.height"
                        >
                            <label for="height">Hauteur</label>
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.entraxe }"
                        >
                            <label for="entraxe-input">Entraxe</label>
                            <select
                                v-model="specs.entraxe"
                                name="entraxe"
                                id="entraxe-input"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une entraxe
                                </option>
                                <option
                                    v-for="(v, k) in terrassePilotisEntraxes"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.garde_corps }"
                        >
                            <label for="garde_corps-input"
                                >type de garde-corps</label
                            >
                            <select
                                v-model="specs.garde_corps"
                                name="garde_corps"
                                id="garde_corps-input"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type de garde-corps
                                </option>
                                <option
                                    v-for="(v, k) in terrassePilotisGardeCorps"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    type="checkbox"
                                    id="input-escalier"
                                    v-model="specs.escalier"
                                    name="escalier"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="input-escalier"
                                    >Escalier ?</label
                                >
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Lame</label>
                            <autocomplete
                                :name="'product'"
                                :placeholder="'Choix de la lame'"
                                :inputClass="'form-control input-xs'"
                                :query="{
                                    table: 'products',
                                    as: 'p',
                                    columns: ['Id', 'Caption'],
                                    fields: '*',
                                    comparator: 'LIKE',
                                    limit: 50,
                                    restrict: {
                                        Family: [
                                            'LAMES ET PL. THERMO',
                                            'LAMES ET PL. RESIN',
                                            'LAMES ET PL. EXO',
                                            'LAMES ET PL. COMPO',
                                        ],
                                        is_active: 1,
                                    },
                                }"
                                :url="'search/autocomplete'"
                                :callback="'update-lame'"
                                :customResult="['Id', 'Caption']"
                                :emptyFilter="false"
                                :initValue="
                                    specs.product
                                        ? specs.product.Id +
                                          ' - ' +
                                          specs.product.Caption
                                        : ''
                                "
                                class="autocomplete-td autocomplete-specs autocomplete-float"
                            />
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'palissade'">
                        <!-- Segments -->
                        <div
                            v-for="(value, key) in specs.segments"
                            v-bind:key="key"
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.segments }"
                        >
                            <div class="input-group input-group-sm">
                                <input
                                    type="number"
                                    class="form-control"
                                    name="segments"
                                    v-model="specs.segments[key]"
                                    min="0"
                                    step="any"
                                    :id="'segment-' + key + 1"
                                    :placeholder="'Segment ' + (key + 1) + ' *'"
                                />
                                <span class="input-group-btn">
                                    <button
                                        v-if="key + 1 === specs.segments.length"
                                        class="btn btn-default"
                                        type="button"
                                        @click="addSegment"
                                    >
                                        <i class="ion-ios-plus-empty"></i>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-default"
                                        type="button"
                                        @click="deleteSegment(key)"
                                    >
                                        <i class="ion-ios-trash-outline"></i>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <hr style="margin-left: -15px; margin-right: -15px" />

                        <!-- NB angles -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.angles }"
                        >
                            <input
                                type="number"
                                name="angles"
                                v-model="specs.angles"
                                min="0"
                                step="any"
                                id="angles"
                                class="form-control input-sm"
                                placeholder="Angles *"
                            />
                        </div>

                        <hr style="margin-left: -15px; margin-right: -15px" />

                        <!-- Family -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.family }"
                        >
                            <select
                                v-model="specs.family"
                                name="family"
                                id="family"
                                class="form-control input-sm"
                                @change="
                                    resetGamme();
                                    resetPattern();
                                    resetHeight();
                                "
                            >
                                <option value="">
                                    -- Sélectionner la famille
                                </option>
                                <option
                                    v-for="(v, k) in palissadePacks"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ k }}
                                </option>
                            </select>
                        </div>

                        <!-- Gamme -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.gamme }"
                            v-if="specs.family"
                        >
                            <select
                                v-model="specs.gamme"
                                name="gamme"
                                id="gamme"
                                class="form-control input-sm"
                                @change="resetPattern()"
                            >
                                <option value="">
                                    -- Sélectionner la gamme
                                </option>

                                <option
                                    v-for="(v, k) in palissadePacks[
                                        specs.family
                                    ]"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ k }}
                                </option>
                            </select>
                        </div>

                        <!-- Motif -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.pattern }"
                            v-if="specs.family && specs.gamme"
                        >
                            <select
                                v-model="specs.pattern"
                                name="pattern"
                                id="pattern"
                                class="form-control input-sm"
                                @change="resetHeight()"
                            >
                                <option value="">
                                    -- Sélectionner le motif
                                </option>

                                <option
                                    v-for="(v, k) in palissadePacks[
                                        specs.family
                                    ][specs.gamme]"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ k }} - {{ v.name }}
                                </option>
                            </select>
                        </div>

                        <!-- Hauteur -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-if="specs.pattern"
                        >
                            <select
                                v-model="specs.height"
                                name="height"
                                id="height"
                                class="form-control input-sm"
                                @change="setFixation()"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une hauteur
                                </option>
                                <option
                                    v-for="height in palissadePacks[
                                        specs.family
                                    ][specs.gamme][specs.pattern]['heights']"
                                    v-bind:key="height"
                                    :value="height"
                                >
                                    {{ height }} cm
                                </option>
                            </select>
                        </div>

                        <hr style="margin-left: -15px; margin-right: -15px" />

                        <!-- Fixation -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.fixation }"
                        >
                            <label style="display: block"
                                ><strong>Fixation</strong></label
                            >
                            <div
                                v-for="(v, k) in palissadeFixations"
                                :key="k"
                                class="custom-control custom-radio custom-control-inline"
                                :class="{ disabled: v.disabled }"
                            >
                                <input
                                    type="radio"
                                    name="fixation"
                                    v-model="specs.fixation"
                                    :id="'fixation_' + k"
                                    :value="k"
                                    class="custom-control-input"
                                    :disabled="v.disabled"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="'fixation_' + k"
                                    >{{ v.name }}</label
                                >
                            </div>
                        </div>

                        <hr style="margin-left: -15px; margin-right: -15px" />

                        <!-- Départ / Arrivée -->
                        <div
                            class="form-group"
                            v-show="
                                specs.gamme === 'PARANA' ||
                                specs.gamme === 'SILENZO'
                            "
                        >
                            <label style="display: block"
                                ><strong>Départ / arrivée</strong></label
                            >
                            <div
                                class="form-group"
                                :class="{ 'has-danger': errors.specs.depart }"
                            >
                                <select
                                    v-model="specs.depart"
                                    name="depart"
                                    id="input-depart"
                                    class="form-control input-sm"
                                >
                                    <option value="" selected="selected">
                                        -- Départ
                                    </option>
                                    <option
                                        v-for="(v, k) in departs"
                                        v-bind:key="k"
                                        :value="toSlug(v)"
                                    >
                                        {{ v }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="form-group"
                                :class="{ 'has-danger': errors.specs.fin }"
                            >
                                <select
                                    v-model="specs.fin"
                                    v-validate="'required'"
                                    name="fin"
                                    id="input-fin"
                                    class="form-control input-sm"
                                >
                                    <option value="" selected="selected">
                                        -- Fin
                                    </option>
                                    <option
                                        v-for="(v, k) in departs"
                                        v-bind:key="k"
                                        :value="toSlug(v)"
                                    >
                                        {{ v }}
                                    </option>
                                </select>
                            </div>
                            <hr
                                style="margin-left: -15px; margin-right: -15px"
                            />
                        </div>

                        <!-- Options -->
                        <div
                            class="checkbox"
                            style="margin-top: 0; margin-bottom: 0"
                        >
                            <div
                                class="custom-control custom-checkbox"
                                v-show="specs.family === 'PARANA'"
                            >
                                <input
                                    v-model="specs.portillon"
                                    name="portillon"
                                    id="portillon-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        !specs.height ||
                                        specs.height < 175 ||
                                        specs.height > 193
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="portillon-input"
                                    >Portillon</label
                                >
                            </div>
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    id="complementary_products"
                                    v-model="specs.complementary_products"
                                    name="complementary_products"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="complementary_products"
                                    >Produits complémentaires
                                    <span class="text-muted"
                                        >(Entretien, outillage)</span
                                    ></label
                                >
                            </div>
                        </div>
                    </fieldset>
                    <fieldset
                        v-else-if="type === 'pergola_alu'"
                        style="max-height: 500px; overflow: scroll"
                    >
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.type }"
                        >
                            <select
                                v-model="specs.type"
                                name="type"
                                id="type"
                                class="form-control input-sm"
                                @change="resetPergolaAluOptions()"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type
                                </option>
                                <option
                                    v-for="(v, k) in pergolaAlu.types"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <hr />
                        <!-- Width -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.width }"
                            v-tooltip="errors.specs.width"
                        >
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="length"
                                class="form-control input-sm"
                                placeholder="Largeur *"
                            />
                        </div>
                        <!-- Depth -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depth }"
                            v-tooltip="errors.specs.depth"
                        >
                            <input
                                type="number"
                                name="depth"
                                v-model="specs.depth"
                                min="0"
                                step="any"
                                id="depth"
                                class="form-control input-sm"
                                placeholder="Profondeur *"
                            />
                        </div>
                        <!-- Height -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-tooltip="errors.specs.height"
                        >
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                        <hr />
                        <!-- Color -->
                        <div class="form-group">
                            <label for="pergola-alu_couverture"
                                ><strong>Coloris / RAL</strong></label
                            >
                            <div
                                class="form-group ionic"
                                :class="{ 'has-danger': errors.specs.color }"
                            >
                                <select
                                    v-model="specs.color"
                                    name="color"
                                    id="color"
                                    class="form-control input-sm"
                                >
                                    <option value="" selected="selected">
                                        -- Sélectionner un coloris
                                    </option>
                                    <option
                                        v-for="(v, k) in pergolaAlu.colors"
                                        v-bind:key="k"
                                        :value="k"
                                    >
                                        {{ v.name }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="form-group ionic"
                                :class="{ 'has-danger': errors.specs.ral }"
                            >
                                <input
                                    type="number"
                                    v-model="specs.ral"
                                    id="ral"
                                    name="ral"
                                    class="form-control input-sm"
                                    :disabled="
                                        specs.color !== 'RAL personnalisé'
                                    "
                                />
                            </div>
                        </div>
                        <hr />
                        <!-- Couverture -->
                        <div
                            class="form-group ionic"
                            :class="{
                                'has-danger': errors.specs.couverture,
                            }"
                        >
                            <label for="pergola-alu_couverture"
                                ><strong>Couverture</strong></label
                            >
                            <select
                                v-model="specs.couverture"
                                name="couverture"
                                id="pergola-alu_couverture"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une plaque
                                </option>
                                <option
                                    v-for="(v, k) in pergolaAluTypes[
                                        specs.type ? specs.type : 'neolis'
                                    ].couvertures"
                                    v-bind:key="k"
                                    :value="v"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <hr />
                        <!-- Gouttière -->
                        <div
                            class="form-group ionic"
                            v-if="specs.type === 'neolis'"
                            :class="{ 'has-danger': errors.specs.gouttiere }"
                        >
                            <label for="pergola-alu_gouttiere"
                                ><strong>Design gouttière</strong></label
                            >
                            <select
                                v-model="specs.gouttiere"
                                name="gouttiere"
                                id="pergola-alu_gouttiere"
                                class="form-control input-sm"
                                v-if="
                                    pergolaAlu.options &&
                                    pergolaAlu.options.gouttieres
                                "
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type de gouttière
                                </option>
                                <option
                                    v-for="(v, k) in pergolaAlu.options
                                        .gouttieres"
                                    v-bind:key="k"
                                    :value="v"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <!-- Paroi latérale complète -->
                        <div class="form-group" v-if="specs.type !== 'kitalys'">
                            <label class="d-block"
                                ><strong>Paroi latérale complète</strong></label
                            >
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.paroi_gauche_complete"
                                    name="paroi_gauche_complete"
                                    id="paroi_gauche_complete-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_gauche_triangle === true ||
                                        specs.store_motorise_gauche === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="paroi_gauche_complete-input"
                                    >A gauche</label
                                >
                            </div>
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.paroi_droite_complete"
                                    name="paroi_droite_complete"
                                    id="paroi_droite_complete-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_droite_triangle === true ||
                                        specs.store_motorise_droite === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="paroi_droite_complete-input"
                                    >A droite</label
                                >
                            </div>
                            <hr />
                        </div>
                        <!-- Paroi latérale triangle -->
                        <div class="form-group">
                            <label class="d-block"
                                ><strong>Paroi latérale triangle</strong></label
                            >
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.paroi_gauche_triangle"
                                    name="paroi_gauche_triangle"
                                    id="paroi_gauche_triangle-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_gauche_complete === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="paroi_gauche_triangle-input"
                                    >A gauche</label
                                >
                            </div>
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.paroi_droite_triangle"
                                    name="paroi_droite_triangle"
                                    id="paroi_droite_triangle-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_droite_complete === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="paroi_droite_triangle-input"
                                    >A droite</label
                                >
                            </div>
                        </div>
                        <hr />
                        <!-- Store motorisé -->
                        <div class="form-group">
                            <label class="d-block"
                                ><strong>Store motorisé</strong></label
                            >
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.store_motorise_gauche"
                                    name="store_motorise_gauche"
                                    id="store_motorise_gauche-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_gauche_complete === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="store_motorise_gauche-input"
                                    >Gauche</label
                                >
                            </div>
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.store_motorise_droite"
                                    name="store_motorise_droite"
                                    id="store_motorise_droite-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="
                                        specs.paroi_droite_complete === true
                                    "
                                />
                                <label
                                    class="custom-control-label"
                                    for="store_motorise_droite-input"
                                    >Droite</label
                                >
                            </div>
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    v-model="specs.store_motorise_facade"
                                    name="store_motorise_facade"
                                    id="store_motorise_facade-input"
                                    type="checkbox"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="store_motorise_facade-input"
                                    >Façade</label
                                >
                            </div>
                        </div>
                        <!-- Store facade qty -->
                        <div
                            class="form-group"
                            :class="{
                                'has-danger':
                                    errors.specs.store_motorise_facade_qty,
                            }"
                        >
                            <input
                                type="number"
                                v-model="specs.store_motorise_facade_qty"
                                name="store_motorise_facade_qty"
                                id="store_motorise_facade_qty"
                                class="form-control input-sm"
                                step="1"
                                min="0"
                                :max="specs.poteaux - 1"
                                :disabled="!specs.store_motorise_facade"
                            />
                        </div>
                        <!-- Store coloris -->
                        <div
                            class="form-group"
                            :class="{
                                'has-danger': errors.specs.store_color,
                            }"
                        >
                            <select
                                v-model="specs.store_color"
                                name="store_color"
                                id="store_color"
                                class="form-control input-sm"
                                v-if="
                                    pergolaAlu.options &&
                                    pergolaAlu.options.store_colors
                                "
                                :disabled="
                                    !specs.store_motorise_gauche &&
                                    !specs.store_motorise_droite &&
                                    !specs.store_motorise_facade
                                "
                            >
                                <option value="" selected="selected">
                                    -- Couleur du store
                                </option>
                                <option
                                    v-for="(v, k) in pergolaAlu.options
                                        .store_colors"
                                    v-bind:key="k"
                                    :value="v"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <hr />
                        <!-- Kit led -->
                        <div class="form-group">
                            <div
                                class="custom-control custom-checkbox custom-control-inline"
                            >
                                <input
                                    type="checkbox"
                                    id="eclairage"
                                    v-model="specs.eclairage"
                                    name="eclairage"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="eclairage"
                                    ><strong>Kit leds</strong></label
                                >
                            </div>
                        </div>
                        <!-- Nb kits leds -->
                        <div
                            class="form-group"
                            :class="{
                                'has-danger': errors.specs.eclairage_qty,
                            }"
                        >
                            <input
                                type="number"
                                v-model="specs.eclairage_qty"
                                name="eclairage_qty"
                                id="eclairage_qty"
                                class="form-control input-sm"
                                min="2"
                                :max="specs.porteurs"
                                step="1"
                                :disabled="!specs.eclairage"
                            />
                        </div>
                        <hr />
                        <!-- Nombre de poteaux -->
                        <div>
                            <div
                                class="form-group"
                                for="poteaux"
                                :class="{
                                    'has-danger': errors.specs.poteaux,
                                }"
                            >
                                <label class="d-block"
                                    ><strong>Nombre de poteaux</strong></label
                                >
                                <input
                                    type="number"
                                    name="poteaux"
                                    v-model="specs.poteaux"
                                    min="0"
                                    step="1"
                                    id="poteaux"
                                    class="form-control input-sm"
                                    placeholder="Nombre de poteaux"
                                    readonly="true"
                                />
                            </div>
                        </div>
                        <!-- Nombre de porteurs -->
                        <div>
                            <div
                                class="form-group"
                                for="porteurs"
                                :class="{
                                    'has-danger': errors.specs.porteurs,
                                }"
                            >
                                <label class="d-block"
                                    ><strong>Nombre de porteurs</strong></label
                                >
                                <input
                                    type="number"
                                    name="porteurs"
                                    v-model="specs.porteurs"
                                    id="porteurs"
                                    class="form-control input-sm"
                                    placeholder="Nombre de porteurs"
                                    readonly="true"
                                />
                            </div>
                        </div>
                        <!-- Prix achat -->
                        <hr />
                        <div
                            id="input-group-cost_price"
                            class="form-group"
                            :class="{
                                'has-danger': errors.specs.cost_price,
                            }"
                            v-tooltip="errors.specs.cost_price"
                        >
                            <label for="angle_toiture"
                                ><strong>Prix d'achat</strong></label
                            >
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="basic-addon1"
                                        >€</span
                                    >
                                </div>
                                <input
                                    type="number"
                                    name="cost_price"
                                    v-model="specs.cost_price"
                                    min="1"
                                    step="any"
                                    id="cost_price"
                                    class="form-control input-sm"
                                    placeholder="Prix d'achat"
                                    readonly="true"
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'garde-corps'">
                        <!-- Prévisualisation -->
                        <div class="form-group">
                            <el-switch
                                active-color="#0a79f8"
                                v-model="showPreview"
                            ></el-switch
                            ><strong
                                style="
                                    position: relative;
                                    top: 1px;
                                    margin-left: 5px;
                                "
                                >Prévisualisation</strong
                            >
                            <a
                                href="#"
                                @click="showGcPreview"
                                class="float-right text-primary"
                                style="
                                    position: relative;
                                    top: 1px;
                                    margin-left: 5px;
                                    font-weight: 500;
                                "
                                >Voir 3D <i class="ion ion-cube"></i
                            ></a>
                        </div>
                        <hr style="margin: 15px -15px" />
                        <!-- Départ -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depart }"
                        >
                            <select
                                v-model="specs.depart"
                                name="depart"
                                id="input-depart"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Départ
                                </option>
                                <option
                                    v-for="(v, k) in departs"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <!-- Type -->
                        <div
                            class="form-group"
                            :class="{
                                'has-danger': errors.specs.type_garde_corps,
                            }"
                        >
                            <select
                                v-model="specs.type_garde_corps"
                                name="type_garde_corps"
                                id="input-type_garde_corps"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Type de garde-corps
                                </option>
                                <option
                                    v-for="(v, k) in gardeCorpsTypes"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <hr style="margin: 15px -15px" />
                        <!-- Inox -->
                        <div
                            class="form-group text-center"
                            :class="{ 'has-danger': errors.specs.type_inox }"
                        >
                            <label style="display: block"
                                ><strong>Type d'inox</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="type_inox"
                                    v-model="specs.type_inox"
                                    id="type_inox-1"
                                    value="304"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="type_inox-1"
                                    >304</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="type_inox"
                                    v-model="specs.type_inox"
                                    id="type_inox-2"
                                    value="316"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="type_inox-2"
                                    >316</label
                                >
                            </div>
                        </div>
                        <!-- Sens platines -->
                        <div
                            class="form-group text-center"
                            :class="{ 'has-danger': errors.specs.sens_platine }"
                        >
                            <label style="display: block"
                                ><strong>Sens des platines</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="sens_platine"
                                    v-model="specs.sens_platine"
                                    id="sens_platine-1"
                                    value="interieur"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="sens_platine-1"
                                    >Intérieur</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    name="sens_platine"
                                    v-model="specs.sens_platine"
                                    id="sens_platine-2"
                                    value="exterieur"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="sens_platine-2"
                                    >Extérieur</label
                                >
                            </div>
                        </div>
                        <hr style="margin: 15px -15px" />
                        <!-- Main courante -->
                        <div
                            class="form-group"
                            :class="{
                                'has-danger': errors.specs.main_courante,
                            }"
                        >
                            <select
                                v-model="specs.main_courante"
                                name="main_courante"
                                id="input-main_courante"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Type de main courante
                                </option>
                                <option
                                    v-for="(v, k) in mainsCourantes"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <!-- Remplissage -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.remplissage }"
                        >
                            <select
                                v-model="specs.remplissage"
                                name="remplissage"
                                id="input-remplissage"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Type de remplissage
                                </option>
                                <option
                                    v-for="(v, k) in remplissages"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <!-- Sol -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.ground }"
                        >
                            <select
                                v-model="specs.ground"
                                name="ground"
                                id="input-ground"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Type de sol
                                </option>
                                <option
                                    v-for="(v, k) in sols"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <h2>
                            <span
                                >Segments
                                <button
                                    class="btn btn-link"
                                    style="padding: 0; margin-left: 5px"
                                    @click.prevent="
                                        dialogAddSegmentVisible = true
                                    "
                                >
                                    <i class="ion-ios-plus"></i></button
                            ></span>
                        </h2>
                        <!-- Segments -->
                        <div v-if="specs.segments && specs.segments.length > 0">
                            <ul class="segments-group">
                                <li
                                    v-for="(v, k) in specs.segments"
                                    v-bind:key="k"
                                    class="segment"
                                >
                                    <el-popover
                                        placement="left"
                                        width="auto"
                                        trigger="click"
                                    >
                                        <div>
                                            <ul class="segment-detail">
                                                <li
                                                    v-for="(vs, ks) in v"
                                                    v-bind:key="ks"
                                                >
                                                    {{ translate(ks) }}:
                                                    <strong>{{
                                                        translate(vs)
                                                    }}</strong>
                                                </li>
                                            </ul>
                                            <hr
                                                style="
                                                    margin-top: 0.5rem;
                                                    margin-bottom: 0.5rem;
                                                "
                                            />
                                            <div>
                                                <button
                                                    class="btn btn-sm btn-outline-primary"
                                                    @click="
                                                        openDialogEditSegment(
                                                            k,
                                                            v
                                                        )
                                                    "
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    class="btn btn-sm btn-outline-danger float-right"
                                                    @click="deleteSegment(k)"
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        </div>
                                        <label slot="reference"
                                            >S{{ k + 1 }}</label
                                        >
                                    </el-popover>
                                </li>
                            </ul>
                        </div>
                        <span
                            v-else
                            class="text-muted btn-block text-center"
                            :class="{ 'text-danger': errors.specs.segments }"
                            >Aucun segments</span
                        >
                        <h2 style="margin: 0 -15px 15px -15px">
                            <span></span>
                        </h2>
                        <!-- Fin -->
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.fin }"
                        >
                            <select
                                v-model="specs.fin"
                                v-validate="'required'"
                                name="fin"
                                id="input-fin"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Fin
                                </option>
                                <option
                                    v-for="(v, k) in departs"
                                    v-bind:key="k"
                                    :value="toSlug(v)"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'carport'">
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.toit }"
                        >
                            <select
                                v-model="specs.toit"
                                name="toit"
                                id="carport-toit"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type
                                </option>
                                <option
                                    v-for="(v, k) in carportTypes"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.couverture }"
                        >
                            <select
                                v-model="specs.couverture"
                                name="couverture"
                                id="carport-couverture"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une couverture
                                </option>
                                <option
                                    v-for="(v, k) in carportCouvertures"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.etancheite }"
                        >
                            <select
                                v-model="specs.etancheite"
                                name="etancheite"
                                id="carport-etancheite"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une éanchéité
                                </option>
                                <option
                                    v-for="(v, k) in carportCouvertures[
                                        specs.couverture
                                            ? specs.couverture
                                            : 'voliges'
                                    ].etancheites"
                                    v-bind:key="k"
                                    :value="v"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.width }"
                            v-tooltip="errors.specs.width"
                        >
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="carport-length"
                                class="form-control input-sm"
                                placeholder="Largeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depth }"
                            v-tooltip="errors.specs.depth"
                        >
                            <input
                                type="number"
                                name="depth"
                                v-model="specs.depth"
                                min="0"
                                step="any"
                                id="carport-depth"
                                class="form-control input-sm"
                                placeholder="Profondeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-tooltip="errors.specs.height"
                        >
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="carport-height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'chalet_bois'">
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.width }"
                            v-tooltip="errors.specs.width"
                        >
                            <label for="chalet-width">Largeur</label>
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="carport-width"
                                class="form-control input-sm"
                                placeholder="Largeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depth }"
                            v-tooltip="errors.specs.depth"
                        >
                            <label for="chalet-depth">Profondeur</label>
                            <input
                                type="number"
                                name="depth"
                                v-model="specs.depth"
                                min="0"
                                step="any"
                                id="chalet-depth"
                                class="form-control input-sm"
                                placeholder="Profondeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-tooltip="errors.specs.height"
                        >
                            <label for="chalet-height">Hauteur</label>
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="chalet-height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.toit }"
                        >
                            <label for="chalet_bois-toit">Toît</label>
                            <select
                                v-model="specs.toit"
                                name="toit"
                                id="chalet_bois-toit"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un toît
                                </option>
                                <option
                                    v-for="(v, k) in chaletToits"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.couverture }"
                        >
                            <label for="chalet_bois-couverture"
                                >Couverture</label
                            >
                            <select
                                v-model="specs.couverture"
                                name="couverture"
                                id="chalet_bois-couverture"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une couverture
                                </option>
                                <option
                                    v-for="(v, k) in chaletCouvertures"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.epaisseur }"
                        >
                            <label style="display: block"
                                ><strong>Epaisseur</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.epaisseur"
                                    name="epaisseur"
                                    id="epaisseur-44mm"
                                    value="44mm"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="epaisseur-44mm"
                                    >44mm</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.epaisseur"
                                    name="epaisseur"
                                    id="epaisseur-50mm"
                                    value="50mm"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="epaisseur-50mm"
                                    >50mm</label
                                >
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.plancher }"
                        >
                            <label style="display: block"
                                ><strong>Plancher</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.plancher"
                                    name="plancher"
                                    id="plancher-oui"
                                    value="oui"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="plancher-oui"
                                    >Oui</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.plancher"
                                    name="plancher"
                                    id="plancher-non"
                                    value="non"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="plancher-non"
                                    >Non</label
                                >
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.plancher }"
                        >
                            <label style="display: block"
                                ><strong>Vitrage</strong></label
                            >
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.vitrage"
                                    name="vitrage"
                                    id="vitrage-simple"
                                    value="simple"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="vitrage-simple"
                                    >Simple</label
                                >
                            </div>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    v-model="specs.vitrage"
                                    name="vitrage"
                                    id="vitrage-double"
                                    value="double"
                                    class="custom-control-input"
                                />
                                <label
                                    class="custom-control-label"
                                    for="vitrage-double"
                                    >Double</label
                                >
                            </div>
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'pergola_bois'">
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.toit }"
                        >
                            <select
                                v-model="specs.toit"
                                name="toit"
                                id="pergola_bois-toit"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type
                                </option>
                                <option
                                    v-for="(v, k) in pergolaBoisTypes"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.essence }"
                        >
                            <select
                                v-model="specs.essence"
                                name="essence"
                                id="pergola_bois-essence"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une essence de bois
                                </option>
                                <option
                                    v-for="(v, k) in pergolaBoisEssences"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.couverture }"
                        >
                            <select
                                v-model="specs.couverture"
                                name="couverture"
                                id="pergola_bois-couverture"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner une couverture
                                </option>
                                <option
                                    v-for="(v, k) in pergolaBoisCouvertures"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.width }"
                            v-tooltip="errors.specs.width"
                        >
                            <input
                                type="number"
                                name="width"
                                v-model="specs.width"
                                min="0"
                                step="any"
                                id="pergola_bois-length"
                                class="form-control input-sm"
                                placeholder="Largeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.depth }"
                            v-tooltip="errors.specs.depth"
                        >
                            <input
                                type="number"
                                name="depth"
                                v-model="specs.depth"
                                min="0"
                                step="any"
                                id="pergola_bois-depth"
                                class="form-control input-sm"
                                placeholder="Profondeur *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.height }"
                            v-tooltip="errors.specs.height"
                        >
                            <input
                                type="number"
                                name="height"
                                v-model="specs.height"
                                min="0"
                                step="any"
                                id="pergola_bois-height"
                                class="form-control input-sm"
                                placeholder="Hauteur *"
                            />
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'garde-corps_jersey'">
                        <div
                            v-for="(value, key) in specs.segments"
                            v-bind:key="key"
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.segments }"
                        >
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="basic-addon1"
                                        >S{{ key + 1 }}</span
                                    >
                                </div>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="segments"
                                    v-model="specs.segments[key]"
                                    min="0"
                                    step="any"
                                    :id="'segment-' + key + 1"
                                    :placeholder="'Segment ' + (key + 1) + ' *'"
                                />
                                <span class="input-group-btn">
                                    <button
                                        v-if="key + 1 === specs.segments.length"
                                        class="btn btn-default"
                                        type="button"
                                        @click="addSegment"
                                    >
                                        <i class="ion-ios-plus-empty"></i>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-default"
                                        type="button"
                                        @click="deleteSegment(key)"
                                    >
                                        <i class="ion-ios-trash-outline"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.product_id }"
                        >
                            <label for="garde-corps_jersey-product_id"
                                >Motif</label
                            >
                            <select
                                v-model="specs.product_id"
                                name="product_id"
                                id="garde-corps_jersey-product_id"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un motif
                                </option>
                                <option
                                    v-for="(v, k) in gardeCorpsJerseyTypes"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    [{{ k }}] - {{ v }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.fixation }"
                        >
                            <label for="garde-corps_jersey-fixation"
                                >Fixation des poteaux</label
                            >
                            <select
                                v-model="specs.fixation"
                                name="fixation"
                                id="garde-corps_jersey-fixation"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type de fixation
                                </option>
                                <option
                                    v-for="(v, k) in gardeCorpsJerseyFixations"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                    </fieldset>
                    <fieldset v-else-if="type === 'garde-corps_louisiane'">
                        <div
                            v-for="(value, key) in specs.segments"
                            v-bind:key="key"
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.segments }"
                        >
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="basic-addon1"
                                        >S{{ key + 1 }}</span
                                    >
                                </div>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="segments"
                                    v-model="specs.segments[key]"
                                    min="0"
                                    step="any"
                                    :id="'segment-' + key + 1"
                                    :placeholder="'Segment ' + (key + 1) + ' *'"
                                />
                                <span class="input-group-btn">
                                    <button
                                        v-if="key + 1 === specs.segments.length"
                                        class="btn btn-default"
                                        type="button"
                                        @click="addSegment"
                                    >
                                        <i class="ion-ios-plus-empty"></i>
                                    </button>
                                    <button
                                        v-else
                                        class="btn btn-default"
                                        type="button"
                                        @click="deleteSegment(key)"
                                    >
                                        <i class="ion-ios-trash-outline"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.type }"
                        >
                            <label for="garde-corps_louisiane-product_id"
                                >Type</label
                            >
                            <select
                                v-model="specs.type"
                                name="type"
                                id="garde-corps_louisiane-type"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type
                                </option>
                                <option
                                    v-for="(v, k) in gardeCorpsLouisianeTypes"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.ground }"
                        >
                            <label for="garde-corps_louisiane-ground"
                                >Sol</label
                            >
                            <select
                                v-model="specs.ground"
                                name="ground"
                                id="garde-corps_louisiane-ground"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type de sol
                                </option>
                                <option
                                    v-for="(v, k) in gardeCorpsLouisianeGrounds"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.specs.fixation }"
                        >
                            <label for="garde-corps_louisiane-fixation"
                                >Fixation des poteaux</label
                            >
                            <select
                                v-model="specs.fixation"
                                name="fixation"
                                id="garde-corps_louisiane-fixation"
                                class="form-control input-sm"
                            >
                                <option value="" selected="selected">
                                    -- Sélectionner un type de fixation
                                </option>
                                <option
                                    v-for="(
                                        v, k
                                    ) in gardeCorpsLouisianeFixations"
                                    v-bind:key="k"
                                    :value="k"
                                >
                                    {{ v }}
                                </option>
                            </select>
                        </div>
                    </fieldset>
                </form>
            </el-collapse-item>
            <!-- Options -->
            <el-collapse-item title="Options" name="options">
                <form>
                    <fieldset>
                        <div class="form-group">
                            <label for="status_id">Statut</label>
                            <select
                                v-model="options.status_id"
                                name="status_id"
                                id="status_id"
                                class="form-control input-sm"
                            >
                                <option
                                    v-for="(v, k) in status"
                                    v-bind:key="k"
                                    :selected="options.status_id === v.id"
                                    :value="v.id"
                                    :disabled="!v.can_be_assigned"
                                >
                                    {{ v.title }}
                                </option>
                            </select>
                        </div>
                        <div
                            v-show="options.status_id == '5'"
                            class="form-group"
                            :class="{
                                'has-danger': errors.options.failed_status_id,
                            }"
                        >
                            <label for="status_id">Raison</label>
                            <select
                                v-model="options.failed_status_id"
                                name="failed_status_id"
                                id="failed_status_id"
                                class="form-control input-sm"
                            >
                                <option
                                    v-for="(v, k) in failedStatus"
                                    v-bind:key="k"
                                    :selected="
                                        options.failed_status_id === v.id
                                    "
                                    :value="v.id"
                                >
                                    {{ v.title }}
                                </option>
                            </select>
                        </div>
                        <div
                            v-show="options.status_id == '7'"
                            class="form-group"
                            :class="{
                                'has-danger': errors.options.reminder_at,
                            }"
                        >
                            <label for="status_id">Date de rappel</label>
                            <el-date-picker
                                v-model="options.reminder_at"
                                type="date"
                                placeholder="Choisir une date"
                                class="form-control input-sm"
                                style="
                                    display: inline-block;
                                    width: 100%;
                                    padding: 0;
                                "
                                format="d/MM/yyyy"
                            >
                            </el-date-picker>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.options.user_id }"
                        >
                            <label for="user_id">Attribué à</label>
                            <select
                                v-model="options.user_id"
                                name="user_id"
                                id="user_id"
                                class="form-control input-sm"
                            >
                                <optgroup
                                    v-for="(v, group) in users"
                                    v-bind:key="group"
                                    :label="group"
                                >
                                    <option
                                        v-for="(user, k) in v"
                                        v-bind:key="k"
                                        :selected="options.user_id === user.id"
                                        :value="user.id"
                                    >
                                        {{ user.name }}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="name">Nom du projet</label>
                            <input
                                type="text"
                                name="name"
                                v-model="options.name"
                                id="input-name"
                                class="form-control input-sm"
                                placeholder="Nom du projet"
                            />
                        </div>
                        <div class="form-group">
                            <label for="">Liaison devis</label>
                            <autocomplete
                                :name="'linked_devis'"
                                :placeholder="'Choix du devis'"
                                :query="{
                                    table: 'devis',
                                    as: 'd',
                                    column: 'id',
                                    fields: ['d.id'],
                                    comparator: 'LIKE',
                                    relation: false,
                                }"
                                :url="'devis/search'"
                                :inputClass="'form-control input-xs'"
                                :callback="'add-linked-item'"
                                :emptyFilter="true"
                                class="autocomplete-td autocomplete-linked"
                            />
                            <div
                                class="linked_items-container"
                                v-if="options.linked_items"
                            >
                                <span
                                    class="badge badge-primary badge-linked_items"
                                    v-for="(v, k) in options.linked_items"
                                    v-bind:key="k"
                                    >{{ v }}
                                    <a
                                        href="#"
                                        class="ion ion-close"
                                        @click="deleteLinkedItem(k)"
                                    ></a
                                ></span>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </el-collapse-item>
            <!-- Client collapse -->
            <el-collapse-item title="Client" name="customer">
                <form>
                    <fieldset>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customer_enable"
                                    @change="customerEnabled = !customerEnabled"
                                    :checked="customerEnabled"
                                />
                                <label
                                    class="custom-control-label"
                                    for="customer_enable"
                                    >Attribuer un client ?</label
                                >
                            </div>
                        </div>
                        <div class="form-group">
                            <autocomplete
                                :name="'customer_email'"
                                :placeholder="'Rechercher un client'"
                                :inputClass="'form-control input-xs'"
                                :query="{
                                    table: 'customers',
                                    as: 'p',
                                    columns: [
                                        'first_name',
                                        'last_name',
                                        'email',
                                        'address',
                                        'postal_code',
                                        'city',
                                        'country',
                                        'phone',
                                        'professional',
                                        'company',
                                    ],
                                    fields: [
                                        'first_name',
                                        'last_name',
                                        'email',
                                        'address',
                                        'postal_code',
                                        'city',
                                        'country',
                                        'phone',
                                        'professional',
                                        'company',
                                    ],
                                    comparator: 'LIKE',
                                    limit: 50,
                                }"
                                :url="'search/autocomplete'"
                                :callback="'update-customer'"
                                :customResult="[
                                    'first_name',
                                    'last_name',
                                    'email',
                                    'address',
                                    'postal_code',
                                    'city',
                                    'country',
                                    'phone',
                                ]"
                                :emptyFilter="true"
                                :initValue="''"
                                :disabled="!customerEnabled"
                                class="autocomplete-td autocomplete-specs"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{
                                'has-danger': errors.customer.first_name,
                            }"
                            v-tooltip="errors.customer.first_name"
                        >
                            <input
                                type="text"
                                name="first_name"
                                v-model="customer.first_name"
                                :disabled="!customerEnabled"
                                id="first_name"
                                class="form-control input-sm"
                                placeholder="Prénom *"
                            />
                        </div>
                        <div
                            class="form-group has-icon ionic"
                            :class="{ 'has-danger': errors.customer.last_name }"
                            v-tooltip="errors.customer.last_name"
                        >
                            <input
                                type="text"
                                name="last_name"
                                v-model="customer.last_name"
                                class="form-control input-sm"
                                id="last_name"
                                :disabled="!customerEnabled"
                                placeholder="Nom *"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                name="address"
                                v-model="customer.address"
                                class="form-control input-sm"
                                :disabled="!customerEnabled"
                                id="address"
                                placeholder="Adresse"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                name="postal_code"
                                v-model="customer.postal_code"
                                class="form-control input-sm"
                                :disabled="!customerEnabled"
                                id="postal_code"
                                placeholder="Code postal *"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                name="city"
                                v-model="customer.city"
                                class="form-control input-sm"
                                :disabled="!customerEnabled"
                                id="city"
                                placeholder="Ville *"
                            />
                        </div>
                        <div class="form-group">
                            <select
                                v-model="customer.country"
                                :disabled="!customerEnabled"
                                class="form-control input-sm"
                            >
                                <option
                                    v-for="(v, k) in countries"
                                    v-bind:key="k"
                                    :selected="customer.country === v.name"
                                    :value="v.name"
                                >
                                    {{ v.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                name="phone"
                                v-model="customer.phone"
                                :disabled="!customerEnabled"
                                class="form-control input-sm"
                                placeholder="Téléphone *"
                            />
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.customer.email }"
                        >
                            <input
                                type="email"
                                name="email"
                                v-model="customer.email"
                                :disabled="!customerEnabled"
                                class="form-control input-sm"
                                placeholder="Email *"
                            />
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    :checked="customer.professional"
                                    v-model="customer.professional"
                                    @change="setProDiscount"
                                    :disabled="!customerEnabled"
                                    class="custom-control-input"
                                    id="customer_pro"
                                />
                                <label
                                    class="custom-control-label"
                                    for="customer_pro"
                                    >Client professionnel</label
                                >
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{ 'has-danger': errors.customer.company }"
                        >
                            <input
                                type="text"
                                name="company"
                                v-model="customer.company"
                                :disabled="
                                    !customerEnabled || !customer.professional
                                "
                                class="form-control input-sm"
                                placeholder="Société *"
                            />
                        </div>
                    </fieldset>
                </form>
            </el-collapse-item>
            <!-- events collapse -->
            <el-collapse-item
                :title="'Historique (' + eventsCount + ')'"
                name="events"
                style="max-height: 434px; overflow-y: scroll"
            >
                <div v-if="events" class="activity-boxes-w">
                    <div
                        v-for="(v, k) in events"
                        v-bind:key="k"
                        class="activity-box-w"
                    >
                        <div class="activity-time"></div>
                        <div class="activity-box">
                            <div class="activity-info">
                                <span v-html="formatDate(v.created_at)"></span>
                                <div
                                    v-if="v.user"
                                    class="badge-user float-left"
                                    :class="
                                        'badge-' + formatUserName(v.user.email)
                                    "
                                    style="
                                        margin-right: 7px;
                                        float: right !important;
                                        top: -4px;
                                    "
                                >
                                    <span v-tooltip="v.user.name">{{
                                        formatUserName(v.user.email)
                                    }}</span>
                                </div>
                                <div class="activity-content">
                                    <p></p>
                                    <h6 v-if="v.title">{{ v.title }}</h6>
                                    <div
                                        v-if="v.value"
                                        v-html="
                                            JSON.parse(JSON.stringify(v.value))
                                        "
                                    ></div>
                                    <div v-if="v.link" v-html="v.link"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
            <!-- Note collapse -->
            <el-collapse-item
                :title="'Notes (' + notesCount + ')'"
                name="notes"
                style="max-height: 434px; overflow-y: scroll"
            >
                <button
                    class="btn btn-outline-secondary btn-rounded btn-sm"
                    style="margin: auto; display: block"
                    @click="dialogNoteVisible = true"
                >
                    Ajouter une note
                </button>
                <div v-if="notes" class="activity-boxes-w">
                    <div
                        v-for="(v, k) in notes"
                        v-bind:key="k"
                        class="activity-box-w"
                    >
                        <div class="activity-time"></div>
                        <div class="activity-box">
                            <div class="activity-info">
                                <span v-html="formatDate(v.created_at)"></span>
                                <div
                                    v-if="v.user"
                                    class="badge-user float-left"
                                    :class="
                                        'badge-' + formatUserName(v.user.email)
                                    "
                                    style="
                                        margin-right: 7px;
                                        float: right !important;
                                        top: -4px;
                                    "
                                >
                                    <span v-tooltip="v.user.name">{{
                                        formatUserName(v.user.email)
                                    }}</span>
                                </div>
                                <p>{{ v.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
            <!-- PJ collapse -->
            <el-collapse-item
                :title="'Pièces jointes (' + attachmentsCount + ')'"
                name="pj"
            >
                <div
                    class="files-upload d-flex align-items-center"
                    :class="{
                        'drop-active': $refs.upload && $refs.upload.dropActive,
                    }"
                >
                    <file-upload
                        ref="upload"
                        v-model="user_attachments"
                        name="file"
                        :post-action="apiUrl + '/v2/upload'"
                        @input-file="inputFile"
                        @input-filter="inputFilter"
                        :drop="true"
                        :multiple="true"
                        :headers="{ Authorization: authToken }"
                        :data="{ id: $route.params.id }"
                        auto="true"
                        :add-index="true"
                    >
                        <div>
                            <h6>
                                <i class="ion-upload"></i>
                                Glisser/déposer <br />un fichier
                            </h6>
                        </div>
                    </file-upload>
                </div>
                <ul class="list-group list-group-upload">
                    <li class="list-group-item header">
                        Pièces jointes client
                        <strong
                            >({{
                                customer_attachments
                                    ? customer_attachments.length
                                    : 0
                            }})</strong
                        >
                    </li>
                </ul>
                <ul class="list-group list-group-upload">
                    <li
                        v-for="(v, k) in customer_attachments"
                        :key="k"
                        class="list-group-item"
                    >
                        <a :href="v.value" target="_blank">
                            <span
                                style="
                                    display: inline-block;
                                    width: 180px;
                                    float: left;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                            >
                                {{ v.value.split("attachments/")[1] }}
                            </span>
                        </a>
                    </li>
                </ul>
                <ul class="list-group list-group-upload">
                    <li class="list-group-item header">
                        Pièces jointes internes
                        <strong
                            >({{
                                user_attachments ? user_attachments.length : 0
                            }})</strong
                        >
                    </li>
                </ul>
                <ul
                    class="list-group list-group-upload"
                    style="
                        margin-bottom: -15px;
                        max-height: 240px;
                        overflow: scroll;
                    "
                >
                    <li
                        v-for="(v, k) in user_attachments"
                        :key="k"
                        class="list-group-item"
                    >
                        <div v-if="v.active">
                            <div class="progress">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="'width: ' + v.progress + '%'"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {{ v.progress }}%
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <span
                                style="
                                    display: inline-block;
                                    width: 180px;
                                    float: left;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                            >
                                <i
                                    :class="getMimeIcon(v.mime_type)"
                                    style="margin-right: 5px"
                                ></i>
                                <a :href="v.value" target="_blank">
                                    {{ v.name
                                    }}<span v-if="v.extension"
                                        >.{{ v.extension }}</span
                                    >
                                </a>
                            </span>
                            <span class="float-right">
                                <a
                                    href="#"
                                    @click.prevent="deleteAttachment(k)"
                                    class="float-right text-danger"
                                    style="margin-left: 5px"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                                <span class="float-right text-muted">{{
                                    fileSize(v.size)
                                }}</span>
                            </span>
                        </div>
                    </li>
                </ul>
            </el-collapse-item>
        </el-collapse>
        <!-- Compute action -->
        <div style="padding: 15px 30px">
            <button
                class="btn btn-outline-primary btn-rounded btn-block"
                @click="computeData()"
                :disabled="computeDisabled"
            >
                Calculer
            </button>
        </div>
        <!-- Dialog note -->
        <el-dialog
            title="Ajouter une note"
            :visible.sync="dialogNoteVisible"
            :append-to-body="true"
            id="modal-note"
            width="30%"
        >
            <form>
                <textarea
                    v-model="note"
                    class="form-control"
                    rows="15"
                ></textarea>
            </form>
            <span slot="footer" class="dialog-footer">
                <button
                    type="reset"
                    @click="closeModalNote"
                    class="btn btn-sm btn-outline-secondary"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    @click="createNote"
                    class="btn btn-sm btn-outline-primary"
                >
                    Enregistrer
                </button>
            </span>
        </el-dialog>
        <!-- Dialog add segment -->
        <el-dialog
            title="Ajouter un segment"
            :visible.sync="dialogAddSegmentVisible"
            :append-to-body="true"
            :close-on-click-modal="false"
            id="modal-add-segment"
            width="20%"
        >
            <Segment :specs="specs" :segment="segment" />
            <span slot="footer" class="dialog-footer">
                <button
                    @click="checkSegment"
                    class="btn btn-sm btn-outline-primary"
                >
                    Enregistrer
                </button>
            </span>
        </el-dialog>
        <!-- Dialog edit segment -->
        <el-dialog
            title="Modifier un segment"
            :visible.sync="dialogEditSegmentVisible"
            :append-to-body="true"
            :close-on-click-modal="false"
            id="modal-update-segment"
            width="20%"
        >
            <Segment :specs="specs" :segment="segment" />
            <span slot="footer" class="dialog-footer">
                <button
                    @click="updateSegment"
                    class="btn btn-sm btn-outline-primary"
                >
                    Modifier
                </button>
            </span>
        </el-dialog>
        <!-- Dialog show segments -->
        <el-dialog
            title="Segments"
            :visible.sync="dialogShowSegmentsVisible"
            :append-to-body="true"
            :close-on-click-modal="false"
            id="modal-segments"
            width="30%"
        >
            <span slot="footer" class="dialog-footer">
                <button
                    @click="dialogShowSegmentsVisible = false"
                    class="btn btn-sm btn-outline-primary"
                >
                    Fermer
                </button>
            </span>
        </el-dialog>
        <!-- Dialog show notes -->
        <el-dialog
            :title="'Historique (' + notesCount + ')'"
            :visible.sync="dialogNotesVisible"
            :append-to-body="true"
            :close-on-click-modal="true"
            id="modal-notes"
            width="30%"
        >
            <div v-if="notes" class="activity-boxes-w">
                <div
                    v-for="(v, k) in notes"
                    v-bind:key="k"
                    class="activity-box-w"
                >
                    <div class="activity-time"></div>
                    <div class="activity-box">
                        <div class="activity-info">
                            <span v-html="formatDate(v.created_at)"></span>
                            <div
                                v-if="v.user"
                                class="badge-user float-left"
                                :class="'badge-' + formatUserName(v.user.email)"
                                style="
                                    margin-right: 7px;
                                    float: right !important;
                                    top: -4px;
                                "
                            >
                                <span v-tooltip="v.user.name">{{
                                    formatUserName(v.user.email)
                                }}</span>
                            </div>
                            <p>{{ v.content }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </aside>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import axios from "axios";
import { Notification } from "element-ui";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _groupBy from "lodash/groupBy";
import _toArray from "lodash/toArray";
import * as moment from "moment";
import slugify from "slugify";
import VTooltip from "v-tooltip";
import VeeValidate from "vee-validate";
import Vue from "vue";
import VueLocalStorage from "vue-ls";
import VueUploadComponent from "vue-upload-component";
import Autocomplete from "../Autocomplete";
import Segment from "./_Segment.vue";
var mimetype2fa = require("mimetype-to-fontawesome")({ prefix: "fa fa-" });

Vue.use(VeeValidate, {
    errorBagName: "errorsSpecs",
});

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);
Vue.use(VTooltip);

moment.locale("fr");

export default {
    name: "devis-specs",
    components: { Autocomplete, FileUpload: VueUploadComponent, Segment },
    data() {
        return {
            type: this.$route.params.type,
            isOpen: Vue.ls.get("aside"),
            specs: {},
            status: [],
            failedStatus: [],
            users: [],
            options: {},
            customerEnabled: false,
            customer: {},
            events: null,
            notes: null,
            note: null,
            customer_attachments: [],
            user_attachments: [],
            activeCollapse: [],
            dialogNoteVisible: false,
            dialogUploadVisible: false,
            dialogAddSegmentVisible: false,
            dialogShowSegmentsVisible: false,
            dialogEditSegmentVisible: false,
            dialogNotesVisible: false,
            currentSegmentKey: -1,
            currentUser: JSON.parse(localStorage.getItem("currentUser")),
            apiUrl:
                window.location.hostname === "app-mydeckplanner.forestia.dev"
                    ? "https://api-mydeckplanner.forestia.dev"
                    : "https://api.mydeckplanner.com",
            files: [],
            showPreview:
                !this.$route.params.id &&
                this.$route.params.type === "garde-corps",
            countries: [],
            errors: {
                specs: {},
                customer: {},
                options: {},
            },
            // Default
            defaultSpecs: {
                terrasse: {},
                palissade: {
                    segments: [null],
                    family: "",
                    gamme: "",
                    pattern: "",
                    height: "",
                    angles: "",
                    depart: "poteau",
                    fin: "poteau",
                },
                pergola_alu: {
                    type: "",
                    couverture: "",
                    color: "",
                    store_color: "",
                    paroi_gauche_complete: 0,
                    paroi_droite_complete: 0,
                    paroi_gauche_triangle: 0,
                    paroi_droite_triangle: 0,
                    store_motorise_gauche: 0,
                    store_motorise_droite: 0,
                    store_motorise_facade: 0,
                    store_motorise_facade_qty: 0,
                    poteaux: 0,
                    porteurs: 0,
                    gouttiere: "",
                    eclairage: 0,
                    eclairage_qty: 0,
                    ral: "",
                },
                "garde-corps": {
                    type_garde_corps: "",
                    sens_platine: "",
                    main_courante: "",
                    remplissage: "",
                    ground: "",
                    segments: [],
                    depart: "",
                    fin: "",
                    type_inox: "",
                    unique_token: Math.floor(Math.random() * 100000000) + 1,
                },
                carport: {
                    toit: "",
                    couverture: "",
                    etancheite: "",
                },
                "garde-corps_louisiane": {
                    segments: [null],
                    angles: 0,
                    type: "",
                    ground: "",
                    fixation: "",
                },
                terrasse_pilotis: {
                    garde_corps: "aucun",
                    entraxe: "",
                },
                "garde-corps_jersey": {
                    product_id: "",
                    ground: "",
                    fixation: "",
                    segments: [null],
                    angles: 0,
                },
                pergola_bois: {
                    toit: "",
                    essence: "",
                    couverture: "",
                },
                chalet_bois: {
                    toit: "",
                    couverture: "",
                    epaisseur: "",
                    plancher: "",
                    vitrage: "",
                },
            },
            // Palissade
            heights: [],
            palissadePacks: [],
            palissadeFixations: {
                "a-fixer": {
                    name: "A fixer",
                    disabled: false,
                },
                "a-sceller": {
                    name: "A sceller",
                    disabled: false,
                },
            },
            families: [],
            // Pergola Alu
            pergolaAlu: {},
            pergolaAluTypes: {
                horizon: {
                    name: "Horizon",
                    colors: [
                        "Anthracite texturé",
                        "Blanc",
                        "Beige",
                        "Gris alu texturé",
                    ],
                    couvertures: ["Translucide", "Opalin", "Anti chaleur"],
                    storeColors: ["Anthracite", "Blanc"],
                },
                neolis: {
                    name: "Neolis",
                    colors: [
                        "Anthracite texturé",
                        "Blanc",
                        "Beige",
                        "Gris alu texturé",
                    ],
                    couvertures: ["Translucide", "Opalin", "Anti chaleur"],
                    storeColors: ["Anthracite", "Blanc"],
                },
                kitalys: {
                    name: "Kitalys",
                    colors: ["Anthracite texturé", "Blanc"],
                    couvertures: ["Translucide", "Opalin", "Anti chaleur"],
                    storeColors: ["Anthracite", "Blanc"],
                },
            },
            // Pergola Bois
            pergolaBoisTypes: {
                "toit-adosse": { name: "Pergola adossé" },
                "toit-autoportant": { name: "Pergola autoportante" },
            },
            pergolaBoisEssences: {
                pin: { name: "Pin" },
                "lamelle-colle": { name: "Lamellé collé" },
                douglas: { name: "Douglas" },
            },
            pergolaBoisCouvertures: {
                "polycarbonate-translucide": {
                    name: "Plaques de polycarbonate translucides",
                },
                "polycarbonate-fumees": {
                    name: "Plaques de polycarbonate fumées",
                },
                voliges: { name: "Voliges" },
            },
            // Carport
            carportTypes: {
                "plat-autoportant": { name: "Toit plat autoportant" },
                "plat-adosse": { name: "Toit plat adossé" },
                "double-pente": { name: "Toit double pente" },
                "toit-adosse": { name: "Toit adossé" },
            },
            carportCouvertures: {
                voliges: {
                    name: "Voliges",
                    etancheites: ["Feutre", "Shingle", "Aucune"],
                },
                alu: { name: "Tôle aluminium laqué", etancheites: ["Aucune"] },
                liteaux: { name: "Liteaux", etancheites: ["Aucune"] },
            },
            // Chalets bois
            chaletToits: {
                "toit-plat": { name: "Toit plat" },
                "toit-double-pente": { name: "Double pente" },
                "toit-simple-pente": { name: "Simple pente" },
            },
            chaletCouvertures: {
                shingle: { name: "Shingle" },
                "feutre-bitumineux": { name: "Feutre bitumineux" },
                liteaux: { name: "Liteaux" },
                sans: { name: "sans" },
            },
            // Garde-corps
            gardeCorpsTypes: ["Complet", "Main courante"],
            mainsCourantes: ["Inox", "Bois Ipé", "Bois Hêtre", "Bois Iroko"],
            departs: ["Mural", "Poteau"],
            types: ["Ligne droite", "Escalier montant", "Escalier descendant"],
            poteaux: ["Poteau à plat", "Poteau latéral"],
            sols: ["Bois", "Béton"],
            remplissages: ["Câble", "Tube", "Tube + plaques transparentes"],
            segment: {},
            translations: {
                type: "Type",
                height: "Hauteur",
                depth: "Profondeur",
                length: "Longueur",
                poteau: "Poteau",
                ground: "Sol",
                remplissage: "Remplissage",
                angle_rotation: "Angle de rotation",
                "ligne-droite": "Ligne droite",
                "poteau-a-plat": "Poteau à plat",
                "poteau-lateral": "Poteau latéral",
                "escalier-montant": "Escalier montant",
                "escalier-descendant": "Escalier descendant",
                cable: "Câble",
                "tube-+-plaques-transparentes": "Tube + plaques transparentes",
                beton: "Béton",
            },
            defaultSegment: {
                type: "",
                height: "",
                depth: "",
                length: "",
                poteau: "",
                ground: "",
                remplissage: "",
                angle_rotation: 0,
            },
            // Garde-corps Jersey
            gardeCorpsJerseyTypes: {
                JCP001: "5 câbles + 3 lames ajourées",
                JCV001: "5 câbles + 4 lames persiennées",
                JTP001: "3 tubes alu + 3 lames ajourées",
                JTV001: "3 tubes alu + 4 lames persiennées",
            },
            gardeCorpsJerseyGrounds: {
                bois: "Bois",
                beton: "Béton",
            },
            gardeCorpsJerseyFixations: {
                "a-plat": "A plat",
            },
            gardeCorpsLouisianeFixations: {
                "a-plat": "A plat",
                laterale: "Latérale",
            },
            // Garde-corps Louisiane
            gardeCorpsLouisianeTypes: {
                "sans-decor": {
                    name: "Sans décor",
                },
                "avec-decor": {
                    name: "Avec décor",
                },
            },
            gardeCorpsLouisianeGrounds: {
                bois: "Bois",
                beton: "Béton",
            },
            // Terrasse pilotis
            terrassePilotisGardeCorps: {
                inox: "Garde-corps Inox",
                jersey: "Garde-corps Bois Jersey",
                louisiane: "Garde-corps Bois Louisiane",
                aucun: "Aucun",
            },
            terrassePilotisEntraxes: {
                0.3: "30",
                0.35: "35",
                0.4: "40",
                0.45: "45",
                0.5: "50",
            },
        };
    },
    watch: {
        customer: {
            handler() {
                EventBus.$emit("refresh-customer", this.customer);
            },
            deep: true,
        },
        options: {
            handler() {
                EventBus.$emit("refresh-options", this.options);
            },
            deep: true,
        },
        "specs.type": {
            handler(val) {},
            deep: true,
        },
        "specs.color": {
            handler(val) {
                if(this.type === 'pergola_alu') {
                    const availableRals = [];

                    for (let key in this.pergolaAlu.colors) {
                        availableRals.push(this.pergolaAlu.colors[key].ral);
                    }

                    if (val === "RAL personnalisé") {
                        if (availableRals.indexOf(this.specs.ral) < 0) {
                            this.specs.ral = this.specs.ral;
                        } else {
                            this.specs.ral = "";
                        }
                    } else {
                        this.specs.ral = this.pergolaAlu.colors[val].ral;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        authToken() {
            return "Bearer " + localStorage.getItem("token");
        },
        eventsCount() {
            return this.events !== undefined && this.events !== null
                ? this.events.length
                : 0;
        },
        notesCount() {
            return this.notes !== undefined && this.notes !== null
                ? this.notes.length
                : 0;
        },
        attachmentsCount() {
            return (
                (this.customer_attachments !== undefined &&
                this.customer_attachments !== null
                    ? this.customer_attachments.length
                    : 0) +
                (this.user_attachments !== undefined &&
                this.user_attachments !== null
                    ? this.user_attachments.length
                    : 0)
            );
        },
        userCompany() {
            return JSON.parse(localStorage.getItem("currentUser")).company;
        },
        computeDisabled() {
            var authorized = [
                "terrasse",
                "palissade",
                "garde-corps",
                "pergola_alu",
                "carport",
                "chalet_bois",
                "pergola_bois",
                "terrasse_pilotis",
                "garde-corps_jersey",
                "garde-corps_louisiane",
            ];

            return authorized.indexOf(this.type) < 0;
        },
    },
    methods: {
        // Reset pergola alu options
        resetPergolaAluOptions() {
            this.specs.paroi_gauche_complete = false;
            this.specs.paroi_droite_complete = false;
            this.specs.paroi_gauche_triangle = false;
            this.specs.paroi_droite_triangle = false;
            this.specs.store_motorise_gauche = false;
            this.specs.store_motorise_facade = false;
            this.specs.store_motorise_droite = false;
            this.specs.poteaux = 0;
            this.specs.porteurs = 0;
            this.specs.color = "";
            this.specs.ral = "";
        },

        resetGamme() {
            this.specs.gamme = "";
        },

        resetPattern() {
            this.specs.pattern = "";
        },

        resetHeight() {
            this.specs.height = "";
        },

        groupedFamily(family) {
            return _groupBy(family, function (b) {
                return b.Gamme;
            });
        },

        // Toggle aside
        toggleAside() {
            this.isOpen = !this.isOpen;
            Vue.ls.set("aside", this.isOpen);
        },

        // Close modal
        closeModalNote() {
            this.dialogNoteVisible = false;
            this.note = null;
        },

        // Create note
        createNote() {
            if (this.note === null) {
                return false;
            }
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .post("/v2/devis/note/" + this.$route.params.id, {
                    note: this.note,
                })
                .then(function (response) {
                    vm.dialogNoteVisible = false;
                    vm.note = null;
                    vm.notes = response.data.notes;
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Succès",
                        message: "Note créee",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.message,
                        type: "error",
                    });
                });
        },

        // Compute data
        computeData() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .post(
                    "/v2/devis/" +
                        this.type +
                        "/compute?site=" +
                        this.userCompany,
                    this.specs
                )
                .then(function (response) {
                    // Reset errors
                    vm.errors.specs = {};
                    // Toggle loader
                    EventBus.$emit("toggle-loading", false);
                    if (response.data.success) {
                        // Set specs
                        vm.specs = response.data.specs;
                        // Refresh items
                        let items = response.data.items;
                        _forEach(response.data.items, function (v, k) {
                            items[k] = _toArray(v);
                        });
                        EventBus.$emit("refresh-items", items);
                        EventBus.$emit("refresh-specs", response.data.specs);
                        EventBus.$emit(
                            "refresh-gc-preview",
                            response.data.images
                        );
                        if (vm.showPreview) {
                            EventBus.$emit("show-gc-preview");
                        } else {
                            EventBus.$emit("hide-gc-preview");
                        }
                    } else if (response.data.warning) {
                        Notification({
                            title: "Attention",
                            message: response.data.warning,
                            type: "warning",
                        });
                    } else {
                        Notification({
                            title: "Erreur",
                            message: response.data.error,
                            type: "error",
                        });
                    }
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    if (error.response.status === 422) {
                        vm.activeCollapse = ["specs"];
                        var errors = {};
                        _forEach(error.response.data, function (v, k) {
                            errors[k] = v[0];
                        });
                        vm.errors.specs = errors;
                        Notification({
                            title: "Erreur",
                            message:
                                Object.keys(error.response.data).length +
                                " champs incorrects",
                            type: "error",
                        });
                    } else {
                        Notification({
                            title: "Erreur",
                            message: error.message,
                            type: "error",
                        });
                    }
                });
        },

        // Format date
        formatDate(date) {
            if (
                moment().format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ) {
                return (
                    '<span class="activity-title" style="display: inline; text-transform: unset;"><strong>' +
                    moment(date).from(moment()) +
                    "</strong></span>"
                );
            }
            return (
                '<span class="activity-title" style="display: inline;"><strong>' +
                moment(date).format("D MMMM YYYY") +
                '</strong></span> <span class="activity-role">' +
                moment(date).format("HH:mm") +
                "</span>"
            );
        },

        // Format Username
        formatUserName(e) {
            return e.substr(0, 2);
        },

        // Check valid segment
        checkSegment() {
            EventBus.$emit("is-valid-segment", false);
        },

        // Add new segment
        addSegment() {
            if (this.type === "palissade") {
                if (this.specs.segments.length < 10) {
                    this.specs.segments.push(null);
                }
            } else {
                this.specs.angles++;
                this.specs.segments.push(this.segment);
                this.dialogAddSegmentVisible = false;
                this.segment = JSON.parse(JSON.stringify(this.defaultSegment));
            }
        },

        // Update area
        updateArea() {
            if (
                this.specs.length !== undefined &&
                this.specs.length > 0 &&
                this.specs.width !== undefined &&
                this.specs.width > 0
            ) {
                this.specs.area = this.specs.length * this.specs.width;
            }
        },

        // Delete segment (Palissade)
        deleteSegment(index) {
            if (this.specs.segments.length > 0) {
                this.specs.angles--;
                this.specs.segments.splice(index, 1);
            }
        },

        // Fetch countries
        fetchCountries() {
            let vm = this;
            axios.get("/v2/countries").then(function (response) {
                vm.countries = response.data;
            });
        },

        fetchPalissadePacks() {
            let vm = this;
            axios.get("/v2/devis/palissade/packs").then(function (response) {
                vm.palissadePacks = response.data;
            });
        },

        fetchPergolaAluInfos() {
            let vm = this;
            axios.get("/v2/devis/pergola_alu").then(function (response) {
                vm.pergolaAlu = response.data;
            });
        },

        // Fetch devis status
        fetchStatus() {
            let vm = this;
            axios.get("/v2/devis/status").then(function (response) {
                vm.status = response.data;
            });
        },

        // Fetch devis users
        fetchUsers() {
            let vm = this;
            axios.get("/v2/devis/users").then(function (response) {
                vm.users = response.data;
            });
        },

        // Fetch failed status
        fetchFailedStatus() {
            let vm = this;
            axios.get("/v2/devis/failed_status").then(function (response) {
                vm.failedStatus = response.data;
            });
        },

        // Set pro discount
        setProDiscount() {
            EventBus.$emit("pro-discount", this.customer.professional);
        },

        // To slug
        toSlug(c) {
            return slugify(c, { lower: true });
        },

        // Open dialog segment
        openDialogEditSegment(key) {
            this.currentSegmentKey = key;
            this.segment = this.specs.segments[key];
            this.dialogEditSegmentVisible = true;
        },

        // Update segment
        updateSegment() {
            this.specs.segments[this.currentSegmentKey] = this.segment;
            this.dialogEditSegmentVisible = false;
            this.segment = JSON.parse(JSON.stringify(this.defaultSegment));
        },

        // Show GC preview
        showGcPreview() {
            EventBus.$emit("show-gc-preview", []);
        },

        // Translate
        translate(str) {
            if (this.translations[str] !== undefined) {
                return this.translations[str];
            }
            return str;
        },

        // Process files
        inputFile(newFile, oldFile) {
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                if (newFile.xhr) {
                    if (newFile.xhr.status === 200) {
                        Notification({
                            title: "Succès",
                            message:
                                newFile.name + " a été uploadé avec succès",
                            type: "success",
                        });
                        let uploadedIndex = _findIndex(this.user_attachments, [
                            "id",
                            oldFile.id,
                        ]);
                        this.user_attachments[uploadedIndex] =
                            newFile.response.result;
                    } else {
                        Notification({
                            title: "Erreur",
                            message:
                                "Impossible de sauvegarder le fichier " +
                                newFile.name,
                            type: "error",
                        });
                    }
                }
            }
        },

        // Filter files
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (
                    !/\.(jpeg|jpe|jpg|gif|png|webp|pdf|xlsx)$/i.test(
                        newFile.name
                    )
                ) {
                    return prevent();
                }
            }
            newFile.blob = "";
            let URL = window.URL || window.webkitURL;
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file);
                this.$refs.upload.active = true;
            }
        },

        // Mime icon
        getMimeIcon(mime) {
            return mimetype2fa(mime);
        },

        // Delete attachment
        deleteAttachment(key) {
            let vm = this;
            let attachment = this.user_attachments[key];
            EventBus.$emit("toggle-loading", true);
            axios
                .delete("/v2/upload/" + attachment.id)
                .then(function () {
                    vm.user_attachments.splice(key, 1);
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Succès",
                        message: "Fichier éffacé avec succès",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.message,
                        type: "error",
                    });
                });
        },

        // File size
        fileSize(size) {
            let i = Math.floor(Math.log(size) / Math.log(1024));
            return (
                (size / Math.pow(1024, i)).toFixed(2) * 1 +
                " " +
                ["B", "kB", "MB", "GB", "TB"][i]
            );
        },

        // Delete linked item
        deleteLinkedItem(key) {
            this.options.linked_items.splice(key, 1);
        },

        // Force palissade CUBA fixation
        setFixation() {
            if (this.type === "palissade" && this.specs.family === "CUBA") {
                for (var key in this.palissadeFixations) {
                    this.palissadeFixations[key].disabled = false;
                }
                if (this.specs.height > 180) {
                    this.palissadeFixations["a-fixer"].disabled = true;
                    this.specs.fixation = "a-sceller";
                }
                if (this.specs.pattern === "ALU2") {
                    this.palissadeFixations["a-sceller"].disabled = true;
                    this.specs.fixation = "a-fixer";
                }
            }
        },
    },
    mounted() {
        this.segment = JSON.parse(JSON.stringify(this.defaultSegment));
        this.fetchCountries();
        this.fetchStatus();
        this.fetchUsers();
        this.fetchFailedStatus();

        if (this.$route.params.type === "palissade") {
            this.fetchPalissadePacks();
        }

        if (this.$route.params.type === "pergola_alu") {
            this.fetchPergolaAluInfos();
        }

        // eslint-disable-next-line
        EventBus.$on("toggle-aside", (data) => {
            this.toggleAside();
        });
        EventBus.$on("devis-specs", (data) => {
            this.specs =
                data !== undefined && data !== null
                    ? data
                    : this.defaultSpecs[this.type];
            this.setFixation();
        });
        EventBus.$on("devis-events", (data) => {
            this.events = data;
        });
        EventBus.$on("devis-status", (data) => {
            this.options.status_id = data.id;
        });
        EventBus.$on("devis-options", (data) => {
            this.options = data;
        });
        EventBus.$on("devis-customer", (data) => {
            if (data !== undefined && data !== null) {
                this.customer = data;
                this.customerEnabled = true;
                if (!this.customer.country) {
                    this.customer.country = "France";
                }
            } else {
                this.customer = {};
            }
        });
        EventBus.$on("devis-notes", (data) => {
            this.notes = data;
            if (
                this.notes &&
                this.notes.length > 0 &&
                this.currentUser.role !== "admin"
            ) {
                this.dialogNotesVisible = true;
            }
        });
        EventBus.$on("devis-customer_attachments", (data) => {
            this.customer_attachments = data;
        });
        EventBus.$on("devis-user_attachments", (data) => {
            this.user_attachments = data;
        });
        EventBus.$on("update-lame", (data) => {
            this.specs.product_id = data.value.Id;
        });
        EventBus.$on("palissade-families", (data) => {
            this.families = data;
        });
        EventBus.$on("update-customer", (data) => {
            this.customer = data.value;
        });
        EventBus.$on("open-collapse", (data) => {
            this.activeCollapse = [data.collapse];
            this.errors[data.errorBag] = data.errors;
        });
        EventBus.$on("errors-customer", (data) => {
            if (data) {
                this.activeCollapse = ["customer"];
                var errors = {};
                _forEach(data, function (v, k) {
                    errors[k.split(".")[1]] = v[0];
                });
                this.errors.customer = errors;
            } else {
                this.errors.customer = {};
            }
        });
        EventBus.$on("errors-options", (data) => {
            if (data) {
                this.activeCollapse = ["options"];
                this.errors.options = data;
            } else {
                this.errors.options = {};
            }
        });
        // eslint-disable-next-line
        EventBus.$on("add-segment", (data) => {
            this.addSegment();
        });
    },
};
</script>

<style lang="scss" scoped>
.custom-control-inline.disabled {
    opacity: 0.5;
}
.linked_items-container {
}
.badge-linked_items {
    margin: 10px 5px 0 0;
    a {
        color: #fff;
        text-decoration: none;
        margin-left: 2px;
        &:hover {
            color: #dc3545;
        }
    }
}
.list-group-upload {
    .list-group-item {
        &.header {
            background: #dde2ec;
        }
    }
}
.files-upload {
    height: 200px;
    border: 2px dashed #dde2ec;
    margin-bottom: 15px;
    &.drop-active {
        border-color: #0a7af8;
    }
}
.file-uploads {
    width: 100%;
}
.files-upload div {
    width: 100%;
}
.files-upload h6 {
    text-transform: uppercase;
    font-size: 0.8rem;
}
.files-upload h6 i {
    display: block;
    font-size: 30px;
    color: #0a7af8;
}
.text-muted.text-danger {
    color: #e65252 !important;
}
.input-group-btn {
    border: solid 2px #dde2ec;
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.has-danger .input-group-btn {
    border-color: #e65252;
}
.has-danger .custom-control {
    color: #e65252;
}
.activity-boxes-w .activity-box-w:before {
    left: 0;
}
.activity-boxes-w .activity-time {
    flex: 0 0 26px;
    padding-right: 0;
}
.activity-boxes-w .activity-box-w:after {
    left: 6px;
}
.activity-boxes-w .activity-box p {
    font-style: italic;
    font-size: 12px;
    border-top: solid 1px #eceef5;
    padding-top: 5px;
    margin-top: 5px;
    margin-bottom: 0;
}
.timed-activities.compact .timed-activity .ta-record-w {
    padding-left: 15px;
}
.timed-activity .ta-timestamp {
    width: 100%;
    padding-right: 5px;
}
.badge-user {
    position: relative;
    width: 25px;
    height: 14.43px;
    margin: 7.22px 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background-color: #3f5369;
    &:before {
        bottom: 100%;
        border-bottom: 7.22px solid #3f5369;
    }
    &:after {
        top: 100%;
        width: 0;
        border-top: 7.22px solid #3f5369;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 12.5px solid transparent;
        border-right: 12.5px solid transparent;
    }
    &.badge-cf {
        background-color: #f7749b;
        &:before {
            border-bottom-color: #f7749b;
        }
        &:after {
            border-top-color: #f7749b;
        }
    }
    &.badge-gd {
        background-color: #26af3a;
        &:before {
            border-bottom-color: #26af3a;
        }
        &:after {
            border-top-color: #26af3a;
        }
    }
    &.badge-tb {
        background-color: #c4add2;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        span {
            color: gold;
        }
        &:before {
            border-bottom-color: #c4add2;
        }
        &:after {
            border-top-color: #c4add2;
        }
    }
    span {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 25px;
        height: 14.4338px;
        z-index: 2;
        background: inherit;
        font-weight: 500;
        font-size: 80%;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        line-height: 15px;
    }
}
.btn-toggle {
    border-bottom: solid 1px #eceef5;
    padding: 0 15px;
    text-align: left;
    line-height: 33px;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}
.el-collapse {
    border-top: 0;
}
aside {
    width: 0;
    top: -34px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    border-left: solid 1px #e6e8eb;
    z-index: 1;
    transition: max-width 0.2s ease;
    min-height: 125vh;
    &.is-opened {
        width: 300px;
    }
}
.form-control {
    &.input-sm {
        padding: 0.275rem 0.75rem;
        font-size: 0.8rem;
    }
}
.form-label {
    display: block;
    font-weight: 600;
}
.segments-group {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
}
.segment {
    float: left;
    width: 50px;
    height: 50px;
    background: #f2f4f8;
    border: solid 1px #e1e3e5;
    margin: 0 10px 10px 0;
    border-radius: 50%;
}
.segment label {
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 50px;
}

#input-group-cost_price .input-group-text {
    background: #0e79f8;
    color: #fff;
    border-color: #0e79f8;
}
#input-group-cost_price .form-control {
    border-color: #0e79f8;
}

#input-group-cost_price.has-danger .input-group-text {
    background: #e65252;
    color: #fff;
    border-color: #e65252;
}
#input-group-cost_price.has-danger .form-control {
    border-color: #e65252;
}
</style>
<style lang="scss">
.el-date-editor--date {
    border: 0;
    .el-input__prefix {
        color: inherit;
    }
    .el-input__inner {
        border: 2px solid #dde2ec;
        height: 31.78px;
        &:focus {
            border-color: #047bf8 !important;
        }
    }
}

.has-danger .el-date-editor--date .el-input__inner {
    border-color: #e65252 !important;
}
.activity-info .activity-content h6 {
    font-size: 0.72rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.3px;
    border-bottom: dotted 1px;
    display: inline-block;
    margin-bottom: 0;
}
.activity-info .activity-content a {
    font-style: normal;
    text-transform: uppercase;
    font-size: 80%;
    font-family: inherit !important;
    -webkit-font-smoothing: auto !important;
}
.activity-info .activity-content a:hover {
    text-decoration: none;
    color: #495057;
}
.activity-info .activity-content a:before {
    font-family: "osfont" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 2px;
}
.custom-control-label {
    cursor: pointer;
    font-weight: 300;
}

select.form-control.input-sm {
    height: 31.78px !important;
}

.custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.7;
}

#modal-note {
    .el-dialog__header {
        padding: 20px 20px 0;
    }
    .el-dialog__body {
        padding: 20px 20px 10px;
    }
    .el-dialog__title {
        font-size: 16px;
        text-transform: uppercase;
    }
    .btn {
        margin-left: 5px;
    }
}
.has-danger {
    .form-check-inline {
        color: #e65251;
    }
}
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
</style>
<style>
.el-collapse-item__header {
    padding-left: 15px;
    font-weight: 100;
    font-size: 12px;
    display: block;
    text-transform: uppercase;
    color: #3e4b5b;
    background-color: #f9f9f9;
}
.el-collapse-item__header.is-active {
    border-bottom-color: #ebeef5;
    color: #077af8;
}
.el-collapse-item__content {
    padding: 15px;
}
.el-collapse-item__content h2 {
    text-transform: uppercase;
    border-bottom: solid 1px #eceef5;
    clear: both;
    text-align: center;
    margin: -15px -15px 15px -15px;
    font-size: 14px;
}
.el-collapse-item__content h2 span {
    clear: both;
    background-color: #fff;
    display: inline-block;
    position: relative;
    top: 9px;
    padding: 0 15px;
}
.el-collapse-item__header.is-active {
    border-bottom-color: #077af8;
    color: #fff;
    background: #077af8;
}
.el-dialog__header {
    padding: 10px 20px;
    border-bottom: solid 1px #eceef5;
}
.el-dialog__headerbtn {
    top: auto;
}
fieldset {
    margin: 0;
}
fieldset .form-group:last-child {
    margin-bottom: 0;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin: 0 -15px;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
<style>
.segment-detail {
    margin: 0;
    padding: 0;
    list-style: none;
}
.segment-detail li:before {
    content: "\f218";
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #047af8;
    margin-right: 5px;
}
.segment-detail li {
    margin-bottom: 5px;
    text-transform: capitalize;
    font-size: 12px;
}
.segment-detail li strong {
    color: #000;
    font-weigth: 600;
    text-transform: uppercase;
}
#modal-add-segment label,
#modal-update-segment label {
    text-transform: uppercase;
    font-size: 80%;
}
#modal-notes .el-dialog__body {
    padding: 20px 20px 30px;
    background-color: #f2f4f8;
    max-height: 550px;
    overflow: scroll;
}
.el-collapse-item__header {
    font-weight: 500;
}
</style>
