<template>
    <div id="header" class="top-bar color-scheme-light">
        <!-- User selector -->
        <div
            id="user-selector"
            class="fancy-selector-w"
            v-show="showTeamSelector"
            :class="isOpenedTeam"
        >
            <div
                class="fancy-selector-current"
                @click="openTeamSelector()"
                style="cursor: pointer"
            >
                <div class="fs-img shadowless">
                    <div
                        style="
                            width: 39px;
                            height: 39px;
                            background-size: cover;
                            background-repeat: no-reapeat;
                            background-size: cover;
                            background-position: center;
                            border-radius: 50%;
                            overflow: hidden;
                        "
                        :style="{
                            'background-image':
                                'url(' + selectedUser.image + ')',
                        }"
                    ></div>
                </div>
                <div class="fs-main-info">
                    <div class="fs-name">{{ selectedUser.name }}</div>
                    <div class="fs-sub">
                        <strong>{{ selectedUser.function }}</strong>
                    </div>
                </div>
                <div class="fs-selector-trigger">
                    <i class="os-icon os-icon-arrow-down4"></i>
                </div>
            </div>
            <div class="fancy-selector-options">
                <div
                    v-for="user in team"
                    class="fancy-selector-option"
                    v-bind:key="user.id"
                    @click="selectCurrentUser(user)"
                >
                    <div class="fs-img shadowless">
                        <div
                            style="
                                width: 39px;
                                height: 39px;
                                background-size: cover;
                                background-repeat: no-reapeat;
                                background-size: cover;
                                background-position: center;
                                border-radius: 50%;
                                overflow: hidden;
                                background-color: #fff;
                            "
                            :style="{
                                'background-image': 'url(' + user.image + ')',
                            }"
                        ></div>
                    </div>
                    <div class="fs-main-info">
                        <div class="fs-name">{{ user.name }}</div>
                        <div class="fs-sub">
                            <strong>{{ user.function }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Products selector -->
        <div
            id="products-selector"
            class="fancy-selector-w"
            v-show="showProductsSelector"
            :class="isOpenedProducts"
            style="margin-left: 1px"
        >
            <div
                class="fancy-selector-current"
                @click="openProductsSelector()"
                style="cursor: pointer; min-width: auto"
            >
                <div class="fs-img shadowless"></div>
                <div class="fs-main-info">
                    <div class="fs-name">Produits</div>
                    <div class="fs-sub">
                        <strong
                            >{{ selectedProducts.length }} produit(s)
                            séléctionné(s)</strong
                        >
                    </div>
                </div>
                <div class="fs-selector-trigger">
                    <i class="os-icon os-icon-arrow-down4"></i>
                </div>
            </div>
            <div class="fancy-selector-options">
                <div
                    v-for="product in userProducts"
                    class="fancy-selector-option"
                    v-bind:key="product"
                >
                    <div class="fs-img shadowless"></div>
                    <div class="fs-main-info">
                        <div
                            class="fs-name"
                            style="line-height: inherit; margin-bottom: 0"
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    v-model="selectedProducts"
                                    name="products"
                                    class="custom-control-input"
                                    :id="'product-' + product"
                                    :value="product"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="'product-' + product"
                                    style="
                                        text-transform: capitalize;
                                        cursor: pointer;
                                    "
                                    >{{ product.replace("_", " ") }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Period selector -->
        <div
            id="period-selector"
            class="fancy-selector-w"
            v-show="showPeriodSelector"
            :class="isOpenedPeriod"
            style="margin-left: 1px"
        >
            <div
                class="fancy-selector-current"
                @click="openPeriodSelector()"
                style="cursor: pointer; min-width: auto"
            >
                <div class="fs-img shadowless"></div>
                <div class="fs-main-info">
                    <div class="fs-name">Période</div>
                    <div class="fs-sub">
                        <strong>{{ periods[selectedPeriod] }}</strong>
                    </div>
                </div>
                <div class="fs-selector-trigger">
                    <i class="os-icon os-icon-arrow-down4"></i>
                </div>
            </div>
            <div class="fancy-selector-options">
                <div
                    v-for="(v, k) in periods"
                    class="fancy-selector-option"
                    v-bind:key="k"
                    @click="selectCurrentPeriod(k)"
                >
                    <div class="fs-img shadowless"></div>
                    <div class="fs-main-info">
                        <div
                            class="fs-name"
                            style="line-height: inherit; margin-bottom: 0"
                        >
                            {{ v }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Custom period -->
        <div
            class="fancy-selector-w"
            v-if="selectedPeriod === 'custom' && showCustomPeriodSelector"
            style="margin-left: 1px"
        >
            <div
                class="fancy-selector-current"
                style="cursor: pointer; min-width: auto"
            >
                <div style="width: 225px">
                    <date-picker
                        v-model="customPeriod"
                        lang="fr"
                        format="yyyy-MM-dd"
                        :shortcuts="[]"
                        placeholder="Période pérsonalisée"
                        range
                        confirm
                    ></date-picker>
                </div>
                <div class="fs-selector-trigger">
                    <i class="os-icon os-icon-calendar"></i>
                </div>
            </div>
        </div>
        <!-- Archive
        <div>
            <div
                class="custom-control custom-checkbox"
                id="archives-checkbox"
                style="margin-left: 20px"
            >
                <input
                    type="checkbox"
                    id="quote_archived"
                    name="quote_archived"
                    @click="toggleDeletedAt()"
                    v-model="deletedAt"
                    class="custom-control-input"
                />
                <label for="quote_archived" class="custom-control-label">
                    Inclure les devis archivées
                </label>
            </div>
        </div>
         -->
        <!-- Controls -->
        <div class="top-menu-controls">
            <div
                class="messages-notifications os-dropdown-trigger os-dropdown-position-left"
                @mouseover="hovered = true"
                @mouseleave="hovered = false"
                :class="hovered ? 'over' : ''"
            >
                <i class="os-icon os-icon-mail-14"></i>
                <div class="new-messages-count">{{ eventsCount }}</div>
                <div class="os-dropdown light message-list">
                    <ul v-if="hasEvents">
                        <li v-for="(v, k) in events" v-bind:key="k">
                            <a href="">
                                <div class="user-avatar-w">
                                    <img alt="" :src="v.user.image" />
                                </div>
                                <div class="message-content">
                                    <h6 class="message-from">
                                        <small>{{ v.created_at }}</small>
                                    </h6>
                                    <h6 class="message-title">{{ v.value }}</h6>
                                </div>
                            </a>
                        </li>
                        <li class="all-events">
                            <a href="" class="text-primary text-center">
                                Voir tous les évènements
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="currentUser" class="logged-user-w">
                <div class="logged-user-i">
                    <div class="avatar-w">
                        <div
                            style="
                                width: 40px;
                                height: 40px;
                                background-size: cover;
                                background-repeat: no-reapeat;
                                background-size: cover;
                            "
                            :style="{
                                'background-image':
                                    'url(' + currentUser.image + ')',
                            }"
                        ></div>
                    </div>
                    <div class="logged-user-menu">
                        <div class="logged-user-avatar-info">
                            <div class="avatar-w">
                                <div
                                    style="
                                        width: 40px;
                                        height: 40px;
                                        background-size: cover;
                                        background-repeat: no-reapeat;
                                        background-size: cover;
                                    "
                                    :style="{
                                        'background-image':
                                            'url(' + currentUser.image + ')',
                                    }"
                                ></div>
                            </div>
                            <div class="logged-user-info-w">
                                <div class="logged-user-name">
                                    {{ currentUser.name }}
                                </div>
                                <div class="logged-user-role">
                                    {{ userRole }}
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <router-link :to="{ name: 'logout' }">
                                    <i class="os-icon os-icon-signs-11"></i>
                                    <span>Déconnexion</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import axios from "axios";
import { EventBus } from "@/event-bus.js";
import VueLocalStorage from "vue-ls";
import DatePicker from "vue2-datepicker";

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);

export default {
    name: "TopMenu",
    components: { DatePicker },
    data() {
        return {
            leader: {},
            team: [],
            events: [],
            eventsCount: 0,
            hovered: false,
            teamSelectorOpened: false,
            productsSelectorOpened: false,
            periodSelectorOpened: false,
            currentUser: JSON.parse(store.state.currentUser),
            selectedUser:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedUser
                    : JSON.parse(store.state.currentUser),
            selectedPeriod:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedPeriod
                    : "day",
            selectedProducts:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedProducts
                    : JSON.parse(store.state.currentUser).products.split("|"),
            customPeriod:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").customPeriod
                    : null,
            deletedAt:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").deletedAt
                    : false,
            periods: {
                day: "Aujourd'hui",
                week: "Cette semaine",
                month: "Ce mois",
                year: "Cette année",
                all: "Total",
                custom: "Personnalisée",
            },
        };
    },
    computed: {
        userRole() {
            let roles = {
                admin: "Administrateur",
                manager: "Manager",
                user: "Utilisateur",
            };
            return roles[this.currentUser.role];
        },
        userProducts() {
            return this.selectedUser.products !== undefined
                ? this.selectedUser.products.split("|")
                : null;
        },
        hasEvents() {
            return this.events.length > 0;
        },
        showTeamSelector() {
            return this.$route.name === "dashboard";
        },
        showProductsSelector() {
            return this.$route.name === "dashboard";
        },
        showPeriodSelector() {
            return this.$route.name === "dashboard";
        },
        showCustomPeriodSelector() {
            return this.$route.name === "dashboard";
        },
        isOpenedTeam() {
            return this.teamSelectorOpened ? "opened" : "";
        },
        isOpenedProducts() {
            return this.productsSelectorOpened ? "opened" : "";
        },
        isOpenedPeriod() {
            return this.periodSelectorOpened ? "opened" : "";
        },
    },
    watch: {
        $route() {
            Vue.ls.set("dashboard", {
                selectedUser: this.selectedUser,
                selectedPeriod: this.selectedPeriod,
                selectedProducts: this.selectedProducts,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            EventBus.$emit("update-dashboard", {
                selectedUser: this.selectedUser,
                selectedProducts: this.selectedProducts,
                selectedPeriod: this.selectedPeriod,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            console.log("DELETE AT = " + this.deletedAt);
        },
        selectedUser() {
            Vue.ls.set("dashboard", {
                selectedUser: this.selectedUser,
                selectedPeriod: this.selectedPeriod,
                selectedProducts: this.selectedProducts,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            EventBus.$emit("update-dashboard", {
                selectedUser: this.selectedUser,
                selectedProducts: this.selectedProducts,
                selectedPeriod: this.selectedPeriod,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
        },
        selectedProducts() {
            Vue.ls.set("dashboard", {
                selectedUser: this.selectedUser,
                selectedPeriod: this.selectedPeriod,
                selectedProducts: this.selectedProducts,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            EventBus.$emit("update-dashboard", {
                selectedUser: this.selectedUser,
                selectedProducts: this.selectedProducts,
                selectedPeriod: this.selectedPeriod,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            this.productsSelectorOpened = false;
        },
        selectedPeriod() {
            if (this.selectedPeriod === "custom") {
                if (this.customPeriod !== "" && this.customPeriod !== null) {
                    Vue.ls.set("dashboard", {
                        selectedUser: this.selectedUser,
                        selectedPeriod: this.selectedPeriod,
                        selectedProducts: this.selectedProducts,
                        customPeriod: this.customPeriod,
                        deletedAt: this.deletedAt,
                    });
                    EventBus.$emit("update-dashboard", {
                        selectedUser: this.selectedUser,
                        selectedProducts: this.selectedProducts,
                        selectedPeriod: this.selectedPeriod,
                        customPeriod: this.customPeriod,
                        deletedAt: this.deletedAt,
                    });
                }
            } else {
                Vue.ls.set("dashboard", {
                    selectedUser: this.selectedUser,
                    selectedPeriod: this.selectedPeriod,
                    selectedProducts: this.selectedProducts,
                    customPeriod: this.customPeriod,
                    deletedAt: this.deletedAt,
                });
                EventBus.$emit("update-dashboard", {
                    selectedUser: this.selectedUser,
                    selectedProducts: this.selectedProducts,
                    selectedPeriod: this.selectedPeriod,
                    customPeriod: this.customPeriod,
                    deletedAt: this.deletedAt,
                });
            }
        },
        customPeriod() {
            Vue.ls.set("dashboard", {
                selectedUser: this.selectedUser,
                selectedPeriod: this.selectedPeriod,
                selectedProducts: this.selectedProducts,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            EventBus.$emit("update-dashboard", {
                selectedUser: this.selectedUser,
                selectedProducts: this.selectedProducts,
                selectedPeriod: this.selectedPeriod,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
        },
        deletedAt() {
            console.log("DELETE AT = " + this.deletedAt);
            Vue.ls.set("dashboard", {
                selectedUser: this.selectedUser,
                selectedPeriod: this.selectedPeriod,
                selectedProducts: this.selectedProducts,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
            EventBus.$emit("update-dashboard", {
                selectedUser: this.selectedUser,
                selectedProducts: this.selectedProducts,
                selectedPeriod: this.selectedPeriod,
                customPeriod: this.customPeriod,
                deletedAt: this.deletedAt,
            });
        },
    },
    methods: {
        // Fetch data
        fetchData() {
            this.fetchLeader();
            this.fetchTeam();
        },
        // Fetch User team
        fetchLeader() {
            let vm = this;
            axios
                .get("v2/users/" + this.currentUser.id + "/leader")
                .then(function (response) {
                    vm.leader = response.data.user;
                    vm.selectedUser = vm.leader;
                    EventBus.$emit("user-dashboard", {
                        user: vm.selectedUser.id,
                        period: vm.period,
                    });
                });
        },
        // Fetch User team
        fetchTeam() {
            let vm = this;
            axios
                .get("v2/users/" + this.currentUser.id + "/team")
                .then(function (response) {
                    vm.team = response.data;
                });
        },
        hoverElement() {
            this.hovered = true;
        },
        // Team selector
        openTeamSelector() {
            this.teamSelectorOpened = !this.teamSelectorOpened;
        },
        // Products selector
        openProductsSelector() {
            this.productsSelectorOpened = !this.productsSelectorOpened;
        },
        // Period selector
        openPeriodSelector() {
            this.periodSelectorOpened = !this.periodSelectorOpened;
        },
        // Select current user
        selectCurrentUser(user) {
            this.selectedUser = user;
            this.teamSelectorOpened = false;
        },
        // Select current period
        selectCurrentPeriod(period) {
            this.selectedPeriod = period;
            this.periodSelectorOpened = false;
        },
        toggleDeletedAt() {
            this.deletedAt = !this.deletedAt;
        },
    },
    mounted() {
        EventBus.$emit("update-dashboard", {
            selectedUser: this.selectedUser,
            selectedProducts: this.selectedProducts,
            selectedPeriod: this.selectedPeriod,
            deletedAt: this.deletedAt,
        });
        EventBus.$on("user-team", (data) => {
            this.team = data;
        });
    },
};
</script>

<style>
#header {
    min-height: 59px;
}
.message-list {
    padding: 0 !important;
}
.all-events a {
    text-transform: uppercase;
    font-size: 70% !important;
    display: block !important;
    background-color: rgba(124, 137, 234, 0.07);
}
.top-bar .logged-user-w .logged-user-menu {
    top: -12px;
    border-radius: 0;
}
.fancy-selector-options {
    padding: 0 !important;
    transform: translateY(0px) scale(1) !important;
}
.fancy-selector-option {
    padding: 8px 15px !important;
}
.fancy-selector-option .fs-img {
    padding-left: 0 !important;
}
.fancy-selector-current {
    min-width: 317px;
    justify-content: flex-start !important;
}
.fs-main-info {
    width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fs-main-info .fs-sub strong {
    margin-left: 0 !important;
}
.top-bar .fancy-selector-w {
    margin-right: unset;
}
.fancy-selector-w .fancy-selector-options {
    z-index: 10;
}
</style>

<style>
#header .mx-input-icon__close {
    color: #f6db77;
}
#header .mx-input {
    height: 59px;
    width: 225px;
    border: 0;
    border-radius: 0;
    background: transparent;
    color: #fff;
}
#header .mx-input-icon__calendar {
    display: none !important;
}
#header ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}
#header:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}
#header::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}
#header:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}
#header::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}

#header::placeholder {
    /* Most modern browsers support this now. */
    font-size: 0.99rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
}
</style>
