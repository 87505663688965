import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import Login from '@/components/Login'
import Logout from '@/components/Logout'

import Dashboard from '@/components/Dashboard'
import DevisList from '@/components/Devis/List'
import DevisEdit from '@/components/Devis/Edit'
import DevisCreate from '@/components/Devis/Create'

import Test from '@/components/Test'

Vue.use(Router)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'devis-list',
    component: DevisList,
    meta: { requiresAuth: true }
  },
  {
    path: '/devis/create/:type',
    name: 'devis-create',
    component: DevisCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/devis/:id/edit/:type',
    name: 'devis-edit',
    component: DevisEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/devis/stats',
    name: 'devis-stats',
    component: Test,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/redirect',
    name: 'redirect'
  }
]

const router = new Router({
  // mode: 'history',
  routes
})

export default router
router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!store.state.isLoggedIn || localStorage.getItem('token') === null) {
      next({ name: 'login' })
    } else {
      next()
    }
    return
  }
  if (to.path === '/login' && store.state.isLoggedIn) {
    next({ name: 'dashboard' })
    return
  }
  next()
})
