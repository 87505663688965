<template>
  <div id="login">
    <div class="auth-box-w">
      <div class="logo-w" style="padding: 2%">
      </div>
      <h4 class="auth-header" style="text-transform: uppercase">Login</h4>
      <form v-on:submit.prevent="submitLogin">
        <div class="form-group">
          <label for="">Email</label>
          <input class="form-control" placeholder="Entrez votre email" name="email" type="email" required autofocus v-model="email">
          <div class="pre-icon os-icon os-icon-user-male-circle"></div>
        </div>
        <div class="form-group">
          <label for="">Password</label>
          <input class="form-control" placeholder="Entrez votre password" name="password" type="password" required v-model="password">
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
        </div>
        <div class="buttons-w">
          <button class="btn btn-primary" type="submit">Connexion</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Notification } from 'element-ui'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      loginError: false
    }
  },
  methods: {
    submitLogin () {
      this.loginError = false
      axios.post('/auth/login', {
        email: this.email,
        password: this.password
      }).then(response => {
        // store.commit('loginUser')
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('currentUser', JSON.stringify(response.data.user))
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        Notification({
          title: 'Bienvenue',
          message: response.data.user.name,
          type: 'success'
        })
        setTimeout(function () {
          window.location.reload()
        }, 500)
      }).catch(error => {
        Notification({
          title: 'Erreur',
          message: error.response.data,
          type: 'error'
        })
        this.loginError = true
      })
    }
  },
  mounted () {
    document.body.classList.add('auth-wrapper')
  }
}
</script>
