<template>
  <div id="devis-form">
    <section class="content-box">
      <Table/>
      <Aside/>
    </section>
  </div>
</template>

<script>
import Table from './_Table.vue'
import Aside from './_Aside.vue'

export default {
  name: 'devis-form',
  components: { Table, Aside },
  data () {
    return {
      type: this.$route.params.type,
      devis: {}
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  padding: 0;
  position: relative;
  height: 150vh;
  z-index: 2;
}
</style>
