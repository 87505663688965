<template>
    <div
        id="app"
        class="all-wrapper"
        :class="isLoggedIn ? 'solid-bg-all' : 'menu-side with-pattern'"
    >
        <div :class="isLoggedIn ? 'layout-w' : ''">
            <Menu v-if="isLoggedIn" :app-version="appVersion" />
            <div :class="isLoggedIn ? 'content-w' : ''">
                <Header v-if="isLoggedIn" />
                <div class="content-i">
                    <div id="loading-container" v-show="isLoading">
                        <vue-simple-spinner
                            :size="65"
                            :line-size="4"
                            line-fg-color="#0073ff"
                            line-bg-color="#DDD"
                            text-fg-color="#fff"
                            message="Chargement..."
                        ></vue-simple-spinner>
                    </div>
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
        </div>
        <!-- Dialog email -->
        <el-dialog
            :title="'Nouveautés de la version ' + appVersion"
            :visible.sync="dialogAppVersionVisible"
            :append-to-body="true"
            :close-on-click-modal="false"
            id="modal-app-version"
            width="40%"
        >
            <div>
                <ul>
                    <li>Correction du bug du choix de la lame</li>
                    <li>Correction de la largeur d'ajout d'un produit</li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <button
                    type="reset"
                    @click="dialogAppVersionVisible = false"
                    class="btn btn-sm btn-outline-primary"
                >
                    Fermer
                </button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Menu from "@/components/Menu";
import Header from "@/components/Header";
import VueSimpleSpinner from "vue-simple-spinner";
import { EventBus } from "@/event-bus.js";
import VueLocalStorage from "vue-ls";

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);

export default {
    name: "App",
    components: {
        Menu,
        Header,
        VueSimpleSpinner,
    },
    data() {
        return {
            isLoading: false,
            appVersion: "2.4.1",
            dialogAppVersionVisible: false,
        };
    },
    computed: {
        isLoggedIn() {
            return store.state.isLoggedIn;
        },
    },
    methods: {},
    updated() {
        if (!this.isLoggedIn) {
            document.body.classList = "auth-wrapper";
        }
    },
    created() {
        EventBus.$on("toggle-loading", (value) => {
            this.isLoading = value;
        });
    },
};
</script>

<style>
body {
    padding: 0;
}
#app {
    max-width: 100%;
    margin: 0;
}
.menu-side-compact-w {
    border-radius: 0;
}
.menu-side .content-w {
    border-radius: 0;
}
.breadcrumb {
    border-radius: 0;
}
.main-container {
    min-height: 125vh;
    position: relative;
}
#loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1000;
    > div {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -20px -50px;
    }
    .vue-simple-spinner-text {
        text-transform: uppercase;
        font-weight: 100;
    }
}
.el-date-editor {
    border: 0 !important;
    background: transparent !important;
}
</style>
