<template>
    <div class="dashboard main-container">
        <ul class="breadcrumb">
            <li class="breadcrumb-item"><span>Home</span></li>
        </ul>
        <div class="content-i">
            <div class="content-box">
                <!-- Stats -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="element-wrapper">
                            <h6 class="element-header">Statistiques</h6>
                            <div class="element-content">
                                <div class="row">
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div
                                                class="stat-body"
                                                v-tooltip="
                                                    '<div>' +
                                                    stats.devis.total.count +
                                                    '/' +
                                                    stats.devis.total
                                                        .count_team +
                                                    ' devis attribués</div><div>Répartition: ' +
                                                    stats.devis.total
                                                        .distribution +
                                                    '%</div>'
                                                "
                                            >
                                                <div class="label">
                                                    Nb de devis attribués
                                                </div>
                                                <div class="value">
                                                    {{
                                                        formatNumber(
                                                            stats.devis.total
                                                                .count
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        currentUser.function ===
                                                            'Admin' ||
                                                        currentUser.function ===
                                                            'Manager'
                                                    "
                                                    class="trending"
                                                    :class="
                                                        stats.devis.total
                                                            .user_trend > 0
                                                            ? 'trending-up-basic'
                                                            : 'trending-down-basic'
                                                    "
                                                >
                                                    <span
                                                        >{{
                                                            stats.devis.total
                                                                .user_trend
                                                        }}%</span
                                                    >
                                                    <i
                                                        class="os-icon"
                                                        :class="
                                                            stats.devis.total
                                                                .user_trend > 0
                                                                ? 'os-icon-arrow-up2'
                                                                : 'os-icon os-icon-arrow-down'
                                                        "
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                <span
                                                    style="
                                                        border-bottom: dashed
                                                            1px #0273ff;
                                                    "
                                                    >{{
                                                        formatNumber(
                                                            stats.devis.total
                                                                .sum
                                                        )
                                                    }}
                                                    €</span
                                                >
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div
                                                class="stat-body"
                                                v-tooltip="
                                                    '<div>' +
                                                    stats.devis.inProgress
                                                        .count +
                                                    '/' +
                                                    stats.devis.inProgress
                                                        .count_team +
                                                    ' devis en cours</div><div>Répartition: ' +
                                                    stats.devis.inProgress
                                                        .distribution +
                                                    '%</div>'
                                                "
                                            >
                                                <div class="label">
                                                    Nb de devis en cours
                                                </div>
                                                <div class="value">
                                                    {{
                                                        formatNumber(
                                                            stats.devis
                                                                .inProgress
                                                                .count
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        currentUser.function ===
                                                            'Admin' ||
                                                        currentUser.function ===
                                                            'Manager'
                                                    "
                                                    class="trending"
                                                    :class="
                                                        stats.devis.inProgress
                                                            .user_trend > 0
                                                            ? 'trending-up-basic'
                                                            : 'trending-down-basic'
                                                    "
                                                >
                                                    <span
                                                        >{{
                                                            stats.devis
                                                                .inProgress
                                                                .user_trend
                                                        }}%</span
                                                    >
                                                    <i
                                                        class="os-icon"
                                                        :class="
                                                            stats.devis
                                                                .inProgress
                                                                .user_trend > 0
                                                                ? 'os-icon-arrow-up2'
                                                                : 'os-icon os-icon-arrow-down'
                                                        "
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                <span
                                                    style="
                                                        border-bottom: dashed
                                                            1px #0273ff;
                                                    "
                                                    >{{
                                                        formatNumber(
                                                            stats.devis
                                                                .inProgress.sum
                                                        )
                                                    }}
                                                    €</span
                                                >
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div
                                                class="stat-body"
                                                v-tooltip="
                                                    '<div>' +
                                                    stats.devis.quoted.count +
                                                    '/' +
                                                    stats.devis.quoted
                                                        .count_team +
                                                    ' devisés </div><div>Répartition: ' +
                                                    stats.devis.quoted
                                                        .distribution +
                                                    '%</div>'
                                                "
                                            >
                                                <div class="label">
                                                    Nb de devis devisés
                                                </div>
                                                <div class="value">
                                                    {{
                                                        formatNumber(
                                                            stats.devis.quoted
                                                                .count
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        currentUser.function ===
                                                            'Admin' ||
                                                        currentUser.function ===
                                                            'Manager'
                                                    "
                                                    class="trending"
                                                    :class="
                                                        stats.devis.quoted
                                                            .user_trend > 0
                                                            ? 'trending-up-basic'
                                                            : 'trending-down-basic'
                                                    "
                                                >
                                                    <span
                                                        >{{
                                                            stats.devis.quoted
                                                                .user_trend
                                                        }}%</span
                                                    >
                                                    <i
                                                        class="os-icon"
                                                        :class="
                                                            stats.devis.quoted
                                                                .user_trend > 0
                                                                ? 'os-icon-arrow-up2'
                                                                : 'os-icon os-icon-arrow-down'
                                                        "
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                <span
                                                    style="
                                                        border-bottom: dashed
                                                            1px #0273ff;
                                                    "
                                                    >{{
                                                        formatNumber(
                                                            stats.devis.quoted
                                                                .sum
                                                        )
                                                    }}
                                                    €</span
                                                >
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div class="stat-body">
                                                <div class="label">
                                                    Tx de transformation global
                                                </div>
                                                <div class="value">
                                                    {{
                                                        stats.devis
                                                            .txTransfoGlobal
                                                            .user_value
                                                    }}
                                                    %
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                &nbsp;
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div
                                                class="stat-body"
                                                v-tooltip="
                                                    '<div>' +
                                                    stats.devis.validated
                                                        .count +
                                                    '/' +
                                                    stats.devis.validated
                                                        .count_team +
                                                    ' validés </div><div>Répartition: ' +
                                                    stats.devis.validated
                                                        .distribution +
                                                    '%</div>'
                                                "
                                            >
                                                <div class="label">
                                                    Nb de devis validés
                                                </div>
                                                <div class="value">
                                                    {{
                                                        formatNumber(
                                                            stats.devis
                                                                .validated.count
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                <span
                                                    style="
                                                        border-bottom: dashed
                                                            1px #0273ff;
                                                    "
                                                    >{{
                                                        formatNumber(
                                                            stats.devis
                                                                .validated.sum
                                                        )
                                                    }}
                                                    €</span
                                                >
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-sm-3 col-xxl-4">
                                        <a
                                            href="#"
                                            class="element-box el-tablo centered trend-in-corner smaller"
                                        >
                                            <div class="stat-body">
                                                <div class="label">
                                                    Tx de transformation réel
                                                </div>
                                                <div class="value">
                                                    {{
                                                        stats.devis
                                                            .txTransfoReal
                                                            .user_value
                                                    }}
                                                    %
                                                </div>
                                            </div>
                                            <div class="stat-footer">
                                                &nbsp;
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="element-wrapper">
                            <h6 class="element-header">
                                Répartition des devis par statut
                            </h6>
                            <div class="element-box" style="height: 278px">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="chart-container">
                                            <canvas
                                                id="status-pie"
                                                height="240"
                                            ></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="element-wrapper">
                            <div class="element-content">
                                <div class="row">
                                    <div class="col-sm-4 col-xxxl-4">
                                        <div class="element-wrapper">
                                            <h6 class="element-header">
                                                Nouveau devis
                                                <span
                                                    class="badge badge-pill badge-dark"
                                                    >{{
                                                        data.devis.newDevis
                                                            .length
                                                    }}</span
                                                >
                                            </h6>
                                            <div
                                                class="element-box element-box-table"
                                            >
                                                <div class="table-responsive">
                                                    <table
                                                        class="table table-lightborder table-hovered table-stripped table-recap"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th width="80">
                                                                    Type
                                                                </th>
                                                                <th>Client</th>
                                                                <th>HT</th>
                                                                <th>Statut</th>
                                                                <th width="60">
                                                                    User
                                                                </th>
                                                                <th width="80">
                                                                    Création
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                data.devis
                                                                    .newDevis
                                                                    .length > 0
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(
                                                                    v, k
                                                                ) in data.devis
                                                                    .newDevis"
                                                                v-bind:key="k"
                                                                @click="
                                                                    goToDevis(
                                                                        v.type,
                                                                        v.id
                                                                    )
                                                                "
                                                                v-tooltip="{
                                                                    content:
                                                                        v.id,
                                                                    placement:
                                                                        'left',
                                                                }"
                                                            >
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-type"
                                                                        :class="
                                                                            'badge-' +
                                                                            v.type
                                                                        "
                                                                        >{{
                                                                            v.type.replace(
                                                                                "_",
                                                                                " "
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.customer
                                                                        "
                                                                        class="text-primary"
                                                                    >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .first_name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .last_name
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <strong
                                                                        class="price"
                                                                        v-if="
                                                                            v.quoted_price
                                                                        "
                                                                        >{{
                                                                            v.quoted_price
                                                                        }}
                                                                        €</strong
                                                                    >
                                                                    <strong
                                                                        class="price"
                                                                        v-else
                                                                        >-</strong
                                                                    >
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="
                                                                            v.status
                                                                        "
                                                                        class="badge badge-status"
                                                                        :class="
                                                                            v
                                                                                .status
                                                                                .name
                                                                        "
                                                                        v-tooltip="
                                                                            v.failed_status
                                                                                ? v
                                                                                      .failed_status
                                                                                      .title
                                                                                : ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            v
                                                                                .status
                                                                                .title
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="relative"
                                                                    style="
                                                                        text-align: initial;
                                                                    "
                                                                >
                                                                    <div
                                                                        class="absolute-badge"
                                                                    >
                                                                        <div
                                                                            v-if="
                                                                                v.user
                                                                            "
                                                                            class="badge-user"
                                                                            :class="
                                                                                'badge-' +
                                                                                formatUserName(
                                                                                    v
                                                                                        .user
                                                                                        .email
                                                                                )
                                                                            "
                                                                        >
                                                                            <span
                                                                                v-tooltip="
                                                                                    v
                                                                                        .user
                                                                                        .name
                                                                                "
                                                                                >{{
                                                                                    formatUserName(
                                                                                        v
                                                                                            .user
                                                                                            .email
                                                                                    )
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.created_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.created_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    align="center"
                                                                >
                                                                    Aucuns
                                                                    résultats
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xxxl-4">
                                        <div class="element-wrapper">
                                            <h6 class="element-header">
                                                A faire
                                                <span
                                                    class="text-muted"
                                                    style="font-size: 11px"
                                                    >( brouillon, à faire
                                                    )</span
                                                >
                                                <span
                                                    class="badge badge-pill badge-dark"
                                                    >{{
                                                        data.devis.toDoDevis
                                                            .length
                                                    }}</span
                                                >
                                            </h6>
                                            <div
                                                class="element-box element-box-table"
                                            >
                                                <div class="table-responsive">
                                                    <table
                                                        class="table table-lightborder table-hovered table-stripped table-recap"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th width="80">
                                                                    Type
                                                                </th>
                                                                <th>Client</th>
                                                                <th>HT</th>
                                                                <th>Statut</th>
                                                                <th width="60">
                                                                    User
                                                                </th>
                                                                <th width="80">
                                                                    Création
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                data.devis
                                                                    .toDoDevis
                                                                    .length > 0
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(
                                                                    v, k
                                                                ) in data.devis
                                                                    .toDoDevis"
                                                                v-bind:key="k"
                                                                @click="
                                                                    goToDevis(
                                                                        v.type,
                                                                        v.id
                                                                    )
                                                                "
                                                                v-tooltip="{
                                                                    content:
                                                                        v.id,
                                                                    placement:
                                                                        'right',
                                                                }"
                                                            >
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-type"
                                                                        :class="
                                                                            'badge-' +
                                                                            v.type
                                                                        "
                                                                        >{{
                                                                            v.type.replace(
                                                                                "_",
                                                                                " "
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.customer
                                                                        "
                                                                        class="text-primary"
                                                                    >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .first_name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .last_name
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <strong
                                                                        class="price"
                                                                        v-if="
                                                                            v.total_ht
                                                                        "
                                                                        >{{
                                                                            v.total_ht
                                                                        }}
                                                                        €</strong
                                                                    >
                                                                    <strong
                                                                        class="price"
                                                                        v-else
                                                                        >-</strong
                                                                    >
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="
                                                                            v.status
                                                                        "
                                                                        class="badge badge-status"
                                                                        :class="
                                                                            v
                                                                                .status
                                                                                .name
                                                                        "
                                                                        v-tooltip="
                                                                            v.failed_status
                                                                                ? v
                                                                                      .failed_status
                                                                                      .title
                                                                                : ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            v
                                                                                .status
                                                                                .title
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="relative"
                                                                    style="
                                                                        text-align: initial;
                                                                    "
                                                                >
                                                                    <div
                                                                        class="absolute-badge"
                                                                    >
                                                                        <div
                                                                            v-if="
                                                                                v.user
                                                                            "
                                                                            class="badge-user"
                                                                            :class="
                                                                                'badge-' +
                                                                                formatUserName(
                                                                                    v
                                                                                        .user
                                                                                        .email
                                                                                )
                                                                            "
                                                                        >
                                                                            <span
                                                                                v-tooltip="
                                                                                    v
                                                                                        .user
                                                                                        .name
                                                                                "
                                                                                >{{
                                                                                    formatUserName(
                                                                                        v
                                                                                            .user
                                                                                            .email
                                                                                    )
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.created_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.created_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    align="center"
                                                                >
                                                                    Aucuns
                                                                    résultats
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xxxl-4">
                                        <div class="element-wrapper">
                                            <h6 class="element-header">
                                                Validés
                                                <span
                                                    class="badge badge-pill badge-dark"
                                                    >{{
                                                        data.devis.validated
                                                            .length
                                                    }}</span
                                                >
                                            </h6>
                                            <div
                                                class="element-box element-box-table"
                                            >
                                                <div class="table-responsive">
                                                    <table
                                                        class="table table-lightborder table-hovered table-stripped table-recap"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th width="80">
                                                                    Type
                                                                </th>
                                                                <th>Client</th>
                                                                <th>HT</th>
                                                                <th>Statut</th>
                                                                <th width="60">
                                                                    User
                                                                </th>
                                                                <th width="80">
                                                                    Création
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                data.devis
                                                                    .validated
                                                                    .length > 0
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(
                                                                    v, k
                                                                ) in data.devis
                                                                    .validated"
                                                                v-bind:key="k"
                                                                @click="
                                                                    goToDevis(
                                                                        v.type,
                                                                        v.id
                                                                    )
                                                                "
                                                                v-tooltip="{
                                                                    content:
                                                                        v.id,
                                                                    placement:
                                                                        'right',
                                                                }"
                                                            >
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-type"
                                                                        :class="
                                                                            'badge-' +
                                                                            v.type
                                                                        "
                                                                        >{{
                                                                            v.type.replace(
                                                                                "_",
                                                                                " "
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.customer
                                                                        "
                                                                        class="text-primary"
                                                                    >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .first_name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .last_name
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <strong
                                                                        class="price"
                                                                        v-if="
                                                                            v.total_ht
                                                                        "
                                                                        >{{
                                                                            v.total_ht
                                                                        }}
                                                                        €</strong
                                                                    >
                                                                    <strong
                                                                        class="price"
                                                                        v-else
                                                                        >-</strong
                                                                    >
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="
                                                                            v.status
                                                                        "
                                                                        class="badge badge-status"
                                                                        :class="
                                                                            v
                                                                                .status
                                                                                .name
                                                                        "
                                                                        v-tooltip="
                                                                            v.failed_status
                                                                                ? v
                                                                                      .failed_status
                                                                                      .title
                                                                                : ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            v
                                                                                .status
                                                                                .title
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="relative"
                                                                    style="
                                                                        text-align: initial;
                                                                    "
                                                                >
                                                                    <div
                                                                        class="absolute-badge"
                                                                    >
                                                                        <div
                                                                            v-if="
                                                                                v.user
                                                                            "
                                                                            class="badge-user"
                                                                            :class="
                                                                                'badge-' +
                                                                                formatUserName(
                                                                                    v
                                                                                        .user
                                                                                        .email
                                                                                )
                                                                            "
                                                                        >
                                                                            <span
                                                                                v-tooltip="
                                                                                    v
                                                                                        .user
                                                                                        .name
                                                                                "
                                                                                >{{
                                                                                    formatUserName(
                                                                                        v
                                                                                            .user
                                                                                            .email
                                                                                    )
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.created_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.created_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    align="center"
                                                                >
                                                                    Aucuns
                                                                    résultats
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xxxl-4">
                                        <div class="element-wrapper">
                                            <h6 class="element-header">
                                                Report projet
                                                <span
                                                    class="badge badge-pill badge-dark"
                                                    >{{
                                                        data.devis.deferred
                                                            .length
                                                    }}</span
                                                >
                                            </h6>
                                            <div
                                                class="element-box element-box-table"
                                            >
                                                <div class="table-responsive">
                                                    <table
                                                        class="table table-lightborder table-hovered table-stripped table-recap"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th width="80">
                                                                    Type
                                                                </th>
                                                                <th>Client</th>
                                                                <th>HT</th>
                                                                <th>Statut</th>
                                                                <th width="60">
                                                                    User
                                                                </th>
                                                                <th width="80">
                                                                    Création
                                                                </th>
                                                                <th width="80">
                                                                    Rappel
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                data.devis
                                                                    .deferred
                                                                    .length > 0
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(
                                                                    v, k
                                                                ) in data.devis
                                                                    .deferred"
                                                                v-bind:key="k"
                                                                @click="
                                                                    goToDevis(
                                                                        v.type,
                                                                        v.id
                                                                    )
                                                                "
                                                                v-tooltip="{
                                                                    content:
                                                                        v.id,
                                                                    placement:
                                                                        'right',
                                                                }"
                                                            >
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-type"
                                                                        :class="
                                                                            'badge-' +
                                                                            v.type
                                                                        "
                                                                        >{{
                                                                            v.type.replace(
                                                                                "_",
                                                                                " "
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.customer
                                                                        "
                                                                        class="text-primary"
                                                                    >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .first_name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .last_name
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <strong
                                                                        class="price"
                                                                        v-if="
                                                                            v.total_ht
                                                                        "
                                                                        >{{
                                                                            v.total_ht
                                                                        }}
                                                                        €</strong
                                                                    >
                                                                    <strong
                                                                        class="price"
                                                                        v-else
                                                                        >-</strong
                                                                    >
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="
                                                                            v.status
                                                                        "
                                                                        class="badge badge-status"
                                                                        :class="
                                                                            v
                                                                                .status
                                                                                .name
                                                                        "
                                                                        v-tooltip="
                                                                            v.failed_status
                                                                                ? v
                                                                                      .failed_status
                                                                                      .title
                                                                                : ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            v
                                                                                .status
                                                                                .title
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="relative"
                                                                    style="
                                                                        text-align: initial;
                                                                    "
                                                                >
                                                                    <div
                                                                        class="absolute-badge"
                                                                    >
                                                                        <div
                                                                            v-if="
                                                                                v.user
                                                                            "
                                                                            class="badge-user"
                                                                            :class="
                                                                                'badge-' +
                                                                                formatUserName(
                                                                                    v
                                                                                        .user
                                                                                        .email
                                                                                )
                                                                            "
                                                                        >
                                                                            <span
                                                                                v-tooltip="
                                                                                    v
                                                                                        .user
                                                                                        .name
                                                                                "
                                                                                >{{
                                                                                    formatUserName(
                                                                                        v
                                                                                            .user
                                                                                            .email
                                                                                    )
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.created_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.created_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.reminder_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.reminder_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    align="center"
                                                                >
                                                                    Aucuns
                                                                    résultats
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xxxl-4">
                                        <div class="element-wrapper">
                                            <h6 class="element-header">
                                                Devisés
                                                <span
                                                    class="badge badge-pill badge-dark"
                                                    >{{
                                                        data.devis.quoted.length
                                                    }}</span
                                                >
                                            </h6>
                                            <div
                                                class="element-box element-box-table"
                                            >
                                                <div class="table-responsive">
                                                    <table
                                                        class="table table-lightborder table-hovered table-stripped table-recap"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th width="80">
                                                                    Type
                                                                </th>
                                                                <th>Client</th>
                                                                <th>HT</th>
                                                                <th>Statut</th>
                                                                <th width="60">
                                                                    User
                                                                </th>
                                                                <th width="80">
                                                                    Création
                                                                </th>
                                                                <th width="80">
                                                                    Rappel
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                data.devis
                                                                    .quoted
                                                                    .length > 0
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(
                                                                    v, k
                                                                ) in data.devis
                                                                    .quoted"
                                                                v-bind:key="k"
                                                                @click="
                                                                    goToDevis(
                                                                        v.type,
                                                                        v.id
                                                                    )
                                                                "
                                                                v-tooltip="{
                                                                    content:
                                                                        v.id,
                                                                    placement:
                                                                        'right',
                                                                }"
                                                            >
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="badge badge-pill badge-type"
                                                                        :class="
                                                                            'badge-' +
                                                                            v.type
                                                                        "
                                                                        >{{
                                                                            v.type.replace(
                                                                                "_",
                                                                                " "
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.customer
                                                                        "
                                                                        class="text-primary"
                                                                    >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .first_name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="capitalize"
                                                                            >{{
                                                                                v
                                                                                    .customer
                                                                                    .last_name
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <strong
                                                                        class="price"
                                                                        v-if="
                                                                            v.total_ht
                                                                        "
                                                                        >{{
                                                                            v.total_ht
                                                                        }}
                                                                        €</strong
                                                                    >
                                                                    <strong
                                                                        class="price"
                                                                        v-else
                                                                        >-</strong
                                                                    >
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        v-if="
                                                                            v.status
                                                                        "
                                                                        class="badge badge-status"
                                                                        :class="
                                                                            v
                                                                                .status
                                                                                .name
                                                                        "
                                                                        v-tooltip="
                                                                            v.failed_status
                                                                                ? v
                                                                                      .failed_status
                                                                                      .title
                                                                                : ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            v
                                                                                .status
                                                                                .title
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="relative"
                                                                    style="
                                                                        text-align: initial;
                                                                    "
                                                                >
                                                                    <div
                                                                        class="absolute-badge"
                                                                    >
                                                                        <div
                                                                            v-if="
                                                                                v.user
                                                                            "
                                                                            class="badge-user"
                                                                            :class="
                                                                                'badge-' +
                                                                                formatUserName(
                                                                                    v
                                                                                        .user
                                                                                        .email
                                                                                )
                                                                            "
                                                                        >
                                                                            <span
                                                                                v-tooltip="
                                                                                    v
                                                                                        .user
                                                                                        .name
                                                                                "
                                                                                >{{
                                                                                    formatUserName(
                                                                                        v
                                                                                            .user
                                                                                            .email
                                                                                    )
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.created_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.created_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="smaller"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            v.reminder_at
                                                                        "
                                                                        class="time"
                                                                        v-tooltip="
                                                                            formatDate(
                                                                                v.reminder_at,
                                                                                'time'
                                                                            )
                                                                        "
                                                                    >
                                                                        <strong
                                                                            >{{
                                                                                formatDate(
                                                                                    v.reminder_at,
                                                                                    "date"
                                                                                )
                                                                            }}</strong
                                                                        >
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    align="center"
                                                                >
                                                                    Aucuns
                                                                    résultats
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import store from "../store";
import { EventBus } from "@/event-bus.js";
import VTooltip from "v-tooltip";
import * as moment from "moment";
import { Notification } from "element-ui";
import Chart from "chart.js";
import VueLocalStorage from "vue-ls";

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);

Vue.use(VTooltip);

export default {
    name: "Dashboard",
    data() {
        return {
            ctx: null,
            myPieChart: null,
            stats: {
                devis: {
                    total: 0,
                    inProgress: 0,
                    quoted: 0,
                    validated: 0,
                    txTransfoGlobal: 0,
                    txTransfoReal: 0,
                },
            },
            data: {
                devis: {
                    newDevis: [],
                    toDoDevis: [],
                    deferred: [],
                    quoted: [],
                    validated: [],
                },
                charts: {
                    status: [],
                },
            },
            periods: {
                day: "Aujourd'hui",
                week: "Cette semaine",
                month: "Ce mois",
                year: "Cette année",
                all: "Total",
                custom: "Personnalisée",
            },
            currentUser: JSON.parse(store.state.currentUser),
            selectedUser:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedUser
                    : JSON.parse(store.state.currentUser),
            selectedProducts:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedProducts
                    : JSON.parse(store.state.currentUser).products.split("|"),
            selectedPeriod:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").selectedPeriod
                    : "day",
            customPeriod:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").customPeriod
                    : null,
            deletedAt:
                Vue.ls.get("dashboard") !== null
                    ? Vue.ls.get("dashboard").deletedAt
                    : false,
        };
    },
    methods: {
        fetchTeam() {
            axios
                .get("v2/users/" + this.currentUser.id + "/team")
                .then(function (response) {
                    EventBus.$emit("user-team", response.data);
                });
        },

        fetchData() {
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .all([
                    this.fetchUserTotalDevis(),
                    this.fetchUserInProgressDevis(),
                    this.fetchUserQuotedDevis(),
                    this.fetchUserValidatedDevis(),
                    this.fetchUsertxTransfoGlobalDevis(),
                    this.fetchUsertxTransfoRealDevis(),
                    this.fetctUserActivityDevis(),
                    this.fetchNewDevis(),
                    this.fetchToDoDevis(),
                    this.fecthDeferredDevis(),
                    this.fecthQuoteDevis(),
                    this.fecthValidatedevis(),
                    this.fetchUserDevisStatus(),
                ])
                .then(
                    axios.spread(function (
                        userTotalDevis,
                        userInProgressDevis,
                        userQuotedDevis,
                        userValidatedDevis,
                        usertxTransfoGlobal,
                        usertxTransfoReal,
                        userActivity,
                        newDevis,
                        toDoDevis,
                        deferredDevis,
                        quotedDevis,
                        validatedDevis
                    ) {
                        vm.stats.devis.total = userTotalDevis.data;
                        vm.stats.devis.inProgress = userInProgressDevis.data;
                        vm.stats.devis.quoted = userQuotedDevis.data;
                        vm.stats.devis.validated = userValidatedDevis.data;
                        vm.stats.devis.txTransfoGlobal =
                            usertxTransfoGlobal.data;
                        vm.stats.devis.txTransfoReal = usertxTransfoReal.data;
                        vm.stats.devis.activity = userActivity.data;
                        vm.data.devis.newDevis = newDevis.data;
                        vm.data.devis.toDoDevis = toDoDevis.data;
                        vm.data.devis.deferred = deferredDevis.data;
                        vm.data.devis.quoted = quotedDevis.data;
                        vm.data.devis.validated = validatedDevis.data;
                        EventBus.$emit("toggle-loading", false);
                    })
                );
        },

        fetchUserTotalDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/total/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchUserInProgressDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/in_progress/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchUserQuotedDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/quoted/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchUserValidatedDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/validated/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchUsertxTransfoGlobalDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/transfo_globale/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchUsertxTransfoRealDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/transfo_real/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetctUserActivityDevis() {
            return axios.get(
                "/v2/devis/stats/" +
                    this.selectedUser.id +
                    "/activity/" +
                    this.selectedPeriod +
                    "?products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchNewDevis() {
            return axios.get(
                "/v2/devis/" +
                    this.selectedUser.id +
                    "/by_status/" +
                    this.selectedPeriod +
                    "?status[]=2&products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fetchToDoDevis() {
            return axios.get(
                "/v2/devis/" +
                    this.selectedUser.id +
                    "/by_status/" +
                    this.selectedPeriod +
                    "?status[]=1&status[]=6&products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        fecthDeferredDevis() {
            return axios.get(
                "/v2/devis/" +
                    this.selectedUser.id +
                    "/by_status/" +
                    this.selectedPeriod +
                    "?status[]=7&products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt +
                    "&orderBy=reminder_at&order=asc"
            );
        },

        fecthQuoteDevis() {
            return axios.get(
                "/v2/devis/" +
                    this.selectedUser.id +
                    "/by_status/" +
                    this.selectedPeriod +
                    "?status[]=8&products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt +
                    "&orderBy=reminder_at&order=asc"
            );
        },

        fecthValidatedevis() {
            return axios.get(
                "/v2/devis/" +
                    this.selectedUser.id +
                    "/by_status/" +
                    this.selectedPeriod +
                    "?status[]=12&products=" +
                    this.selectedProducts +
                    "&custom_period=" +
                    this.customPeriod +
                    "&deleted_at=" +
                    this.deletedAt
            );
        },

        goToDevis(type, id) {
            this.$router.push({
                name: "devis-edit",
                params: { type: type, id: id },
            });
        },

        deleteDevis(table, id, index) {
            if (!confirm("Etes vous sur de vouloir supprimer ce devis ?")) {
                return false;
            }
            let vm = this;
            EventBus.$emit("toggle-loading", true);
            axios
                .delete("/v2/devis/" + id)
                .then(function () {
                    vm.data.devis[table].splice(index, 1);
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Succès",
                        message: "Devis supprimé",
                        type: "success",
                    });
                })
                .catch(function (error) {
                    EventBus.$emit("toggle-loading", false);
                    Notification({
                        title: "Erreur",
                        message: error.message,
                        type: "error",
                    });
                });
        },

        formatUserName(e) {
            return e.substr(0, 2);
        },

        formatNumber(num) {
            if (num >= 1000000) {
                return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
            }
            if (num >= 1000) {
                return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
            }
            return num;
        },

        formatDate(d, type) {
            if (type === "date") {
                return moment(d).format("DD/MM/YYYY");
            } else {
                return moment(d).format("hh:mm");
            }
        },

        fetchUserDevisStatus() {
            axios
                .get(
                    "/v2/devis/stats/" +
                        this.selectedUser.id +
                        "/status/" +
                        this.selectedPeriod +
                        "?products=" +
                        this.selectedProducts +
                        "&custom_period=" +
                        this.customPeriod
                )
                .then((response) => {
                    var data = {
                        labels: response.data.labels,
                        datasets: [
                            {
                                data: response.data.data,
                                backgroundColor: response.data.colors,
                            },
                        ],
                    };
                    var options = {
                        responsive: true,
                        maintainAspectRatio: false,
                    };
                    if (this.myPieChart !== null) {
                        this.myPieChart.data = data;
                        this.myPieChart.update();
                    } else {
                        // eslint-disable-next-line
                        this.myPieChart = new Chart(this.ctx, {
                            type: "pie",
                            data: data,
                            options: options,
                        });
                    }
                });
        },
    },
    mounted() {
        this.selectedProducts = this.selectedProducts.join("|");
        this.customPeriod =
            this.customPeriod !== "" && this.customPeriod !== null
                ? this.customPeriod.join("|")
                : null;
        this.fetchTeam();
        this.fetchData();
        this.ctx = document.getElementById("status-pie").getContext("2d");
    },
    updated() {
        EventBus.$once("update-dashboard", (data) => {
            this.selectedUser = data.selectedUser;
            this.selectedProducts = data.selectedProducts.join("|");
            this.selectedPeriod = data.selectedPeriod;
            this.customPeriod =
                this.customPeriod !== "" && this.customPeriod !== null
                    ? moment(data.customPeriod[0]).format("Y-MM-DD") +
                      "|" +
                      moment(data.customPeriod[1]).format("Y-MM-DD")
                    : null;
            this.deletedAt = data.deletedAt;
            console.log("[RECEIVED] DELETED_AT = " + this.deletedAt);
            this.fetchData();
        });
    },
};
</script>

<style lang="scss" scoped>
.element-box-table {
    padding: 0;
    height: 453px;
    overflow: scroll;
}

.table-recap {
    th,
    td {
        text-align: center;
    }
    td.smaller {
        font-size: 85%;
    }
}
.el-tablo.trend-in-corner {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0;
    height: 131px;

    > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .stat-footer {
        border-top: solid 1px #f2f2f2;
        padding: 0.5rem 0;
        margin-top: 0.5rem;
    }
}

.badge-type {
    font-size: 70%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    color: #fff;
    &.badge-pergola {
        background-color: #ff5500;
    }
    &.badge-garde-corps {
        background-color: #4d8cd1;
    }
    &.badge-palissade {
        background-color: #2e3a3c;
    }
    &.badge-terrasse {
        background-color: #d3823d;
    }
}

.capitalize {
    text-transform: capitalize;
}

.badge-status {
    font-size: 70%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    font-weight: 400;
    &.draft {
        background-color: #bbbbbb;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    &.new {
        background-color: #2196f3;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    &.pre-quoted {
        background: #c5fbc7;
        color: #333;
    }
    &.auto-quoted {
        background-color: #8dff91;
        color: #333;
    }
    &.quoted {
        background-color: #2bda31;
        color: #333;
    }
    &.pending {
        background-color: #ff9800;
    }
    &.raise-1 {
        background-color: #e3ff8d;
        color: #333;
    }
    &.raise-2 {
        background-color: #ffbc7b;
        color: #333;
    }
    &.error,
    &.quoted-fail,
    &.fail {
        background-color: #7b1224;
    }
    &.duplicated {
        background-color: #bbb;
        color: #333;
    }
    &.contacted {
        background-color: #9c27b0;
    }
    &.in-queue {
        background: #a94442;
        color: #fff;
    }
    &.succeeded {
        background: #199319;
        color: #fff;
    }
    &.deferred {
        background-color: #acaae6;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
}
.badge-user {
    position: relative;
    width: 25px;
    height: 14.43px;
    margin: 7.22px 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background-color: #3f5369;
    &:before {
        bottom: 100%;
        border-bottom: 7.22px solid #3f5369;
    }
    &:after {
        top: 100%;
        width: 0;
        border-top: 7.22px solid #3f5369;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 12.5px solid transparent;
        border-right: 12.5px solid transparent;
    }
    &.badge-cf {
        background-color: #f7749b;
        &:before {
            border-bottom-color: #f7749b;
        }
        &:after {
            border-top-color: #f7749b;
        }
    }
    &.badge-gd {
        background-color: #26af3a;
        &:before {
            border-bottom-color: #26af3a;
        }
        &:after {
            border-top-color: #26af3a;
        }
    }
    &.badge-tb {
        background-color: #c4add2;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        span {
            color: gold;
        }
        &:before {
            border-bottom-color: #c4add2;
        }
        &:after {
            border-top-color: #c4add2;
        }
    }
    span {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 25px;
        height: 14.4338px;
        z-index: 2;
        background: inherit;
        font-weight: 500;
        font-size: 80%;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        line-height: 15px;
    }
}

.absolute-badge {
    left: 50%;
    position: absolute;
    margin: -14px -12.5px;
}
</style>
<style lang="scss">
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
.el-tablo.trend-in-corner .trending {
    top: 50%;
    right: 10px;
    margin-top: -15px;
}
</style>
