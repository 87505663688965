// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import store from './store'
import Jquery from 'jquery'
import 'bootstrap'
import {
  Collapse,
  CollapseItem,
  Switch,
  Dialog,
  DatePicker,
  Popover
}
from 'element-ui'
import lang from 'element-ui/lib/locale/lang/fr'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css';
import FlagIcon from 'vue-flag-icon'
import 'vue-multiselect/dist/vue-multiselect.min.css'
  
global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

//require('font-awesome/css/font-awesome.css')

Vue.config.productionTip = false

// Element ui default lang
locale.use(lang)

// Flag icons
Vue.use(FlagIcon)

// Element ui components
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Popover)

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept': 'application/json',
  'Authorization': 'Bearer ' + localStorage.getItem('token')
}

axios.defaults.baseURL = window.location.hostname === 'app-mydeckplanner.forestia.dev' ? 'https://api-mydeckplanner.forestia.dev/' : 'https://api.mydeckplanner.com/'

axios.interceptors.request.use(function(config) {
  // Do something before request is sent
  return config
}, function(error) {
  // Do something with request error
  return Promise.reject(error)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})
