<template>
    <div class="autocomplete" :class="mainClass">
        <input
            type="text"
            v-model="search"
            @focus="onFocus"
            @input="onChange"
            @keyup.down="onArrowDown"
            @keyup.up="onArrowUp"
            @keyup.enter="onEnter"
            :placeholder="placeholder"
            :class="inputClass"
            :disabled="disabled"
        />
        <i
            @click="resetFilter()"
            v-show="search && isOpen && isFiltered && !isLoading"
            class="ion-close text-danger"
        >
        </i>
        <div class="loading-container">
            <vue-simple-spinner
                v-show="isLoading && isOpen"
                :size="'tiny'"
                :line-size="4"
                line-fg-color="#0073ff"
                line-bg-color="#dde2ec"
            ></vue-simple-spinner>
        </div>
        <ul class="autocomplete-results" v-show="isOpen && hasResults">
            <li
                v-for="(item, i) in results"
                :key="i"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"
            >
                <a
                    href="#"
                    @click="setResult(item)"
                    :title="customResult ? '' : formatResult(item)"
                    v-html="formatResult(item)"
                ></a>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueSimpleSpinner from "vue-simple-spinner";
import VueLocalStorage from "vue-ls";
import { EventBus } from "@/event-bus.js";

let optionsLs = {
    namespace: "devis-app__",
};

Vue.use(VueLocalStorage, optionsLs);

export default {
    name: "autocomplete",
    components: { VueSimpleSpinner },
    props: {
        name: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        mainClass: {
            type: String,
            required: false,
            default: "",
        },
        inputClass: {
            type: String,
            required: false,
            default: "",
        },
        query: {
            type: Object,
            required: true,
            default: null,
        },
        url: {
            type: String,
            required: true,
            default: "",
        },
        callback: {
            type: String,
            required: true,
            default: "",
        },
        initValue: {
            type: String(),
            required: false,
        },
        customResult: {
            type: Array,
            required: false,
        },
        emptyFilter: {
            type: Boolean(),
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean(),
            required: false,
            default: false,
        },
    },
    data() {
        return {
            search: "",
            results: [],
            isOpen: false,
            isLoading: false,
            arrowCounter: -1,
            debounce: false,
        };
    },
    watch: {
        initValue() {
            this.search = this.initValue;
        },
    },
    computed: {
        hasResults() {
            return this.results.length > 0;
        },
        hasFilters() {
            return Vue.ls.get("filters")
                ? Object.keys(Vue.ls.get("filters")).length > 0
                : false;
        },
        isFiltered() {
            return (
                this.hasFilters &&
                this.name !== "" &&
                Vue.ls.get("filters")[this.name] !== undefined &&
                Vue.ls.get("filters")[this.name].value !== ""
            );
        },
    },
    methods: {
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
            }
        },
        onChange() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.isOpen = true;
                this.isLoading = true;
                this.query.value = this.search;
                let vm = this;
                axios
                    .post(
                        "/v2/" +
                            vm.url +
                            (this.$route.params.type
                                ? "?type=" + this.$route.params.type
                                : ""),
                        vm.query
                    )
                    .then(function (response) {
                        vm.isLoading = false;
                        vm.results = response.data;
                    })
                    .catch(function () {});
            }, 350);
        },
        setResult(result) {
            if (!result) {
                return false;
            }
            if (this.emptyFilter) {
                this.search = "";
            } else {
                this.search = this.formatResult(result, false);
            }
            this.query.value = result;
            this.isOpen = false;
            EventBus.$emit(this.callback, this.query);
        },
        onFocus() {
            if (this.results.length > 0 || this.search !== "") {
                this.isOpen = true;
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEnter() {
            this.setResult(this.results[this.arrowCounter]);
            this.arrowCounter = -1;
        },
        setFilter() {
            if (this.isFiltered && !this.emptyFilter) {
                this.search =
                    this.name === "product"
                        ? Vue.ls.get("filters")[this.name].value["Id"]
                        : Vue.ls.get("filters")[this.name].value;
            }
        },
        resetFilter() {
            this.search = "";
            this.query.value = "";
            this.isOpen = false;
            EventBus.$emit(this.callback, this.query);
        },
        formatResult(item, isTyping = true) {
            if (this.customResult) {
                if (isTyping) {
                    if (this.query.table === "products") {
                        return `
            <div title="${item.Caption}">
              <span style="float:left; margin-right: 10px;width: 40px; height: 40px;">
              <img src="${this.formatImageUrl(
                  item.Image,
                  item.Id
              )}" width="40" height="40" style="border-radius: 50%; border: solid 1px #d0d0d0;"/></span>
              <span style="display: block; padding: 4px 0;"><strong style="font-weight: 600; color: #0071ff; font-size: 14px; letter-spacing: -0.5px;">${
                  item.Id
              }</strong><h6 style="margin-bottom: 0; font-size: 13px; width: 235px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${
                            item.Caption
                        }</h6></span>
            </div>
            `;
                    } else if (this.query.table === "customers") {
                        return `<strong style="font-weight: bold; text-transform: uppercase;">${item.first_name} ${item.last_name}</strong><p style="margin-bottom: 0; color: #0079ff;">${item.email}</p>`;
                    } else {
                        return this.customResult
                            .map(function (val) {
                                return item[val];
                            })
                            .join(" - ");
                    }
                } else {
                    return this.customResult
                        .map(function (val) {
                            return item[val];
                        })
                        .join(" - ");
                }
            }

            return item;
        },
        formatImageUrl(image, id) {
            return image
                ? "data:" +
                      this.detectMimeType(image) +
                      ";charset=utf-8;base64," +
                      image
                : "https://via.placeholder.com/40?text=" + id;
        },
        detectMimeType(b64) {
            var signatures = {
                JVBERi0: "application/pdf",
                R0lGODdh: "image/gif",
                R0lGODlh: "image/gif",
                iVBORw0KGgo: "image/png",
                "/9j/": "image/jpg",
            };
            for (var s in signatures) {
                if (b64.indexOf(s) === 0) {
                    return signatures[s];
                }
            }
        },
    },
    mounted() {
        this.setFilter();
        document.addEventListener("click", this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>

<style lang="scss">
.autocomplete {
    position: relative;

    &#autocomplete-product {
        .autocomplete-results {
            width: 300%;
            left: -100% !important;

            .autocomplete-result {
                a {
                    width: 305px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &.autocomplete-th {
        height: 39px;
        line-height: 39px;

        input[type="text"] {
            height: 39px;
            border-radius: 0;
            border: 0;

            &:focus {
                border: 2px solid #dde2ec;
                background: #fff;
                border-radius: 30px;
                height: 30px;
                width: 80%;
                position: absolute;
                top: 4.5px;
                left: 10%;
                z-index: 1;
            }
        }

        &.is-filtered {
            input[type="text"] {
                color: #047bf8;
            }
        }
    }

    &.autocomplete-specs {
        height: 31.78px;

        .form-control {
            font-size: 0.8rem;
        }

        .autocomplete-results a {
            width: 267px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            h6 {
                width: 200px !important;
            }
        }
    }

    &.autocomplete-td {
        border: none;

        &.w100 {
            .autocomplete-results {
                h6 {
                    width: 650px !important;
                }
            }
        }

        &.autocomplete-float {
            .ion-close {
                right: 10px !important;
                margin: 0;
                top: 8px;
            }

            .autocomplete-results {
                position: inherit;
                top: -1px;
                left: 0 !important;
            }
        }

        .form-control {
            font-size: 12px;
            height: 30px;

            &:focus {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                    0 0 8px rgba(102, 175, 233, 0.6);
            }
        }

        .autocomplete-results {
            width: 100% !important;
            left: -1px !important;
            border-width: 1px;
            max-height: 366px;
            overflow: scroll;
        }

        &.autocomplete-linked {
            .autocomplete-results {
                position: static;
                margin-top: 2px;
            }
        }

        .loading-container {
            position: absolute;
            z-index: 400;
            right: 10px;
            top: 50%;
            margin: -6px 8px 0 0;
        }
    }

    .autocomplete-results {
        position: absolute;
        left: 10% !important;
        width: 80%;
        top: 100%;
        z-index: 1;
        float: left;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 0.9rem;
        color: #3e4b5b;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border-radius: 4px;
        border: 2px solid #dde2ec;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0px;
        background: #f8f9fb;
        padding: 0;

        &:before {
            position: absolute;
            top: -7px;
            left: 50%;
            margin-left: -7px;
            display: inline-block;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-left: 7px solid transparent;
            border-bottom-color: #dde2ec;
            content: "";
        }

        li {
            line-height: initial;
            max-width: 100%;
            overflow: hidden;
            &:hover {
                strong,
                p {
                    color: #fff !important;
                }
            }
            a {
                padding: 6px !important;
                display: block;
                color: #3e4b5b;
                text-decoration: none;
                font-size: 12px;
                text-transform: initial;
                &:hover {
                    color: #fff;
                    background-color: #5897fb !important;
                }
            }
            &:nth-of-type(odd) a {
                background-color: #fff;
            }
            &.is-active {
                a {
                    color: #fff;
                    background-color: #5897fb !important;
                }
            }
        }
    }

    .loading-container {
        position: absolute;
        z-index: 400;
        right: 10%;
        top: 50%;
        margin: -6px 8px 0 0;
    }

    .ion-close {
        position: absolute;
        z-index: 400;
        right: 10%;
        top: 50%;
        margin: -5.3px 8px 0 0;
        line-height: initial;
        cursor: pointer;
    }
}
</style>
